import React from 'react';

import BannerBg from './img/pes_bg1.jpg'

import styled from 'styled-components';

const BannerBox = styled.div`
	display: flex;
    justify-content: center;
	position:relative;
	min-height: 216px;
	padding: 20px 0;
	background:url(${BannerBg}) center center no-repeat;
	background-size: cover;
`

const BannerInner = styled.div`
	display: flex;
    justify-content: center;
	align-items: center;
	max-width: 1060px;
	color:#fff;
	box-sizing: border-box;
    padding: 0 20px;
`

const LftTxt = styled.p`
	font-size: 60px;
`

const RghtTxt = styled.p`
	font-size: 20px;
    padding-left: 50px;
	line-height: 27px;
	
	@media(max-width:520px){
		font-size:16px;
		line-height:140%;
	}
`

const CptBanner = () => {
	return (
		<BannerBox>
			<BannerInner>
				<LftTxt>
					PES
				</LftTxt>
				<RghtTxt>
					카르페 선수트레이닝(PES) 교육은 다양한 스포츠 종목에 따른 운동수행능력 강화를 위한 필수요소가 무엇인지를 알아보고 자세평가와 체력평가를 통한 평가결과를 토대로 운동처방 방법을 배웁니다. 또한 빈번히 일어나는 스포츠상해의 종류와 특징을 알아보고 스포츠재활의 기초와 관리방법을 학습할 수 있습니다.
				</RghtTxt>
			</BannerInner>
		</BannerBox>
	)
}

export default CptBanner;