import React, { Component } from "react";

import url from "../../config";

import axios from "axios";
import styled from "styled-components";

import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import PicturLft from "./img/pictur_lft_arrow.png";
import PicturRght from "./img/pictur_rght_arrow.png";

const EduPicturSlider = styled.div`
  max-width: 1214px;
  width: 100%;
  margin: 0 auto;

  @media (max-width: 1480px) {
    width: 80%;
  }

  @media (max-width: 1024px) {
    width: 70%;
  }

  @media (max-width: 640px) {
    width: 60%;
  }
`;

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        position: "absolute",
        top: "50%",
        left: "-62px",
        width: "40px",
        height: "44px",
        display: "block",
        marginTop: "-22px",
        background: `url(${PicturLft}) 0 0 no-repeat`,
        cursor: "pointer",
        zIndex: "5",
      }}
      onClick={onClick}
    />
  );
}

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        position: "absolute",
        top: "50%",
        right: "-62px",
        width: "40px",
        height: "44px",
        display: "block",
        marginTop: "-22px",
        background: `url(${PicturRght}) 0 0 no-repeat`,
        cursor: "pointer",
        zIndex: "5",
      }}
      onClick={onClick}
    />
  );
}

class EduPictur extends Component {
  state = {
    eduPictur: null,
  };

  componentDidMount() {
    axios
      .get(url.Api + "/rest/karfe/photo")
      .then((res) => {
        this.setState({ eduPictur: res.data });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  render() {
    const settings = {
      dots: false,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 2000,
      pauseOnHover: true,
      speed: 600,
      slidesToShow: 3,
      slidesToScroll: 3,
      rows: 2,
      centerPadding: "6px",
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
          },
        },
        {
          breakpoint: 960,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 640,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    const { eduPictur } = this.state;

    return (
      <EduPicturSlider>
        {eduPictur && Array.isArray(eduPictur) ? (
          <Slider {...settings} className="[&_.slick-arrow::before]:hidden">
            {eduPictur.map((item, idx) => {
              return (
                <div key={item.txt}>
                  <img src={item.src} alt={item.txt} />
                </div>
              );
            })}
          </Slider>
        ) : (
          "로딩중"
        )}
      </EduPicturSlider>
    );
  }
}

export default EduPictur;
