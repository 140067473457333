import React from "react";

import Header from "./components/Header";
import NavLink from "./components/NavLink/";
import Footer from "./components/Footer";
import kakao from "./Kakao_logo.jpg";

const HomeLayout = ({ children, ...rest }) => {
  return (
    <div>
      <Header />
      {children}
      <NavLink />
      <div className="fixed right-0 bottom-0 p-3">
        <img
          src={kakao}
          alt=""
          className="w-16 rounded shadow-[3px_3px_5px_rgba(0,0,0,0.3)] cursor-pointer"
          onClick={() => window.open("http://pf.kakao.com/_hrKXxl")}
        />
      </div>
      <Footer />
    </div>
  );
};

export default HomeLayout;
