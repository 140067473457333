import React, {Component} from 'react';

import styled from 'styled-components';

import CertificateBg from './img/certificates_bg.jpg';
import MoCertificateBg from './img/m_certificates_bg.jpg';

import DnsCertificates from './img/certificates_thums.jpg';


const CertificatesBox = styled.div`
	position:relative;
	padding:60px 0;
	height:972px;
	background:url(${CertificateBg}) 0 0 no-repeat;
	background-size:cover;

	@media(max-width:960px){
		height:825px;
		background:url(${MoCertificateBg}) 0 0 no-repeat;
		background-size:cover;
	}
`

const ConceptBox = styled.div`
	
`

const InfoConTit = styled.h2`
    text-align: center;
    font-size: 63px;
    color: #fff;
	letter-spacing: -5px;
	line-height: 130%;

	&>span{
		font-family: 'Impact';
	}

	@media(max-width:960px){
		font-size: 40px;
		padding-top:60px;
	}
`

const CertificateMenuBox = styled.div`
	padding:100px 0;
	max-width:862px;
	width:100%;
	margin:0 auto;

	@media(max-width:960px){
		padding:60px 0;
	}
`

const CertificateThums = styled.img`
	display:block;
	width:100%;
	max-width:495px;
	margin:0 auto;

	@media(max-width:960px){
		width: 70%;
		max-width: 400px;
	}
`

class Certificates extends Component {
	componentDidMount() {
		this.props.getOffset('certificates', this.certificates);
	}

	render() {
		return (
			<CertificatesBox
				id="certificates"
				ref={ref => {
					this.certificates = ref;
				}}
			>
				<ConceptBox>
					<InfoConTit><span>STC</span> 인증 교육 수료증</InfoConTit>
				</ConceptBox>
				<CertificateMenuBox>
					<CertificateThums src={DnsCertificates}/>
				</CertificateMenuBox>
			</CertificatesBox>
		)
	}
}

export default Certificates;