import React from "react";

// import People1 from './img/people_1.jpg';
import People1 from "./img/박일봉.png";
import People2 from "./img/people_2.jpg";
import People3 from "./img/people_3.jpg";
import People4 from "./img/people_4.jpg";
import People5 from "./img/people_5.jpg";
import People6 from "./img/people_6.jpg";
import People7 from "./img/people_7.jpg";
import People8 from "./img/people_8.jpg";
import People9 from "./img/people_9.jpg";
import People10 from "./img/people_10.jpg";
import People11 from "./img/people_11.jpg";
import People12 from "./img/강슈호.png";
import People13 from "./img/이자연.png";

import styled from "styled-components";

const InstructorListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
`;

const InstructorListThums = styled.button`
  position: relative;
  width: 24%;
  margin: 1% 0.5%;
  box-sizing: border-box;
  text-align: left;

  @media (max-width: 1480px) {
    width: 32.333%;
    margin: 1% 0.5%;
  }

  @media (max-width: 1024px) {
    width: 48%;
    margin: 1% 1%;
  }

  @media (max-width: 640px) {
    width: 98%;
    margin: 1% 1%;
  }

  & > div:nth-child(2) {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 0;
    height: 0;
    z-index: -5;
    opacity: 0;
  }

  &:hover > div:nth-child(2),
  :focus > div:nth-child(2) {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    opacity: 1;
    z-index: 5;
    transition: 0.3s ease-in-out;
  }
`;

const InstructorThums = styled.img`
  display: block;
  width: 100%;
`;

const HovContents = styled.div`
  padding: 20px 10px 0 10px;
  background: rgba(0, 0, 0, 0.8);
  box-sizing: border-box;

  @media (max-width: 1040px) {
    padding: 20px 10px 0 10px;
  }

  @media (max-width: 580px) {
    padding: 15% 10px 0 10px;
  }
`;

const HovTit = styled.p`
  padding-bottom: 24px;
  font-size: 40px;
  color: #838383;
  font-weight: 300;

  @media (max-width: 1040px) {
    padding-bottom: 12px;
  }

  @media (max-width: 580px) {
    padding-bottom: 24px;
  }
`;

const HovTxt = styled.div`
  color: #fff;
  line-height: 140%;

  @media (max-width: 1024px) {
    line-height: 120%;
  }

  @media (max-width: 580px) {
    line-height: 140%;
  }

  & > span {
    display: block;
    font-size: 15px;
    letter-spacing: -1.8px;
    font-weight: 700;

    @media (max-width: 1480px) {
      font-size: 1vw;
    }

    @media (max-width: 1024px) {
      font-size: 1.5vw;
    }

    @media (max-width: 580px) {
      font-size: 14px;
    }
  }
`;

const PrevTxtBox = styled.div`
  position: absolute;
  top: 44px;
  left: 33px;
`;

const PrevTxtTop = styled.p`
  & > span {
    display: block;
    font-size: 16px;
    line-height: 140%;
    color: #838383;
  }
`;

const PrevNameTxt = styled.p`
  font-size: 32px;
  font-weight: 900;
  padding-top: 16px;
`;

const PrevNameTxtWhite = styled.p`
  font-size: 32px;
  font-weight: 900;
  padding-top: 16px;
  color: white;
`;

const PrevJobTxt = styled.p`
  padding-top: 6px;
  font-size: 32px;
  letter-spacing: -5px;
  color: #606060;
`;

const Instructor = ({ children, trainer, onClick }) => {
  const data = [
    {
      prevImg: People1,
      contentsTxt1: "재활 교정운동의 대가<br/>퍼스널 트레이너들의 멘토",
      name: "박일봉",
      job: "박사",
      hovTxt: `동아대학교 체육학부 사회체육학 체육학사<br/>
        동아대학교 대학원 체육학과 운동생리학 체육학석사<br/>
        동아대학교 대학원 체육학과 운동생리학 이학박사<br/>
        부산대학교 의학전문대학원 의학석사<br/>
        부산카톨릭대학교 보건과학대학 물리치료학 보건학사<br/>
        부산대학교 의학전문대학원 의학박사<br/>
        부산대학교 경영대학원 경영컨설팅 마케팅 경영학박사<br/>
        현)부산외국어대학교 스포츠재활의학과교수<br/>
        현)KARFE대표`,
    },
    {
      prevImg: People12,
      contentsTxt1: "동적안정화운동 전문가<br/>파워플레이트 마스터",
      name: "강슈호",
      job: "교수",
      hovTxt: `부산외국어대학교 겸임교수<br/>
		이학박사 (BUFS)<br/>
		체육학석사 (BUFS)<br/>
		일본 Wako University (Basketball Team) 졸업<br/>
		버티힐즈트레이닝스튜디오 원장<br/>
		카르페 교육강사<br/>
		STC Master Instructor<br/>
		DST Master Instructor<br/>
		Powerplate Master<br/>
		DNS A,B,C Course<br/>
		RAD mobility Master Trainer<br/>
		NASM CPT, CES, PES<br/>
		CFSC Level 1,2`,
    },
    {
      prevImg: People2,
      contentsTxt1: "펑셔널트레이닝 전문가<br/>실전 트레이닝의 강자",
      name: "이채관",
      job: "교수",
      hovTxt:
        "부산대학교 체육교육대학원 석사<br/>부산카톨릭대물리치료학 박사과정<br/>물리치료사(보건복지부)<br/>Powerplate instructor course<br/>Trigger Point instructor course<br/>NASM instructor<br/>현)동아과학기술대학 스포츠재활운동학부 겸임교수<br/>현)KARFE 교육이사",
    },
    {
      prevImg: People4,
      contentsTxt1: "운동기능향상 전문가<br/>ALEX와 함께 핵심 마스터",
      name: "정주연",
      job: "강사",
      hovTxt:
        "동국대학교 체육교육과 졸업<br/>동국대학교 일반대학원 체육학 석사과정<br/>NASM CPT,CES instructor<br/>VIPR instructor<br/>SMART-CORE instructor<br/>POWER PLATE instructor<br/>TRIGGER POINT instructor<br/>FLOWIN instructor<br/>현)H personal training대표<br/>현)카르페교육이사",
    },
    {
      prevImg: People13,
      contentsTxt1: "동적안정화운동 전문가<br/>안정화 트레이닝의 진수",
      name: "이자연",
      job: "교수",
      hovTxt:
        "부산외국어대학교 스포츠재활학 박사 과정<br/>부산외국어대학교 스포츠재활학과 석사<br/>함안 대산고등학교 여자 축구부 팀닥터<br/>부산외대 배드민턴 선수부 컨디셔닝 관리(제 95회 전국체육대회)<br/>Ultimateinstability(Aquabag&Aquaball) Instructor<br/>STC(Basic, Advance, Rehab) Master<br/>CFSC Level 1 Instructor<br/>KARFE CPT, CES, PES Instructor<br/>Functional Training(GV, KP, SR, CFT) Instructor<br/>현)KARFE 교육강사",
    },
    // {
    //   prevImg: People7,
    //   contentsTxt1: "필라테스운동 전문가<br/>바이브로짐 필라테스",
    //   name: "정은주",
    //   job: "강사",
    //   hovTxt:
    //     "한국필라테스학회(교육이사)<br/>신라대학교 교양과정대학<br/>동서울대학교 운동처방학과<br/>춘해보건대학교 요가학과<br/>부산대학교 무용학 석사<br/>인제대학교 물리치료학 석사<br/>MBC 행복한 금요일,KBS 생생투데이 출연<br/>현)움직임과학연구소 소장<br/>현)FM필라테스원장<br/>현)KARFE 교육이사",
    // },
    {
      prevImg: People9,
      contentsTxt1: "촉진 스트레칭 전문가<br/>촉진 스트레칭의 진수",
      name: "정지환",
      job: "교수",
      hovTxt:
        "동아대학교 체육대학 경기지도학<br/>부산 카톨릭대학 물리치료학<br/>부산 외국어대학교 스포츠재활 석사과정<br/>운동처방사<br/>인간공학기사<br/>물리치료사(보건복지부)<br/>프리티올라 운동센터 대표<br/>NASM CPT instructor<br/>NACA CPT instructor<br/>현)KARFE 교육이사",
    },
    {
      prevImg: People3,
      contentsTxt1: "펑셔널트레이닝 전문가<br/>성공 파트너 RAIN",
      name: "강준",
      job: "강사",
      hovTxt:
        "NASM CPT 퍼스널트레이닝 공역<br/>근력강화를 위한 세라밴드 운동법 역자<br/>DNC Exercise1,Scolosis<br/>KEMA introduction course<br/>2014~15년도 PLAN KOREA 선정 퍼스널트레이너 부문<br/>Kinetic intergrations 전 과정 이수<br/>현)다듬체 MOVEMENTLAB CEO<br/>현)KESA 이사(한국운동전문가 아카데미)<br/>현)KARFE 교육이사",
    },
    // {
    // prevImg: People5,
    // contentsTxt1: "완벽한 퍼포먼스 전문가<br/>퍼포먼스의 귀재",
    // name: '장유태',
    // job: '강사',
    // hovTxt: '미국 R2P member<br/>NASM CPT,CES,PES<br/>TRX GSTC/TRX RIP TRAINER<br/>PS2AD KOREA member<br/>서울대학교 교정운동 출강<br/>현)연세알찬병원 스포츠재활 센터장<br/>현)프리포먼스 교육이사<br/>현)(사)KFLA부회장<br/>현)KARFE 교육이사'
    // },
    // {
    // prevImg: People6,
    // contentsTxt1: "재활운동 전문가<br/>어려운 재활운동의 진수",
    // name: '김동우',
    // job: '강사',
    // hovTxt: '인제대학교 물리치료학과<br/>경성대학교 체육교육 석사과정<br/>부산외국어대학교 스포츠건강융합 박사<br/>물리치료사(보건복지부)<br/>NASM CPT,CES,PES<br/>황윤성 재활의학과 재활치료실<br/>나눔과 행복병원 재활치료실<br/>현)동원과학기술대학교 겸임교수<br/>현)부산외국어대학교 겸임교수<br/>현)KARFE 교육이사'
    // },
    // {
    // prevImg: People8,
    // contentsTxt1: "산전산후운동 전문가<br/>여성을 위한 특수운동치료",
    // name: '백승화',
    // job: '강사',
    // hovTxt: '한국요가협회 요가 지도자<br/>Stott pilatest 교육과정 이수<br/>PNF course instructor<br/>PEDALO instructor<br/>TRX STC,SMSTC instructor<br/>T.P master course instructor<br/>Vipr Fitness instructor<br/>현)FM필라테스 부원장<br/>현)KARFE 교육이사'
    // },
    // {
    // prevImg: People10,
    // contentsTxt1: "스포츠 재활관리 전문가<br/>스포츠 재활관리의 진수",
    // name: '임병권',
    // job: '교수',
    // hovTxt: '신라대학교 의생명과학대학 체육학과,물리치료학과<br/>미국 센트럴 오클라호마 주립대학교 대학원E<br/>xercise Science 석사<br/>Strength and Conditioning Assistant 코치<br/> Olympic Weightlifting코치<br/>TSU 운동선수 폼 롤링 스트레치 및 근막<br/>스트레칭 기술 코치<br/>UCO미국 장애인 올림픽 트레이닝센터 Assistant코치<br/>미국역도연맹 스포츠퍼포먼스 코치<br/>노인운동 실행 및 트레이닝 자격증<br/>NACA CSCS<br/>현)KARFE교육이사'
    // },
    // {
    // prevImg: People11,
    // contentsTxt1: "선수트레이닝 전문가<br/>선수 재활관리의 사령탑",
    // name: '정연창',
    // job: '강사',
    // hovTxt: '미국 보이시주립대학교 미식 축구팀<br/>NC다이노스 프로야구단 재활군 트레이너<br/>NC다이노스 프로야구단 트레이닝 코치<br/>NASM CES,PES<br/>생활스포츠 지도자 2급(보디빌딩)<br/>현)KARFE Kettlebell Certification'
    // },
  ];

  const targetTrainder = data.filter((x) => x.name === trainer.name)[0];

  return targetTrainder ? (
    <InstructorListThums onClick={onClick}>
      <div>
        <PrevTxtBox>
          <PrevTxtTop>
            {targetTrainder.contentsTxt1.split("<br/>").map((line, idx) => {
              return <span key={idx}>{line}</span>;
            })}
          </PrevTxtTop>
          {targetTrainder.name === "박일봉" ||
          targetTrainder.name === "강슈호" ? (
            <PrevNameTxtWhite>{targetTrainder.name}</PrevNameTxtWhite>
          ) : (
            <PrevNameTxt>{targetTrainder.name}</PrevNameTxt>
          )}
          <PrevJobTxt>{targetTrainder.job}</PrevJobTxt>
        </PrevTxtBox>
        <InstructorThums src={targetTrainder.prevImg} />
      </div>
      <HovContents>
        <HovTit>Profile</HovTit>
        <HovTxt>
          {targetTrainder.hovTxt.split("<br/>").map((line, idx) => {
            return <span key={idx}>{line}</span>;
          })}
        </HovTxt>
      </HovContents>
    </InstructorListThums>
  ) : (
    <></>
  );
};

export default Instructor;
