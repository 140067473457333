import React from 'react';

import HealthImg from './img/stc_health_bg.jpg';

import styled from 'styled-components';

const HealthThums = styled.img`
	display:block;
	width:100%;
`

const StcBanner = () => {
	return(
		<div>
			<HealthThums src={HealthImg}/>
		</div>
	)
}

export default StcBanner;