import React from 'react';

import BenefitBg from './img/BenefitList_bg.jpg';

import ItemsNum1 from './img/num_1_ico.png';
import ItemsNum2 from './img/num_2_ico.png';
import ItemsNum3 from './img/num_3_ico.png';
import ItemsNum4 from './img/num_4_ico.png';
import ItemsNum5 from './img/num_5_ico.png';

import ItemsThums1 from './img/benefit_thums_1.jpg';
import ItemsThums2 from './img/benefit_thums_2.jpg';
import ItemsThums3 from './img/benefit_thums_3.jpg';
import ItemsThums4 from './img/benefit_thums_4.jpg';
import ItemsThums5 from './img/benefit_thums_5.jpg';

import styled from 'styled-components';

const BenefitBannerWarrper = styled.section`
	padding:96px 0;

	@media(max-width:960px){
		padding:60px 0;
	}
`

const BenefitBannerInner = styled.div`
	max-width:1350px;
	margin:0 auto;
`

const BenefitBanner = styled.h1`
	padding-bottom:70px;
	font-size:33px;
	text-align:center;

	@media(max-width:960px){
		padding-bottom:60px;
	}
`

const BenefitListBox = styled.div`

`

const BenefitListThums = styled.img`
	display:block;
	width:100%;
`

const BenefitItemsBox = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
`

const BenefitItems = styled.div`
	position:relative;
	width: 20vw;
    height: 20vw;
    border: 1.4vw solid #e3e3e3;
    box-sizing: border-box;
	border-radius: 50%;
	margin: 0 2%;
	
	&:nth-child(3){
		margin-right:0;
	}

	@media(max-width:1490px){
		width: 28vw;
    	height: 28vw;
	}

	@media(max-width:640px){
		width:40vw;
		height:40vw;
		margin: 1% 2%;
	}
`

const BenefitItemsCount = styled.span`
	position:absolute;
	top: 2vw;
	left:50%;
	transform:translate(-50%,0);

	&>img{
		width: 4vw;
    	max-width: 72px;
	}

	@media(max-width:640px){
		&>img{
			width: 8vw;
		}
	}
`

const IconThums = styled.span`
	position:absolute;
	top: 7vw;
	left:50%;
	transform:translate(-50%,0);
	width:100%;
	text-align:center;

	@media(max-width:640px){
		top:12vw;
	}
`

const FreeThums = styled(IconThums)`
	&>img{
		width: 9vw;
    	max-width: 157px;
	}

	@media(max-width:640px){
		&>img{
			width: 14vw;
			max-width: 157px;
		}
	}
`

const PerThums = styled(IconThums)`
	top: 5.8vw;

	&>img{
		width: 6vw;
    	max-width: 90px;
	}

	@media(max-width:640px){
		top:9vw;

		&>img{
			width: 9vw;
			max-width: 90px;
		}
	}
`

const PeopleThums = styled(IconThums)`
	top: 5.8vw;

	&>img{
		width: 6vw;
    	max-width: 100px;
	}

	@media(max-width:640px){
		top:10vw;

		&>img{
			width: 9vw;
			max-width: 90px;
		}
	}
`

const PaperThums = styled(IconThums)`
	top: 5.8vw;

	&>img{
		width: 5.8vw;
    	max-width: 88px;
	}

	@media(max-width:640px){
		top:10vw;

		&>img{
			width: 8vw;
			max-width: 90px;
		}
	}
`

const CartThums = styled(IconThums)`
	top: 5.8vw;

	&>img{
		width: 7vw;
    	max-width: 114px;
	}

	@media(max-width:640px){
		top:10vw;

		&>img{
			width: 9vw;
			max-width: 90px;
		}
	}
`

const MasterTxt = styled.p`
	position: absolute;
    top: 11vw;
    left: 50%;
    transform: translate(-50%,0);
    width: 100%;
	text-align: center;
	font-size: 20px;
	line-height: 120%;

	@media(max-width:1780px){
		top: 12vw;
	}

	@media(max-width:1480px){
		top: 13vw;
	}

	@media(max-width:760px){
		font-size:16px;
	}
	
	@media(max-width:640px){
		top: 21vw;
		font-size: 14px;
	}

	@media(max-width:520px){
		font-size: 12px;
	}
	
`
const Orange = styled.span`
	color:#ef5022;
	font-size:15px;
	font-weight:bold;

	@media(max-width:760px){
		font-size:10px;
	}	
`;

const Benefit = () => {
	
	return(
		<BenefitBannerWarrper>
			<BenefitBannerInner>
				<BenefitBanner>Master Trainer Benefit</BenefitBanner>
				<BenefitListBox>
					<BenefitItemsBox>
						<BenefitItems>
							<BenefitItemsCount><img src={ItemsNum1} alt="01"/></BenefitItemsCount>
							<FreeThums><img src={ItemsThums1} /></FreeThums>
							<MasterTxt>
								자신이 수강한 모든<br/>
								현장강의를 평생<br/>
								무료재청강가능
							</MasterTxt>
						</BenefitItems>
						<BenefitItems>
							<BenefitItemsCount><img src={ItemsNum2} alt="02" /></BenefitItemsCount>
							<PerThums><img src={ItemsThums2} /></PerThums>
							<MasterTxt>
								카르페 인터넷 및 현장강의<br></br>
								30%할인 혜택<br></br>
								<Orange>*외국 파트너쉽 강의 제외</Orange>
							</MasterTxt>
						</BenefitItems>
						<BenefitItems>
							<BenefitItemsCount><img src={ItemsNum3} alt="03" /></BenefitItemsCount>
							<PeopleThums><img src={ItemsThums3} /></PeopleThums>
							<MasterTxt>
								석사학위 이상 시<br />
								카르페강사<br />
								우선채용
							</MasterTxt>
						</BenefitItems>
						<BenefitItems>
							<BenefitItemsCount><img src={ItemsNum4} alt="04" /></BenefitItemsCount>
							<PaperThums><img src={ItemsThums4} /></PaperThums>
							<MasterTxt>
								마스터 트레이너 <br />
								인증패 수여								
							</MasterTxt>
						</BenefitItems>
						<BenefitItems>
							<BenefitItemsCount><img src={ItemsNum5} alt="05" /></BenefitItemsCount>
							<CartThums><img src={ItemsThums5} /></CartThums>
							<MasterTxt>
								소도구 구입시<br />
								10% 할인<br />
								<Orange>*스마트몰 이용시</Orange>
							</MasterTxt>
						</BenefitItems>
					</BenefitItemsBox>
				</BenefitListBox>
			</BenefitBannerInner>
		</BenefitBannerWarrper>
	)
}

export default Benefit;
