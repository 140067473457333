import React from 'react';

import PriceThums from './img/price_thums.jpg';
import MoPriceThums from './img/m_price_thums.jpg';

import CheckIcon from './img/check_icon.png';

import styled from 'styled-components';

const PriceImg = styled.img`
	display:block;
	width:100%;

	@media(max-width:960px){
		display:none;
	}
`

const MoPriceImg = styled.img`
	display:none;

	@media(max-width:960px){
		display:block;
		width:100%;
		padding-top: 36px;
	}
`

const TeacherTit = styled.h2`
	padding:120px 0;
	font-size:64px;
	font-weight:100;
	color:#565656;
	text-align:center;

	&>span{
		color:#a49a21;
	}

	@media(max-width: 960px){
		padding: 60px 0 0 0;
		font-size: 33px;
	}

	@media(max-width:480px){
		&>span{
			display:block;
		}
	}
`

const MentoMenuBox = styled.div`
	max-width: 1385px;
	width:100%;
    margin: 0 auto;
`

const MentoItems = styled.div`
	display: flex;
	padding: 44px 165px;
	align-items: center;
	border-top:1px solid #dcdcdc;
	color:#595959;
	font-weight:300;

	&:first-child{
		border-top:0;
	}

	@media(max-width:1235px){
		padding: 44px 15px;
	}

	@media(max-width:640px){
		flex-direction: column;
		text-align: center;
		padding: 44px 10px;
	}
`

const MentoLft = styled.div`
	width: 60%;
	font-size:49px;

	&>span{
		display: block;
		padding-top: 9px;
		padding-left: 7%;
		font-size: 17px;
		font-weight: 900;
		color: #fd0d11;
	}

	@media(max-width:1235px){
		font-size:34px;
		&>span{
			padding-left: 0;
		}
	}

	@media(max-width:640px){
		width: 100%;
		font-size:28px;
	}
`

const MentoRght = styled.span`
	position:relative;
	flex: 1 1 auto;
    text-align: center;
    font-size: 90px;
    font-style: italic;
	font-weight: 900;
	color:#2ca0d1;

	&:after{
		content:"";
		position:absolute;
		background:url(${CheckIcon}) 0 0 no-repeat;
		width: 244px;
		height: 165px;
		top: -36px;
		right: 0;
		background-size: 100%;
	}

	@media(max-width:1235px){
		font-size:70px;

		&:after{
			width:200px;
		}
	}

	@media(max-width:640px){
		padding-top:30px;
		&:after{
			top:0px;
		}
	}
`

const MentoRghtExtendsion = styled(MentoRght)`
	&:after{
		content:"";
		position:absolute;
		background:url(${CheckIcon}) 0 0 no-repeat;
		width: 244px;
		height: 165px;
		top: -36px;
		right: 18%;
		background-size: 100%;
	}

	@media(max-width:1235px){
		font-size:70px;

		&:after{
			width:200px;
		}
	}

	@media(max-width:640px){
		&:after{
			top:0px;
			right: 50%;
    		margin-right: -69%;
		}
	}
`

const Price = () => {
	return(
		<div>
			<TeacherTit>카르페멘토링스쿨 <span>혜택</span></TeacherTit>
			<MentoMenuBox>
				<MentoItems>
					<MentoLft>멘토링스쿨의 모든비용</MentoLft>
					<MentoRghtExtendsion>무료!</MentoRghtExtendsion>
				</MentoItems>
				<MentoItems>
					<MentoLft>카르페 모든강의 신청시<span>2019년도 교육/ 정상금액에 할인적용/ 국외 코스는 혜택불가</span></MentoLft>
					<MentoRght>50%할인</MentoRght>
				</MentoItems>
				<MentoItems>
					<MentoLft>스마트몰 이용시 할인</MentoLft>
					<MentoRght>10%할인</MentoRght>
				</MentoItems>
				<MentoItems>
					<MentoLft>취업연계</MentoLft>
					<MentoRghtExtendsion>지원!</MentoRghtExtendsion>
				</MentoItems>
			</MentoMenuBox>
		</div>
	)
}

export default Price;