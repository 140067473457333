import React from 'react';

import HowThums from './img/how_thums.png';

import styled from 'styled-components';

const HowBox = styled.section`
	padding:80px 0;
	text-align: center;
	color:#565656;

	@media(max-width:960px){
		padding:80px 20px;
	}
`

const HowImg = styled.img`
	display:block;
	width:100%;
	max-width:200px;
	margin: 0 auto;
`

const HowTit = styled.h2`
	padding:46px 0 25px 0;
	font-size: 64px;
	font-weight:300;

	@media(max-width:960px){
		font-size:33px;
	}
`

const HowTxt = styled.p`
	font-size:20px;
	line-height: 120%;

	@media(max-width:960px){
		font-size:14px;
	}
`


const HowMentoring = () => {
	return(
		<HowBox>
			<HowImg src={HowThums} alt="멘토링 스쿨이란?"/>
			<HowTit>카르페 멘토링 스쿨이란?</HowTit>
			<HowTxt>진정한 트레이너가 되고자 하는 트레이너들을  각각 멘토링을 배정하여<br/> 멘티들과 운동 및 교류를 통하여 퍼스널 트레이너를 양성하고 현장 수업을 중심으로 지도하는 무료 과정입니다.</HowTxt>
		</HowBox>
	)
}

export default HowMentoring;