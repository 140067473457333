import React from 'react';

import MentoImg from './img/mento_thums.jpg';
import MoMentoImg from './img/m_mento_thums.jpg';

import PicturThums from './img/picture_thums.png';
import WiterThums from './img/writer_thums.png';
import EmailThums from './img/email_thums.png';

import styled from 'styled-components';

const MentoContainer = styled.div`
	padding-bottom:120px;
	background:#f4f4f4;

	@media(max-width:960px){
		padding-bottom:60px;
	}
`

const TeacherTit = styled.h2`
	padding:120px 0;
	font-size:64px;
	font-weight:100;
	color:#565656;
	text-align:center;

	&>span{
		color:#a49a21;
	}

	@media(max-width: 960px){
		padding:60px 0;
		font-size: 33px;
	}

	@media(max-width:480px){
		&>span{
			display:block;
		}
	}
`

const MentoThums = styled.img`
	display:block;
	width:100%;

	@media(max-width:960px){
		display:none;
	}
`

const MoMentoThums = styled.img`
	display:none;

	@media(max-width:960px){
		display:block;
		width:100%;
	}
`

const MentoCircleBox = styled.div`
	display:flex;
	max-width: 900px;
	margin: 0 auto;
	justify-content: center;

	@media(max-width:960px){
		padding:0 15px;
	}
`

const MentoCircleItems = styled.div`
	display:flex;
	width: 15vw;
	height: 15vw;
	margin:0 1%;
	border-radius:50%;
	background:#e1ca2e;
	flex-direction: column;
    justify-content: center;
    text-align: center;

	@media(max-width:960px){
		width: 30vw;
		height: 30vw;
	}
`

const IconThums = styled.img`
	width: 5vw;
	max-width: 100px;
	
	@media(max-width:960px){
		width: 10vw;
	}
`

const MentoConTxt = styled.span`
	padding-top:20px;
	color:#fff;
	font-size: 1.3vw;
    line-height: 120%;

	&>span{
		color:#ff0000;
		font-weight:700;
	}

	@media(max-width:960px){
		font-size:16px;
	}

	@media(max-width:660px){
		font-size:12px;
	}
`

const PaperConBox = styled.div`
	display:table;
	max-width: 900px;
	width:100%;
	margin: 60px auto 120px auto;
	box-sizing: border-box;
    font-size: 26px;
	color: #595959;
	
	@media(max-width:960px){
		display:block;
		margin-bottom:0;
	}
`

const PaperRow = styled.div`
	display: table-row;

	@media(max-width:700px){
		display:block;
	}
`

const PaperCell = styled.div`
	display: table-cell;
	padding: 16px 0;
	border-bottom:1px solid #dedede;

	@media(max-width:700px){
		display:block;
		padding:20px 0;
		text-align:center;
	}
`

const PaperPrevCell = styled(PaperCell)`
	width: 192px;
	padding-left: 100px;
	
	@media(max-width:700px){
		width:100%;
		padding:20px 0;
	}
`

const PaperCellBox = styled.div`
	font-size:19px;
`

const PaperCellLft = styled.span`
	padding-right:3px;
`

const PaperColorRed = styled.span`
	color:#ff0000;
	font-weight:900;

	@media(max-width:720px){
		display:block;
	}
`

const PaperBold = styled.span`
	position:relative;
	display: block;
	font-weight:700;
	padding-bottom:6px;

	&>span{
		position:absolute;
		top:0;
		right:0;
		font-size: 15px;
	}

	@media(max-width:700px){
		&>span{
			position:relative;
		}
	}

	@media(max-width:480px){
		&>span{
			display:block;
			padding:5px 0;
		}
	}
`


const Mento = () => {
	return(
		<MentoContainer>
			<div>
				<TeacherTit>카르페멘토링스쿨 <span>멘토</span></TeacherTit>
			</div>

			<MentoCircleBox>
				<MentoCircleItems>
					<span><IconThums src={PicturThums}/></span>
					<MentoConTxt>
						사진이 들어간<br/>
						이력서
					</MentoConTxt>
				</MentoCircleItems>
				<MentoCircleItems>
					<span><IconThums src={WiterThums} /></span>
					<MentoConTxt>
						정성껏 작성한<br/>
						자기소개서
					</MentoConTxt>
				</MentoCircleItems>
				<MentoCircleItems>
					<span><IconThums src={EmailThums} /></span>
					<MentoConTxt>
						이메일 지원<br/>
						<span>fitboss@naver.com</span>
					</MentoConTxt>
				</MentoCircleItems>
			</MentoCircleBox>

			<PaperConBox>
				<PaperRow>
					<PaperPrevCell>1차 서류접수</PaperPrevCell>
					<PaperCell>
						<PaperBold>~2019년 6월 9일(일요일)</PaperBold>
						<PaperCellBox>
							<PaperCellLft>이력서 자기소개서 이메일 접수</PaperCellLft>
							<PaperColorRed>e-mail:fitboss@naver.com</PaperColorRed>
						</PaperCellBox>
					</PaperCell>
				</PaperRow>
				<PaperRow>
					<PaperPrevCell>2차 면접</PaperPrevCell>
					<PaperCell>
						<PaperBold>~2019년 6월 15일(토요일) <span>*시간은 추후공지</span></PaperBold>
						<PaperCellBox>
							<span>1차 서류합격자에 한하여면접 (서울,대전,부산 나누어져 진행)</span>
						</PaperCellBox>
					</PaperCell>
				</PaperRow>
				<PaperRow>
					<PaperPrevCell>합격자발표</PaperPrevCell>
					<PaperCell>
						<PaperBold>~2019년 6월 22일(토요일)</PaperBold>
					</PaperCell>
				</PaperRow>
			</PaperConBox>
		</MentoContainer>
	)
}

export default Mento;