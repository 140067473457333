import React, {Component} from 'react';

import styled from 'styled-components';

import ConceptImg from './img/concept_bg.jpg';
import MoConceptImg from './img/m_concept_bg.jpg';

const ConceptWrap = styled.div`
	position:relative;
`

const ConceptThums = styled.img`
	display:block;
	width:100%;

	@media(max-width:960px){
		display:none;
	}
`

const MoConceptThums = styled.img`
	display:none;

	@media(max-width:960px){
		display:block;
		width:100%;
	}
`

const ConceptTxtBox = styled.div`
	position:absolute;
	top:50%;
	right:10%;
	color:#3d3d3d;
	transform:translate(0,-50%);

	@media(max-width:960px){
		width: 100%;
		position: static;
		top: 0;
		left: 0;
		transform: inherit;
		padding: 40px;
		box-sizing: border-box;
	}

`

const ConceptTit = styled.p`
	font-size:42px;
	font-weight:700;
	padding-bottom:32px;

	@media(max-width:960px){
		padding-bottom:24px;
		font-size:28px;
	}
`

const ConceptTxt = styled.p`
	font-size:24px;
	font-weight:300;
	line-height:140%;

	@media(max-width:960px){
		font-size:18px;
	}

	@media(max-width:640px){
		&>br{
			display:none;
		}
	}
`

const ConceptBold = styled.span`
	font-family: 'impact';
`

class Concept extends Component {

	render(){
		return(
			<ConceptWrap 
				id="concept"
			>
				<ConceptThums src={ConceptImg} />
				<MoConceptThums src={MoConceptImg} />
				<ConceptTxtBox>
					<ConceptTit><ConceptBold>Pael Kolar</ConceptBold>(파벨콜라)</ConceptTit>
					<ConceptTxt>Pavel Kolar (파벨콜라) 교수는 Prague School (프라하 스쿨) 출신으로 <br />
						DNS 설립자이다. 프라하 스쿨은 르윗( Karel Lewit), 보이타 (Vaclav Vojta), <br />
						얀다 (Vladimir Janda)와 같은 재활운동 및 도수치료 분야의 최고의 <br />
						권위자들을 배출한 100년 전통 프라하 학파이다.
					</ConceptTxt>
				</ConceptTxtBox>
			</ConceptWrap>
		)
	}
}

export default Concept;