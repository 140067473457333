import React, {Component} from 'react';

import CfscList1 from './img/cfsc_01.jpg';
import CfscList2 from './img/cfsc_02.jpg';
import CfscList3 from './img/cfsc_03.jpg';
import CfscList4 from './img/cfsc_04.jpg';

import styled from 'styled-components';

const InfoContainer = styled.div`
	position:relative;
	padding-bottom:100px;

	@media(max-width:960px){
		padding-bottom:60px;
	}
`

const InfoThums = styled.img`
	display:block;
	width:100%;

	@media(max-width:960px){
		display:none;
	}
`

const MoInfoThums = styled.img`
	display:none;

	@media(max-width:960px){
		display:block;
		width:100%;
	}
`

const InfoConTit = styled.h2`
    text-align: center;
    font-size: 63px;
    color: #333;
	letter-spacing: -5px;
	padding-bottom: 100px;

	&>span{
		position: relative;
		top: 6px;
		left: 6px;
		font-family: 'Impact';
		font-size: 68px;
		color: #333;
		letter-spacing: 2px;
	}

	@media(max-width:960px){
		font-size: 40px;
		padding-bottom: 60px;

		&>span{
			font-size: 44px;
		}
	}
`

const InfoListBox = styled.div`
	display:flex;
	max-width:1350px;
	margin:0 auto;
	flex-wrap:wrap;
`

const InfoListItems = styled.div`
	width:23%;
	margin:1% 1%;
	text-align: center;

	@media(max-width:720px){
		width:48%;
	}
`

const InfoListThums = styled.img`
	display:block;
	width:100%;
`

const InfoListTit = styled.p`
	padding:24px 0 18px 0;
	font-size:24px;
	font-weight:700;
	color:#dd1b1e;

	@media(max-width:680px){
		font-size:20px;
	}
`

const InfoListTxt = styled.p`
	font-size: 13px;

	&>span{
		display:block;
	}

	@media(max-width:720px){
		&>span{
			display:inline;
		}
	}
`

const data = [
	{images: CfscList1, title: '실용적인 코치 인증 과정', contentsTxt: '필기 온라인 테스트 뿐 아니라 실기 세미나와 실기 시험을<br/>설정하여 현장에서 즉시 활용할 수 있는 인증입니다.'},
	{images: CfscList2, title: 'MBSC의 철학을 체계화', contentsTxt: '마이크 보일 씨는 자신이 운영하는 체육관<br/>\'MBSC\'의 철학(철학 · 생각)을 체계화되어 있습니다.'},
	{images: CfscList3, title: '코칭 스킬에 중점', contentsTxt: '어떻게 말을 거시겠습니까? 어떻게 대처해야 합니까?<br/>현장에서 사용할 코칭에 중점을 두고 있습니다.'},
	{images: CfscList4, title: '실용적인 코치 인증 과정', contentsTxt: 'Web을 통한 사전 학습과 \'필기 온라인 테스트\' 를 결합하여<br/>컴팩트하면서 더 많은 정보 공유 그 실천이 가능하게되어있다.'},
]

class Info extends Component {
	componentDidMount() {
		this.props.getOffset('course',this.course);
	}

	render() {
		return (
			<InfoContainer 
				id="course"
				ref={ref => {
					this.course = ref;
				}}
			>
				<InfoConTit><span>CFSC</span> 의 차별성</InfoConTit>

				<InfoListBox>

				{
					data.map((list,idx)=>{
						return(
							<InfoListItems key={idx}>
								<div>
									<InfoListThums src={list.images} alt={list.title} />
								</div>
								<div>
									<InfoListTit>{list.title}</InfoListTit>
									<InfoListTxt>
										{list.contentsTxt.split('<br/>').map((line, idx) => {
											return (<span key={idx}>{line}</span>)
										})}
									</InfoListTxt>
								</div>
							</InfoListItems>
						)
					})
				}
				</InfoListBox>
			</InfoContainer>
		)
	}
}

export default Info;