import React, {Component} from 'react';
import Modal from 'react-responsive-modal';

import styled from 'styled-components';

const VideoPlayer = styled.video`
	width:100%;
`

const PopBox = styled.div`
	position:relative;
`

const OpenBox = styled.div`
	padding:30px 20px;
`

const SpinBox = styled.div`
	position: absolute;
    top: 50%;
    left: 50%;
`

const StyledSpinner = styled.svg`
  animation: rotate 2s linear infinite;
  margin: -25px 0 0 -25px;
  width: 50px;
  height: 50px;
  
  & .path {
    stroke: #fc465a;
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }
  
  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
`

class ReplayModal extends Component {
	state = {
		videoUrl: 'http://karfe1.cache.iwinv.net/156075574971397.mp4'
	}

	render() {

		return (
			<div>
				<Modal open={this.props.isReply} onClose={this.props.onCloseModal} center>
					<PopBox>
						<OpenBox>동영상 준비중입니다.</OpenBox>
						<SpinBox>
							<StyledSpinner viewBox="0 0 50 50">
								<circle
									className="path"
									cx="25"
									cy="25"
									r="20"
									fill="none"
									strokeWidth="4"
								/>
							</StyledSpinner>
						</SpinBox>
					</PopBox>
					{/*<VideoPlayer controls autoPlay>
						<source src={this.state.videoUrl} />
					</VideoPlayer>*/}
				</Modal>
			</div>
		);
	}
}

export default ReplayModal;