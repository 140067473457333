import React from "react";
import main from "./img/main.png";
import google from "./img/google.png";
import apple from "./img/apple.png";
import qr from "./img/qr.png";
import edueasy from "./img/edueasy.png";
import mileage from "./img/mileage.png";
import getmile from "./img/getmile.png";
import badge from "./img/badge.png";
import goyoutube from "./img/goyoutube.png";

export default function AppIntoduce() {
  return (
    <div className="relative">
      {/* 첫번째 이미지 및 텍스트 */}
      <div className="lg:absolute top-0 left-0 flex flex-col justify-center items-center text-center w-full mb-10">
        <p className="mt-7 text-[calc(1.5rem+1.5vw)] font-black">
          카르페 APP(앱) 다운로드
        </p>
        <p className="mt-2 lg:text-lg px-2 break-keep break-words">
          아래 방법 중 편리한 방법으로{" "}
          <span className="font-bold">카르페앱</span>을 다운로드 하세요.
        </p>
      </div>
      <div className="w-full overflow-hidden flex justify-center items-center">
        <img src={main} alt="" className="w-full object-cover min-w-[580px]" />
      </div>

      {/* 보기 편한 인터페이스 */}
      <div className="mx-auto max-w-[1200px] px-3 flex justify-center items-center py-20">
        <img src={edueasy} alt="" />
      </div>

      {/* 카르페 마일리지 등급 */}
      <div className="px-3 flex justify-center items-center py-20 bg-[#f5f5f7]">
        <div className="mx-auto max-w-[1200px]">
          <img src={mileage} alt="" />
          <p className="mt-5 lg:mt-10 text-center text-sm lg:text-xl font-light opacity-80">
            카르페 교육을 결제한 만큼 5% 마일리지를 지급 받아
            <br />
            필요한 등급 마일리지를 채워 등급을 5단계까지 올릴 수 있으며
            <br />
            등급에 따라 다양한 혜택을 받으실 수 있습니다.
          </p>
        </div>
      </div>

      {/* 마일리지 획드 방법 */}
      <div className="mx-auto max-w-[1200px] px-3 flex flex-col justify-center items-center py-20">
        <img src={getmile} alt="" />
        <p className="mt-5 lg:mt-10 text-center text-sm lg:text-xl font-light opacity-80">
          다양한 방법으로 마일리지를 적립해
          <br />
          교육 결제 시 현금처럼 사용이 가능합니다.
        </p>
        <p className="text-[#60a5fa] mt-7 lg:mt-10 font-bold lg:text-2xl">
          지금부터 카르페 마일리지 적립에 재미를 느껴보세요.
        </p>
      </div>

      {/* 벳지 및 유튜브 */}
      <div className="px-3 flex justify-center items-center py-20 bg-[#f5f5f7]">
        <div className="mx-auto max-w-[1200px] flex flex-col justify-center items-center">
          <img src={badge} alt="" />
          <p className="mt-5 lg:mt-10 text-center text-sm lg:text-xl font-light opacity-80">
            카르페 교육을 수료하게되면 각 교육에 대한 뱃지를 획득할 수 있으며
            <br />
            획득한 뱃지를 클릭하면 교육에 대한 정보등을 확인이 가능하고 <br />
            SNS등으로 뱃지를 다운로드 및 공유할 수 있을뿐 아니라 <br />
            교육에 대한 증명서까지 다운로드 및 출력이 가능합니다.
          </p>

          <p className="text-[#09213c] mt-14 lg:mt-20 mb-5 lg:mb-16 font-bold lg:text-2xl break-keep text-center">
            카르페 어플에서 간편하게 교육 등록하고 다양한 혜택들도 받으세요.
          </p>
          <button onClick={()=>window.open("https://youtu.be/oRpl_aUkVTA?si=R5XXEjnAOS5zJgS2")}>
            <img src={goyoutube} alt="" />
          </button>
        </div>
      </div>

      {/* 어플 다운로드 버튼 */}
      <div className="left-0 bottom-0 bg-white w-full py-10 flex flex-col lg:flex-row justify-evenly lg:items-center px-3 gap-4">
        <div className="flex gap-8 items-center">
          <div>
            <p className="text-xl mb-5 font-bold">스토어 다운로드</p>
            <p className="lg:whitespace-pre-line">
              애플 앱스토어와 구글 플레이스토어에서{`\n`}
              <span className="font-bold">카르페앱</span>을 다운로드 해보세요.
            </p>
          </div>
          <div className="flex flex-col gap-2 w-full lg:w-auto">
            <img
              src={google}
              alt=""
              className="cursor-pointer"
              onClick={() =>
                window.open(
                  "https://play.google.com/store/apps/details?id=com.smartfitkorea.karfe&hl=ko-KR"
                )
              }
            />
            <img
              src={apple}
              alt=""
              className="cursor-pointer"
              onClick={() =>
                window.open("https://apps.apple.com/kr/app/karfe/id6447024980")
              }
            />
          </div>
        </div>
        <div className="flex gap-8 items-center justify-between">
          <div>
            <p className="text-xl mb-5 font-bold">QR코드 다운로드</p>
            <p>
              QR코드를 스캔하여<br></br>
              <span className="font-bold">카르페앱</span>을 다운로드 해보세요.
            </p>
          </div>
          <div className="border p-3">
            <img src={qr} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}
