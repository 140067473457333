import React from 'react';

import KarfeOrganizationBg from './img/karfeis_organization.jpg';
import MobileKarfeOrganizationBg from './img/m_karfeis_organization.jpg';

import styled from 'styled-components';

const OrganizationImg = styled.img`
	display:block;
	width:100%;

	@media(max-width:960px){
		display:none;
	}
`

const MobileOrganizationImg = styled.img`
	display:none;
	width:100%;
	@media(max-width:960px){
		display:block;
	}
`

const KarfeOrganization = () => {
	return(
		<div>
			<OrganizationImg src={KarfeOrganizationBg}/>
			<MobileOrganizationImg src={MobileKarfeOrganizationBg}/>
		</div>
	)
}

export default KarfeOrganization;