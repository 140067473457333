import React, { Component } from 'react';
import Modal from 'react-responsive-modal';

import CertZoomOne from './img/cert_zoom1.jpg';
import CertZoomTwo from './img/cert_zoom2.jpg';
import CertZoomThree from './img/cert_zoom3.jpg';

import styled from 'styled-components';

const CertificateTitle = styled.h1`
	font-size: 20px;
    padding-bottom: 10px;
    font-weight: 900;
    padding-left: 6px;
`

const CertificateColum = styled.div`
	float:left;
	width:33.333%;;
	@media (max-width: 768px) {
		float:none;
		width:80%;
		margin:0 auto;
	}
`
const CertificateImg = styled.img`
	display:block;
	width:100%;
`

class CertificateModal extends Component {
	render() {
		return (
			<section>
				<Modal open={this.props.open} onClose={this.props.onCloseModal} center>
					<CertificateTitle>카르페 민간자격증</CertificateTitle>
					<div>
						<CertificateColum>
							<CertificateImg src={CertZoomOne}/>
						</CertificateColum>
						<CertificateColum>
							<CertificateImg src={CertZoomTwo} />
						</CertificateColum>
						<CertificateColum>
							<CertificateImg src={CertZoomThree} />
						</CertificateColum>
					</div>
				</Modal>
			</section>
		);
	}
}

export default CertificateModal;