import React, {Component} from 'react';
import Modal from 'react-responsive-modal';

import styled from 'styled-components';

const VideoPlayer = styled.video`
	width:100%;
`

class ReplayModal extends Component {
	state = {
		videoUrl1: 'http://karfe1.cache.iwinv.net/156092174865300.mp4',
		videoUrl2: 'http://karfe1.cache.iwinv.net/156092173064739.mp4',
		videoUrl3: 'http://karfe1.cache.iwinv.net/156092170983856.mp4'
	}

	render() {

		return (
			<div>
				<Modal open={this.props.isReply} onClose={this.props.onCloseModal} center>
					<VideoPlayer controls autoPlay>
						{this.props.isNumber === 1 && < source src={this.state.videoUrl1} />}
						{this.props.isNumber === 2 && < source src={this.state.videoUrl2} />}
						{this.props.isNumber === 3 && < source src={this.state.videoUrl3} />}
					</VideoPlayer>
				</Modal>
			</div>
		);
	}
}

export default ReplayModal;