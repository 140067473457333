import React from "react";
import KarfeisBg from "./img/karfeis_banner_2024.png";

const KarfeBanner = () => {
  return (
    <div className="relative">
      <img
        src={KarfeisBg}
        className="w-full h-[calc(100vh-72px)] lg:h-[calc(100vh-136px)] object-cover"
      />
      <div className="absolute w-full h-full flex justify-center items-center top-0 left-0 text-white italic text-[calc(2rem+5vw)]">
        <p>
          What is <b className="font-black">KARFE</b>
        </p>
      </div>
    </div>
  );
};

export default KarfeBanner;
