import React, {Component} from 'react';

import CftVideo from './Cft_video';
import CftBanner from './Cft_banner';
import CftContents from './Cft_Content';

class Academy_onCft extends Component {
	state = {
		ClientY: 0,
	}

	componentDidMount() {
		this.setState({ClientY: this.BoxTop.offsetTop});
	}

	render() {
		return (
			<div
				ref={ref => {
					this.BoxTop = ref;
				}}
			>
				{this.state.ClientY ? this.props.getCptOffset(this.BoxTop.offsetTop) : ''}
				<CftVideo />
				<CftBanner />
				<CftContents />
			</div>
		);
	}
}

export default Academy_onCft;