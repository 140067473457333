import React, { Component } from 'react';

import animateScrollTo from 'animated-scroll-to';

import StcHeader from './Stc_header';
import StcBanner from './Stc_banner';
import Intro from './Stc_intro';
import Info from './Stc_info';
import Concept from './Stc_concept';
import Curriculum from './Stc_curriculum';
import Record from './Stc_record';
import Process from './Stc_process';
import Certificates from './Stc_certificates';
import OutLook from './Stc_outlook';
import Footer from './Stc_footer';

class index extends Component {
	state = {
		intro: null,
		course: null,
		concept: null,
		curriculum: null,
		record: null,
		process: null,
		outlook: null,
	}

	componentWillMount() {
		document.title = 'STC KOREA';
	}

	chkMov = ()=>{
		const typeChk = this.props.location.search;

		if (typeChk === '?intro') {
			animateScrollTo(this.state.intro.offsetTop);
		}
		if (typeChk === '?course') {
			animateScrollTo(this.state.course.offsetTop);
		}

		if (typeChk === '?concept') {
			animateScrollTo(this.state.concept.offsetTop);
		}

		if (typeChk === '?process') {
			animateScrollTo(this.state.process.offsetTop);
		}

		if (typeChk === '?certificates') {
			animateScrollTo(this.state.certificates.offsetTop);
		}

		if (typeChk === '?outlook') {
			animateScrollTo(this.state.outlook.offsetTop);
		}
	}

	getOffset = (type ,getNode) => {
		if(type === 'intro'){
			this.setState({
				intro: getNode,
			});
		}else if(type === 'course'){
			this.setState({
				course: getNode,
			});
		} else if (type === 'concept'){
			this.setState({
				concept: getNode,
			});
		} else if(type === 'process'){
			this.setState({
				process: getNode,
			});
		} else if (type === 'certificates'){
			this.setState({
				certificates: getNode,
			});
		} else if (type === 'outlook'){
			this.setState({
				outlook: getNode,
			});
		}
	}


	render() {
		return (
			<div>
				{this.state.concept ? this.chkMov() : ''}
				<StcHeader onHandleClick={this.onHandleClick}/>
				<StcBanner/>
				<Intro getOffset={this.getOffset}/>
				<Info getOffset={this.getOffset}/>
				<Concept getOffset={this.getOffset}/>
				<Curriculum/>
				<Record/>
				<Process getOffset={this.getOffset}/>
				<Certificates getOffset={this.getOffset}/>
				<OutLook getOffset={this.getOffset}/>
				<Footer/>
			</div>
		);
	}
}

export default index;