import React from 'react';
import { useState } from 'react';

import styled from 'styled-components';

import MainPC from './img/prevent_main.jpg'
import MainMo from './img/prevent_main02.jpg'
import Info from './img/prevent_info.jpg'
import Info03 from './img/prevent_info02.jpg'
import Teacher from './img/prevent_teacher.jpg'
import Teacher02 from './img/prevent_teacher02.jpg'
import Curriculum from './img/prevent_curriculum.jpg'

const Wrap = styled.div`
    width: 100%;
`
const TitleWrap = styled.div`
    .main_img_pc {
        display: block;
        width: 100%;
    }
    .main_img_mo {
        display: none;
    }
    @media(max-width:768px){
		.main_img_pc {
            display: none;
        }
        .main_img_mo {
            display: block;
            width: 100%;
        }
	}
`

const InfoWrap = styled.div`
	.info_img_pc {
        display: block;
    }
    .info_img_mo {
        display: none;
    }

	@media(max-width:768px){
		.info_img_pc {
            display: none;
        }
        .info_img_mo {
            display: block;
        }
	}
`

const TeacherWrap = styled.div`
    .teacher_img_pc {
        display: block;
    }
    .teacher_img_mo {
        display: none;
    }
	@media(max-width:768px){
		.teacher_img_pc {
            display: none;
        }
        .teacher_img_mo {
            display: block;
        }
	}
`

const MobilityCurriculum = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    margin-top: 5%;
    .curriculum_title {
        color: #079fb4;
        font-size: 40px;
        font-weight: bold;
        margin-bottom: 2%;
    }
    .curriculum_img {
        width: 50%;
    }
	@media(max-width:768px){
		.curriculum_title {
            color: #079fb4;
            font-size: 18px;
            font-weight: bold;
            margin-bottom: 2%;
        }
        .curriculum_img {
            width: 90%;
        }
	}
`

const MobilitySubmit = styled.div`
	display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    margin-top: 5%;
    background: #EFEFEF;
    margin-bottom: 5%;
    .submit_title {
        color: #079fb4;
        font-size: 40px;
        font-weight: bold;
        margin: 2% 0;
    }
    .submit_content {
        font-size: 20px;
        margin-bottom: 2%;
    }
    .submit_button {
        font-size: 20px;
        margin-bottom: 5%;
        background: #079fb4;
        color: white;
        border: none;
        padding: 5px 15px;
    }
	@media(max-width:768px){
		.submit_title {
            color: #079fb4;
            font-size: 18px;
            font-weight: bold;
            margin: 2% 0;
        }
        .submit_content {
            width: 90%;
            font-size: 10px;
            margin-bottom : 10px;
            /* padding: 0 4%; */
        }
        .submit_button {
            font-size: 12px;
            margin-bottom: 5%;
            background: #079fb4;
            color: white;
            border: none;
            padding: 5px 15px;
        }
	}
`

const BufsPrevent = () => {
    const [mobilityColor, setMobilityColor] = useState(false);
    const [preventColor, setPreventColor] = useState(false);
    const [yogaColor, setYogaColor] = useState(false);
    return(
        <div>
            <Wrap>
                <TitleWrap>
                <img className="main_img_pc" src={MainPC} style={{width: "100%"}}/>
                <img className="main_img_mo" src={MainMo} style={{width: "100%;"}}/>
                </TitleWrap>
                <InfoWrap className="info_wrap">
                    <img className="info_img_pc" src={Info} style={{width: "100%"}}/>
                    <img className="info_img_mo" src={Info03} style={{width: "100%"}}/>
                </InfoWrap>
                <TeacherWrap>
                    <img className="teacher_img_pc" src={Teacher} style={{width: "100%"}}/>
                    <img className="teacher_img_mo" src={Teacher02} style={{width: "100%"}}/>
                </TeacherWrap>
                <MobilityCurriculum>
                    <div className="curriculum_title">
                        교육 커리큘럼
                    </div>
                    <img className="curriculum_img"src={Curriculum}/>
                </MobilityCurriculum>
                <MobilitySubmit>
                    <div className="submit_title">
                        교육등록안내
                    </div>
                    <div className="submit_content">
                        접수기간 : 2022.01.04 ~ 2022.02.18 까지
                        <br></br>
                        강좌기간 : 2022.03.10 ~ 2022.06.23 15주 과정 (매주 목요일 18:30~21:30)
                        <br></br>
                        교육비용 : 27만원
                        <br></br>
                        모집인원 : 20명
                        <br></br>
                        교육장소 : 부산외국어대학교 평생교육원
                        <br></br>
                        신청방법 : 부산외국어대학교 평생교육원 홈페이지에서 회원가입 후 등록
                    </div>
                    <button type="button" className="submit_button" onClick={() => window.open('https://lec.bufs.ac.kr/lec/index.php?pCode=lec02&mode=view&aIdx=331','_blank')}>예방운동처방사 과정 신청하러가기</button>
                </MobilitySubmit>
            </Wrap>
        </div>
    )
}

export default BufsPrevent;