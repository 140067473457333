import React, { Component } from 'react';

import EduPictur from './EduPictur';

import styled from 'styled-components';

const EduPicturWrapper = styled.div`
	padding:89px 0 89px 0;
	background-color:#4796d1;
	text-align:center;

	@media (max-width: 640px) {
		padding:74px 0 74px 0;
	}
`

const EduPicturTit = styled.div`
	padding-bottom:96px;
	font-size: 64px;
	color:#fff;
	font-weight: 300;

	@media (max-width:1280px){
		font-size: 54px;
	}

	@media (max-width: 720px) {
		font-size: 38px;
		padding-bottom:72px;
	}
`

class HomePictur extends Component {
	render() {
		return (
			<EduPicturWrapper>
				<EduPicturTit>최근 교육 수료 사진</EduPicturTit>
				<EduPictur/>
			</EduPicturWrapper>
		);
	}
}

export default HomePictur;