import React from 'react';

import styled from 'styled-components';

const NavLinkWarpper = styled.div`
	padding:46px 0;
`

const NavLinkInner = styled.div`
	display:flex;
	flex-direction: row;
    justify-content: center;
`

const NavLinkItmes = styled.div`
	padding: 0 60px;
	border-left:1px solid #000;

	@media (max-width:1024px){
		padding:0 24px;
	}

	@media (max-width:720px){
		font-size:14px;
		padding:0 8px;
	}

	&:first-child{
		border-left:0;
	}
`

const NavLinks = styled.a`
	color:#595959;
	&:hover{
		color:#3d6cec;
	}
`

const NavLink = () => {

	function termPop(){
		const termPopup = window.open('/Term.html', '_blank', 'width = 680, height = 500, scrollbars=1', false);
		const HTML = `
			<table width="624" border="0" cellpadding="0" cellspacing="0">
			<tbody>
				<tr>
					<td>
						<img src="http://www.smartfitkorea.com/image/main_old/privacy_05.gif" width="624" height="110" alt="">
					</td>
				</tr>
				<tr>
					<td background="http://www.smartfitkorea.com/image/main_old/privacy_02.gif" width="624" height="8" align="center">
						<table width="580" align="center">
							<tbody><tr><td height="10">
					</td>
				</tr>
				<tr>
					<td width="540" style="padding:0 30px;">
						<style>
							<!--  
							a { cursor:hand; }
							a:link, a:visited, a:active { text-decoration:none; color:black;}  
							a:hover { text-decoration:underline;color:red; }  
							body  { font-size: 9pt;line-height : 15pt;font-style:굴림}  
							table { font-size: 9pt; }  
							font  { font-size: 9pt;text-decoration: none; }
							TD,SELECT,DIV,form,TEXTAREA { font-size:9pt; }
							P,td,br { font-size:9pt; }
							-->
						</style>
						<table width="100%" cellpadding="0" cellspacing="0" border="0" style="font-size:9pt;">
							<tbody>
								<tr>
									<td>
									◇ <b>(주)KARFE</b> 회원약관 ◇
									<br>
									<br>제 1 장   총 칙
									<br>
									<br>제 1 조 (목적)
									<br>
									<br>이 약관은 <b>(주)KARFE</b>가 제공하는 서비스의 이용조건 및 절차, 기타 필요한 사항을 규정함을 목적으로 합니다. 
									<br>
									<br>제 2 조 (약관의 효력) 
									<br>
									<br>① 이 약관은  전기통신사업법 제31조, 동법 시행규칙  제21조의 2에 따라  공시ㆍ전자우편ㆍ기타의 방법으로  회원에게 통지함으로써 효력을 발생합니다. 
									<br>
									<br>② <b>(주)KARFE</b>는 이 약관의 내용을 변경할 수 있으며, 변경된 약관은  제1항과 같은 방법으로 통지함으로써 효력을 발생합니다. 
									<br>
									<br>제 3 조 (약관 이외의 준칙) 
									<br>
									<br>이 약관에 명시되지 않은 사항이 전기통신기본법, 전기통신사업법, 기타 관련법령에 규정되어 있는 경우 그 규정에 따릅니다. 
									<br>
									<br>제 4 조 (용어의 정의) 
									<br>
									<br>이 약관에서 사용하는 용어의 정의는 다음과 같습니다. 
									<br>
									<br>1. 회원 : <b>(주)KARFE</b>과 서비스 이용에 관한 계약을 체결한 자 
									<!--<br>2. 아이디(ID) : 회원 식별과 회원의 서비스 이용을 위하여 회원이 선정한 문자와 숫자의 조합 -->
									<br>2. 메일 : 회원 식별과 회원의 서비스 이용을 위하여 회원이 선정한 문자와 숫자의 조합 
									<br>3. 비밀번호 : 회원이 통신상의 자신의 비밀을 보호하기 위해 선정한 문자와 숫자의 조합 
									<br>
									<br>
									<br>제 2 장 서비스 이용계약 
									<br>
									<br>제 5 조 (이용계약의 성립) 
									<br>
									<br>이용계약은 서비스이용신청자의 이용신청에 대하여 <b>(주)KARFE</b>가 승낙을 함으로써 성립합니다.
									<br>
									<br>제 6 조 (이용신청) 
									<br>
									<br>① 서비스 이용신청자는 <b>(주)KARFE</b>가 정한 가입 양식에 따라 회원 정보를 기입하여 이를 제출함으로써 이용신청을 할 수 있습니다.
									<br>
									<br>② 서비스 이용신청자는 반드시 실명으로 이용신청을 하여야 합니다.
									<br>
									<br>제 7 조 (이용신청의 승낙) 
									<br>
									<br>① <b>(주)KARFE</b>는 제6조에 따른 이용신청에 대하여 특별한 사정이 없는 한 접수순서에 따라서 이용신청을 승낙합니다.
									<br>
									<br>② <b>(주)KARFE</b>는 다음 각 호에 해당하는 경우 이용신청에 대한 승낙을 제한할 수 있고, 그 사유가 해소될때 까지 승낙을 유보할 수 있습니다. 
									<br>
									<br>1. 서비스 관련 설비의 용량이 부족한 경우
									<br>2. 기술상 장애사유가 있는 경우 
									<br>
									<br>③ <b>(주)KARFE</b>는 다음 각 호에  해당하는 사항을 인지하는 경우 이용신청을 승낙하지 아니합니다.
									<br>
									<br>1. 이름이 실명이 아닌 경우 
									<br>2. 다른 사람의 명의를 사용하여 신청한 경우 
									<br>3. 가입신청서의 내용을 허위로 기재한 경우 
									<br>4. 사회의 안녕질서 또는 미풍양속을 저해할 목적으로 신청한 경우 
									<br>5. 기타  소정의 이용신청요건을 충족하지 못하는 경우
									<!--
									<br>  
									<br>  
									<br>제 8 조 (회원 아이디(ID)의 변경)
									<br>
									<br>다음 각 호에 해당하는 경우 <b>(주)KARFE</b>는 직권 또는 회원의 신청에 의하여 회원 아이디(ID)를 변경할 수 있습니다.
									<br>
									<br>1. 회원 아이디(ID)가 회원의 전화번호 등으로 등록되어 있어서 회원의 사생활을 침해할 우려가 있는 경우
									<br>2. 타인에게 협오감을 주거나 미풍양속에 어긋하는 경우
									<br>3. 기타  소정의 합리적인 사유가 있는 경우 
									--->
									<br>  
									<br>  
									<br>제 8 조 (회원 메일의 변경)
									<br>
									<br>회원 식별을 위한 메일은 변경이 불가능합니다. 메일을 변경하기 위하여 탈퇴후 신규로 가입할때는 기존 메일 관련 모든 정보는 삭제되오니 가입시 유의해 주시길 바랍니다.
									<br>
									<br>
									<br>제 3 장 서비스 제공 및 이용 
									<br>
									<br>제 9 조 (서비스의 내용)
									<br>
									<br>① <b>(주)KARFE</b>가 제공하는 서비스의 내용은 다음과 같습니다.
									<br>
									<br>1. 회원들의 교류를 위한 정보 서비스
									<br>
									<br>② <b>(주)KARFE</b>는 필요한 경우 서비스의 내용을 추가 또는 변경할 수 있습니다. 
									<br>
									<br>제 10 조 (서비스의 요금)
									<br>
									<br>회원 가입은 무료이며, 회원 가입 목적 외 기타 서비스를 이용하기 위한 별도의 서비스는 유료로 제공될 수 있다.
									<br>
									<br>제 11 조 (서비스의 개시)
									<br>
									<br>서비스는 이 제7조에 따라서 이용신청을 승낙한 때로부터 즉시 개시됩니다. 다만, <b>(주)KARFE</b>의 업무상 또는 기술상의 장애로 인하여 서비스를 즉시 개시하지 못하는 경우 <b>(주)KARFE</b>는 회원에게 이를 지체없이 통지합니다.
									<br>
									<br>제 12 조 (서비스 이용시간)
									<br>
									<br>① 서비스는 <b>(주)KARFE</b>의 업무상 또는  기술상 장애, 기타 특별한 사유가 없는 한 연중무휴, 1일 24시간 이용할 수 있습니다.  다만  설비의 점검 등 <b>(주)KARFE</b>가 필요한 경우 또는 설비의 장애, 서비스 이용의 폭주 등 불가항력 사항으로 인하여 서비스 이용에 지장이 있는 경우 예외적으로 서비스 이용의 전부 또는 일부에 대하여 제한할 수 있습니다.
									<br>
									<br>② <b>(주)KARFE</b>는 제공하는 서비스 중 일부에 대한 서비스이용시간을 별도로 정할 수 있으며, 이 경우 그 이용시간을 사전에 회원에게 공지 또는 통지합니다.
									<br>
									<br>제 13 조 (정보의 제공 및 광고의 게재)
									<br>
									<br>① <b>(주)KARFE</b>는 서비스를 운용함에 있어서 각종 정보를 서비스에 게재하는 방법 등으로 회원에게 제공할 수 있습니다.
									<br>
									<br>② <b>(주)KARFE</b>는 서비스의 운용과 관련하여 서비스 화면, 홈페이지 등에 광고 등을 게재할 수 있습니다.
									<br>
									<br>제 14 조 (서비스 제공의 중지)
									<br>
									<br><b>(주)KARFE</b>는 다음 각 호에 해당하는 경우 서비스의 제공을 중지할 수 있습니다. 
									<br>
									<br>1. 설비의 보수 등을 위하여 부득이한 경우 
									<br>2. 전기통신사업법에 규정된 기간통신사업자가 전기통신서비스를 중지하는 경우 
									<br>3. 기타 귀사가 서비스를 제공할 수 없는 사유가 발생한 경우.
									<br>
									<br>
									<br>제 4 장   서비스와 관련한 권리·의무관계
									<br>
									<br>제 15 조 (의무) 
									<br>
									<br>① <b>(주)KARFE</b>는 제12조 및  제14조에서 정한 경우를 제외하고  이 약관에서 정한 바에  따라 계속적, 안정적으로 서비스를 제공할 수 있도록 최선의 노력을 다하여야 합니다.
									<br>
									<br>② <b>(주)KARFE</b>는 서비스에 관련된 설비를 항상 운용할 수 있는 상태로 유지·보수하고, 장애가 발생하는 경우 지체없이 이를 수리·복구할 수 있도록 최선의 노력을 다하여야 합니다.
									<br>
									<br>③ <b>(주)KARFE</b>는 서비스와 관련한 회원의 불만사항이 접수되는 경우 이를 즉시 처리하여야 하며, 즉시 처리가 곤란한 경우 그 사유와 처리일정을 서비스 또는 전자우편를 통하여 동 회원에게 통지하여야 합니다.
									<br>
									<br>제 16 조 (사생활의 보호)
									<br>
									<br>① <b>(주)KARFE</b>는 서비스와 관련하여 모든회원의 정보를 부분 공개함을 원칙으로 한다.
									<br>  다음 각 호에 해당하는 경우에는 완전 공개할 수 도 있다.
									<br>
									<br>1. 관계법령에 의하여 수사상의 목적으로 관계기관으로부터 요구받은 경우
									<br>2. 정보통신윤리위원회의 요청이 있는 경우
									<br>3. 기타 관계법령에 의한 경우
									<br>
									<br>제 17 조 (회원의 의무)
									<br>
									<br>① 회원은 관계법령, 이 약관의  규정, 이용안내 및 주의사항 등이 통지하는 사항을 준수하여야 하며, 기타의 업무에 방해되는 행위를 하여서는 아니됩니다.
									<br>
									<br>② 회원은 <b>(주)KARFE</b>의 사전 승낙없이 서비스를 이용하여 어떠한 영리행위도 할 수 없습니다.
									<br>
									<br>③ 회원은 서비스를 이용하여 얻은 정보를 <b>(주)KARFE</b>의 사전 승낙없이 복사, 복제, 변경, 번역, 출판·방송 기타의 방법으로 사용하거나 이를 타인에게 제공할 수 없습니다.
									<br>
									<br>④ 회원은 이용신청서의 기재내용 중 변경된 내용이 있는 경우 서비스를 통하여 그 내용을 수정하여야 한다.
									<br>
									<br>⑤ 회원은 서비스 이용과 관려하여 다음 각 호의 행위를 하여서는 아니됩니다.
									<br>      
									<!--
									<br>1. 다른 회원의 아이디(ID)를 부정사용하는 행위
									--->
									<br>1. 다른 회원의 메일을 부정사용하는 행위
									<br>2. 범죄행위를 목적으로 하거나 기타 범죄행위와 관련된 행위
									<br>3. 선량한 풍속, 기타 사회질서를 해하는 행위
									<br>4. 타인의 명예를 훼손하거나 모욕하는 행위
									<br>5. 타인의 지적재산권 등의 권리를 침해하는 행위 
									<br>6. 해킹행위 또는 컴퓨터바이러스의 유포행위
									<br>7. 타인의 의사에 반하여 광고성 정보 등 일정한 내용을 지속적으로 전송하는 행위
									<br>8. 서비스의 안전적인 운영에 지장을 주거나 줄 우려가 있는 일체의 행위
									<br>9. 기타 관계법령에 위배되는 행위
									<br>
									<br>제 18 조 (게시물 또는 내용물의 삭제) 
									<br>
									<br><b>(주)KARFE</b>는 서비스의 게시물 또는 내용물이 제17조의 규정에 위반되거나 소정의 게시기간을 초과하는 경우 사전 통지나 동의없이 이를 삭제할 수 있습니다.
									<br>
									<br>제 19 조 (게시물에 대한 권리·의무) 
									<br>
									<br>게시물에 대한 저작권을 포함한 모든 권리 및 책임은 이를 게시한 회원에게 있습니다.
									<br>
									<br>
									<br>제 5 장   기  타
									<br>
									<br>제 20 조 (양도금지)
									<br>
									<br>회원이 서비스의 이용권한, 기타 이용계약상 지위를 타인에게 양도, 증여할 수 없으며, 이를 담보로 제공할 수 없습니다.
									<br>
									<br>제 21 조 (계약해지 및 이용제한) 
									<br>
									<br>① 회원이 이용계약을 해지하고자 할 때에는 직접 회원탈퇴 작업을 할 수 있다.
									<br>
									<br>② <b>(주)KARFE</b>는 회원이 제17조 기타 이 약관의 내용을 위반하고, 소정의 기간 이내에 이를 해소하지 아니하는 경우 서비스 이용계약을 해지할 수 있습니다.
									<br>
									<br>③ <b>(주)KARFE</b>는 제2항에 의해 해지된 회원이 다시 이용신청을 하는 경우 일정기간 그 승낙을 제한할 수 있습니다.
									<br>
									<br>제 22 조 (손해배상)
									<br>
									<br><b>(주)KARFE</b>이 제공하는 서비스와 관련하여 회원에게 어떠한 손해가 발생하더라도 동 손해가 <b>(주)KARFE</b>의 중대한 과실에 의한 경우를 제외하고 이에 대하여 책임을 부담하지 아니합니다.
									<br>
									<br>제 23 조 (면책·배상)
									<br>
									<br>① <b>(주)KARFE</b>는 회원이 서비스에 게재한 정보, 자료, 사실의 정확성, 신뢰성 등 그 내용에 관하여는 어떠한 책임을 부담하지 아니하고, 회원은 자기의 책임아래 서비스를 이용하며, 서비스를 이용하여 게시 또는 전송한 자료 등에 관하여 손해가 발생하거나 자료의 취사선택, 기타 서비스 이용과 관련하여 어떠한 불이익이 발생하더라도 이에 대한 모든 책임은 회원에게 있습니다.
									<br>
									<br>② 회원 메일과 비밀번호의 관리 및 이용상의 부주의로 인하여 발생되는 손해 또는 제3자에 의한 부정사용 등에 대한 책임은 모두 회원에게 있습니다.
									<br>
									<br>제 24 조 (분쟁의 해결) 
									<br>
									<br>① <b>(주)KARFE</b>과 회원은 서비스와 관련하여 발생한 분쟁을 원만하게 해결하기 위하여 필요한 모든 노력을 하여야 합니다.
									<br>
									<br>② 제1항의 규정에도 불구하고, 동 분쟁으로 인하여 소송이 제기될 경우 동 소송은 본사소재지를 관할하는 법원의 관할로 합니다.
									<br>
									<br>
									<br>
									<br>
									<br>부  칙 
									<br>
									<br>
									<br>제 1 조 (시행일)
									<br>
									<br>이 약관은 2014년 10월 10일부터 시행합니다.  
									<br>
									<br>
									<br>제 2 조 (수정일)
									<br>
									<br>이 약관은 2014년 12월 15일부터 시행합니다.  
									<br>
								</td>
							</tr>
						</tbody>
						</table>
							</td>
								</tr>
									</tbody>
							</table>
							</td>
							</tr>
							<tr>
							<td>
								<img src="http://www.smartfitkorea.com/image/main_old/privacy_04.gif" alt="" width="624" height="142" border="0">
							</td>
						</tr>
					</tbody>
				</table>
		`
		termPopup.document.write(HTML);
	}

	function PrivacyPop(){
		const PrivacyPopup = window.open('/Term.html', '_blank', 'width = 680, height = 500, scrollbars=1', false);
		const HTML = `
			<table width="624" border="0" cellpadding="0" cellspacing="0">
				<tbody><tr>
					<td>
						<img src="http://www.smartfitkorea.com/image/main_old/privacy_01.gif" width="624" height="110" alt=""></td>
				</tr>
				<tr>
					<td background="http://www.smartfitkorea.com/image/main_old/privacy_02.gif" width="624" height="8" align="center">
						<table width="580">
							<tbody><tr><td height="10"></td></tr>
							<tr>
								<td width="540" style="padding:0 30px;">
								<style>
				<!--  
				a { cursor:hand; }
				a:link, a:visited, a:active { text-decoration:none; color:black;}  
				a:hover { text-decoration:underline;color:red; }  
				body  { font-size: 9pt;line-height : 15pt;font-style:굴림}  
				table { font-size: 9pt; }  
				font  { font-size: 9pt;text-decoration: none; }
				TD,SELECT,DIV,form,TEXTAREA { font-size:9pt; }
				P,td,br { font-size:9pt; }
				-->
				</style>
				<table width="100%" cellpadding="0" cellspacing="0" border="0" style="font-size:9pt;">
					<tbody><tr>
						<td>
				◇ <b>(주)KARFE</b> 개인정보 보호정책 ◇
				<br>
				<br>1. 개인정보의 수집범위 및 이용목적
				<br><br><b>(주)KARFE</b>는 별도의 회원가입 절차 없이 컨텐츠에 자유롭게 접근할 수 있습니다. <b>(주)KARFE</b>는 회원제 서비스를 이용하시고자 할 경우 다음의 정보를 입력해주셔야 합니다.
				<br>
				<br>1) 회원 가입시 수집하는 개인정보의 범위 
				<br>
				<!--
				<br> - ID, 성명, 비밀번호, 생년월일, 이메일, 전화번호, 주소 등
				-->
				<br> - 메일, 이름, 비밀번호, 전화번호 등
				<br>
				<br>2) 회사는 수집한 개인정보를 다음의 목적을 위해 활용합니다. 
				<br>
				<br> - 서비스 제공에 관한 계약 이행 및 서비스 제공에 따른 요금정산 콘텐츠 제공, 구매 및 요금결제, 물품배송 또는 청구지 등 발송, 요금추심. 
				<br> - 회원 관리회원제 서비스 이용에 따른 본인확인, 개인식별, 불량회원의 부정 이용 방지와 비인가 사용방지, 불만처리 등 민원처리 고지사항 전달. 
				<br> - 마케팅 및 광고에 활용 신규 서비스(제품) 개발 및 특화, 이벤트 등 광고성 정보전달. 
				<br>
				<br>2. 정보수집에 대한 동의
				<br><b>(주)KARFE</b>는 귀하께서 이용약관 및 개인정보보호방침을 충분히 숙지하고 동의할 수 있는 절차를 마련하여, 체크버튼을 클릭하면 개인정보 수집에 대해 동의한 것으로 봅니다.
				<br>
				<br>3. 정보의 수집목적 및 이용목적 
				<br>① <b>(주)KARFE</b>는 다음과 같은 목적을 위하여 개인정보를 수집하고 있습니다. 
				<br>
				<br> - 메일, 이름, 비밀번호 : 회원제 서비스 이용에 따른 본인 식별 절차에 이용 
				<br> - 전화번호, 핸드폰번호 : 고지사항 전달, 본인 의사 확인, 불만 처리 등 원활한 의사소통 경로의 확보, 새로운 서비스/신상품이나 이벤트 정보의 안내 
				<br> - 그 외 선택항목 : 개인맞춤 서비스를 제공하기 위한 자료 
				<br>
				<br>② 단, 이용자의 기본적 인권 침해의 우려가 있는 민감한 개인정보(인종 및 민족, 사상 및 신조, 출신지 및 본적지, 정치적 성향 및 범죄기록, 건강상태 및 성생활 등)는 수집하지 않습니다. 
				<br>
				<br>4. 쿠키에 대한 개인정보 수집 
				<br>① 쿠키(cookie)란? 
				<br><b>(주)KARFE</b>는 귀하에 대한 정보를 저장하고 수시로 찾아내는 "쿠키(cookie)"를 사용합니다. 쿠키는 웹사이트가 귀하의 컴퓨터 브라우저(넷스케이프, 인터넷 익스플로러 등)로 전송하는 소량의 정보입니다. 귀하께서 웹사이트에 접속을 하면 <b>(주)KARFE</b>는 컴퓨터는 귀하의 브라우저에 있는 쿠키의 내용을 읽고, 귀하의 추가정보를 귀하의 컴퓨터에서 찾아 접속에 따른 성명 등의 추가 입력 없이 서비스를 제공할 수 있습니다. 쿠키는 귀하의 컴퓨터는 식별하지만 귀하를 개인적으로 식별하지는 않습니다. 
				<br>
				<br>② <b>(주)KARFE</b>의 쿠키(cookie) 운용 
				<br>회원님께서 <b>(주)KARFE</b>에 접속하신 후 이용자의 편의와 개인화된 서비스 및 맞춤화된 서비스를 이용하시기 위해서는 쿠키를 허용하여야 합니다. <b>(주)KARFE</b>이 쿠키를 통해 수집하는 정보는 <b>(주)KARFE</b> 회원 ID에 한하며, 그 외의 다른 정보는 수집하지 않습니다. <b>(주)KARFE</b>이 쿠키(cookie)를 통해 수집한 회원 ID는 다음의 목적을 위해 사용됩니다. 
				<br>
				<br> - 개인의 관심 분야에 따라 차별화된 정보를 제공 
				<br> - 회원과 비회원의 접속빈도 또는 머문시간 등을 분석하여 이용자의 취향과 관심분야를 파악하여 타겟(target) 마케팅에 활용 
				<br> - 회원들의 습관을 분석하여 서비스 개편 등의 척도 
				<br> - 게시판 글 등록 
				<br>
				<br>쿠키는 브라우저의 종료시나 로그아웃시 만료됩니다. 
				<br>또한 귀하는 쿠키에 대한 선택권이 있습니다. 웹브라우저 상단의 도구 &gt; 인터넷옵션 탭(option tab)에서 모든 쿠키를 다 받아들이거나, 쿠키가 설치될 때 통지를 보내도록 하거나, 아니면 모든 쿠키를 거부할 수 있는 선택권을 가질 수 있습니다. 단, 쿠키를 허용하시지 않을 경우에는 <b>(주)KARFE</b>에서 제공해드리는 서비스중 로그인이 필요한 일부 서비스를 이용하실 수 없게 됩니다. 
				<br>
				<br>5. 목적 외 사용 및 제 3자에 대한 제공 및 공유 
				<b>(주)KARFE</b>는 귀하의 개인정보를 「개인정보의 수집목적 및 이용목적」에서 고지한 범위내에서 사용하며, 동 범위를 초과하여 이용하거나 타인 또는 타기업, 기관에 제공하지 않습니다. 
				<br>
				<br>1) 제휴관계 : 
				<br>보다 나은 서비스 제공을 위하여 귀하의 개인정보를 제휴사에게 제공하거나 또는 제휴사와 공유할 수 있습니다. 개인정보를 제공하거나 공유할 경우에는 사전에 귀하께 제휴사가 누구인지, 제공 또는 공유되는 개인정보항목이 무엇인지, 왜 그러한 개인정보가 제공되거나 공유되어야 하는지, 그리고 언제까지 어떻게 보호, 관리되는지에 대해 개별적으로 전자우편 및 사이트 공지사항을 통해 고지하여 동의를 구하는 절차를 거치게 되며, 귀하께서 동의하지 않는 경우에는 제휴사에게 제공하거나 제휴사와 공유하지 않습니다. 제휴관계에 변화가 있거나 제휴관계가 종결될 때도 같은 절차에 의하여 고지하거나 동의를 구합니다. 
				<br>
				<br>2) 위탁 처리 : 
				<br>원활한 업무 처리를 위해 이용자의 개인정보를 위탁 처리할 경우 반드시 사전에 위탁처리 업체명과 위탁 처리되는 개인정보의 범위, 업무위탁 목적, 위탁 처리되는 과정, 위탁관계 유지기간 등에 대해 상세하게 고지합니다. 
				<br>
				<br>3) 매각, 인수합병 등 : 
				<br>서비스제공자의 권리와 의무가 완전 승계, 이전되는 경우 반드시 사전에 정당한 사유와 절차에 대해 상세하게 고지할 것이며 이용자의 개인정보에 대한 동의 철회의 선택권을 부여합니다. 
				<br>
				<br>4) 고지 및 동의방법 : 
				<br>온라인 홈페이지 초기화면의 공지사항을 통해 최소 10일 이전부터 고지함과 동시에 이메일 등을 이용하여 1회 이상 개별적으로 고지합니다. 이때 동의는 회원으로부터의 적극적 동의에 의해서만 절차를 진행합니다. 
				<br>
				<br>5) 다음은 예외로 합니다. 
				<br>
				<br> - 관계법령에 의하여 수사상의 목적으로 관계기관으로부터의 요구가 있을 경우 
				<br> - 통계작성, 학술연구나 시장조사를 위하여 특정 개인을 식별할 수 없는 형태로 광고주, 협력사나 연구단체 등에 제공하는 경우 
				<br> - 기타 관계법령에서 정한 절차에 따른 요청이 있는 경우 
				<br> - 그러나 예외 사항에서도 관계법령에 의하거나 수사기관의 요청에 의해 정보를 제공한 경우에는 이를 당사자에게 고지하는 것을 원칙으로 운영하고 있습니다. 법률상의 근거에 의해 부득이하게 고지를 하지 못할 수도 있습니다. 본래의 수집목적 및 이용목적에 반하여 무분별하게 정보가 제공되지 않도록 최대한 노력하겠습니다. 
				<br>
				<br>6. 개인정보의 열람, 정정, 이용내역 조회 
				<br>① 귀하는 언제든지 등록되어 있는 귀하의 개인정보를 열람하거나 정정하실 수 있습니다. 개인정보 열람 및 정정을 하고자 할 경우에는 『회원정보수정』, 또는 개인정보와 관련된 『정보수정』을 클릭하여 직접 열람 또는 정정하거나, 개인정보관리책임자 및 담당자에게 서면, 전화 또는 E-mail로 연락하시면 지체없이 조치하겠습니다. 
				<br>
				<br>② 귀하는 언제든지 귀하의 개인정보 이용내역 및 제 3자에게 제공한 내역을 조회, 요청하실 수 있습니다. 
				<br>개인정보 이용내역이나 제3자에의 제공내역을 알고자 하실 경우에는 개인정보관리책임자 및 담당자에게 서면, 전화 또는 E-mail로 연락하시면 지체없이 조치하겠습니다. 
				<br>
				<br>③ 귀하가 개인정보의 오류에 대한 정정을 요청한 경우, 정정을 완료하기 전까지 당해 개인 정보를 이용 또는 제공하지 않습니다. 
				<br>
				<br>④ 잘못된 개인정보를 제3자에게 이미 제공한 경우에는 정정 처리결과를 제3자에게 지체없이 통지하여 정정하도록 조치하겠습니다. 
				<br>
				<br>7. 개인정보 수집, 이용, 제공에 대한 동의철회 
				<br>① 회원가입 등을 통해 개인정보의 수집, 이용, 제공에 대해 귀하께서 동의하신 내용을 귀하는 언제든지 철회하실 수 있습니다. 동의철회는 홈페이지 첫 화면의 『동의철회(회원탈퇴)』를 클릭하거나 개인정보관리책임자에게 서면, 전화, E-mail등으로 연락하시면 즉시 개인정보의 삭제 등 필요한 조치를 하겠습니다. 동의 철회를 하고 개인정보를 파기하는 등의 조치를 취한 경우에는 그 사실을 귀하께 지체없이 통지하도록 하겠습니다. 
				<br>
				<br>② <b>(주)KARFE</b>는 개인정보의 수집에 대한 동의철회(회원탈퇴)를 개인정보를 수집하는 방법보다 쉽게 할 수 있도록 필요한 조치를 취하겠습니다. 
				<br>
				<br>8. 개인정보의 보유기간 및 이용기간 
				<br>① 귀하의 개인정보는 다음과 같이 개인정보의 수집목적 또는 제공받은 목적이 달성되면 파기됩니다. 단, 상법 등 관련법령의 규정에 의하여 다음과 같이 거래 관련 권리 의무 관계의 확인 등을 이유로 일정기간 보유하여야 할 필요가 있을 경우에는 일정기간 보유합니다. 
				<br>회원가입정보의 경우, 회원가입을 탈퇴하거나 회원에서 제명된 경우 등 일정한 사전에 보유목적, 기간 및 보유하는 개인정보 항목을 명시하여 동의를 구합니다. 
				<br>
				<br> - 계약 또는 청약철회 등에 관한 기록 : 5년 
				<br> - 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년 
				<br> - 표시, 광고에 관한 기록 : 6개월 
				<br>
				<br>② 귀하의 동의를 받아 보유하고 있는 거래정보 등을 귀하께서 열람을 요구하는 경우 <b>(주)KARFE</b>는 지체없이 열람, 확인 할 수 있도록 조치합니다. 
				<br>
				<br>9. 개인정보보호를 위한 기술 및 관리적 대책 
				<br>① 기술적 대책 
				<br><b>(주)KARFE</b>는 귀하의 개인정보를 취급함에 있어 개인정보가 분실, 도난, 누출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은 기술적 대책을 강구하고 있습니다. 
				<br>
				<br> - 귀하의 개인정보는 비밀번호에 의해 보호되며 파일 및 전송데이터를 암호화하거나 파일 잠금기능(Lock)을 사용하여 중요한 데이터는 별도의 보안기능을 통해 보호되고 있습니다. 
				<br> - <b>(주)KARFE</b>는 백신프로그램을 이용하여 컴퓨터바이러스에 의한 피해를 방지하기 위한 조치를 취하고 있습니다. 백신프 로그램은 주기적으로 업데이트되며 갑작스런 바이러스가 출현할 경우 백신이 나오는 즉시 이를 제공함으로써 개인정보가 침해되는 것을 방지하고 있습니다. 
				<br> - <b>(주)KARFE</b>는 암호알고리즘을 이용하여 네트워크 상의 개인정보를 안전하게 전송할 수 있는 보안장치(SSL 또는 SET) 를 채택하고 있습니다. 
				<br> - 해킹 등 외부침입에 대비하여 각 서버마다 침입차단시스템 및 취약점 분석시스템 등을 이용하여 보안에 만전을 기하고 있습니다. 
				<br>
				<br>② 관리적 대책 
				<br> - <b>(주)KARFE</b>는 귀하의 개인정보에 대한 접근권한을 최소한의 인원으로 제한하고 있습니다. 그 최소한의 인원에 해당하 는 자는 다음과 같습니다. 
				<br>
				<br>- 이용자를 직접 상대로 하여 마케팅 업무를 수행하는 자 
				<br>- 개인정보관리책임자 및 담당자 등 개인정보관리업무를 수행하는 자 
				<br>- 기타 업무상 개인정보의 취급이 불가피한 자 
				<br>
				<br> - 개인정보를 취급하는 직원을 대상으로 새로운 보안 기술 습득 및 개인정보 보호 의무 등에 관해 정기적인 사내 교육 및 외부 위탁교육을 실시하고 있습니다. 
				<br> - 입사 시 전 직원의 보안서약서를 통하여 사람에 의한 정보유출을 사전에 방지하고 개인정보보호정책에 대한 이행사항 및 직원의 준수여부를 감사하기 위한 내부절차를 마련하고 있습니다. 
				<br> - 개인정보 관련 취급자의 업무 인수인계는 보안이 유지된 상태에서 철저하게 이뤄지고 있으며 입사 및 퇴사 후 개인정보 사고에 대한 책임을 명확화하고 있습니다. 
				<br> - 개인정보와 일반 데이터를 혼합하여 보관하지 않고 별도의 서버를 통해 분리하여 보관하고 있습니다. 
				<br> - 전산실 및 자료 보관실 등을 특별 보호구역으로 설정하여 출입을 통제하고 있습니다. 
				<br> - <b>(주)KARFE</b>는 이용자 개인의 실수나 기본적인 인터넷의 위험성 때문에 일어나는 일들에 대해 책임을 지지 않습니다. 회원 개개인이 본인의 개인정보를 보호하기 위해서 자신의 ID 와 비밀번호를 적절하게 관리하고 여기에 대한 책임을 져야 합니다. 
				<br> - 그 외 내부 관리자의 실수나 기술관리 상의 사고로 인해 개인정보의 상실, 유출, 변조, 훼손이 유발될 경우 <b>(주)KARFE</b> 은 즉각 귀하께 사실을 알리고 적절한 대책과 보상을 강구할 것입니다. 
				<br>
				<br>10. 링크사이트
				<br><b>(주)KARFE</b>는 귀하께 다른 회사의 웹사이트 또는 자료에 대한 링크를 제공할 수 있습니다. 이 경우 <b>(주)KARFE</b>는 외부사이트 및 자료에 대한 아무런 통제권이 없으므로 그로부터 제공받는 서비스나 자료의 유용성에 대해 책임질 수 없으며 보증할 수 없습니다. <b>(주)KARFE</b>이 포함하고 있는 링크를 클릭(click)하여 타 사이트(site)의 페이지로 옮겨갈 경우 해당 사이트의 개인정보보호정책은 <b>(주)KARFE</b>과 무관하므로 새로 방문한 사이트의 정책을 검토해 보시기 바랍니다. 
				<br>
				<br>11. 게시물
				<br>① <b>(주)KARFE</b>는 귀하의 게시물을 소중하게 생각하며 변조, 훼손, 삭제되지 않도록 최선을 다하여 보호합니다. 그러나 다음의 경우는 그렇지 아니합니다. 
				<br>
				<br> - 스팸(spam)성 게시물 (예 : 행운의 편지, 8억 메일, 특정사이트 광고, 돈버는 광고 등) 
				<br> - 타인을 비방할 목적으로 허위 사실을 유포하여 타인의 명예를 훼손하는 글 
				<br> - 동의 없는 타인의 신상공개, <b>(주)KARFE</b>의 저작권, 제 3자의 저작권 등 권리를 침해하는 내용, 기타 게시판 주제와 다른 내용의 게시물 
				<br> - <b>(주)KARFE</b>는 바람직한 게시판 문화를 활성화하기 위하여 동의 없는 타인의 신상 공개시 특정부분을 삭제하거나 기호 등으로 수정하여 게시할 수 있습니다. 
				<br> - 다른 주제의 게시판으로 이동 가능한 내용일 경우 해당 게시물에 이동 경로를 밝혀 오해가 없도록 하고 있습니다. 
				<br> - 그 외의 경우 명시적 또는 개별적인 경고 후 삭제 조치할 수 있습니다. 
				<br>
				<br>② 근본적으로 게시물에 관련된 제반 권리와 책임은 작성자 개인에게 있습니다. 또 게시물을 통해 자발적으로 공개된 정보는 보호 받기 어려우므로 정보 공개 전에 심사숙고하시기 바랍니다. 
				<br>
				<br>12. 개인정보의 위탁처리 
				<br>① <b>(주)KARFE</b>는 서비스 향상을 위해서 귀하의 개인정보를 외부에 수집, 취급, 관리등을 위탁하여 처리할 수 있습니다. 
				<br>
				<br> - 개인정보의 처리를 위탁하는 경우에는 위탁기관 및 그 사실을 홈페이지를 통해 미리 귀하에게 고지하겠습니다. 
				<br> - 개인정보의 처리를 위탁하는 경우에는 위탁계약 등을 통하여 서비스제공자의 개인정보보호 관련 지시엄수, 개인정보에 관한 비밀유 지, 제3자 제공의 금지 및 사고시의 책임부담, 위탁기간, 처리 종료후의 개인정보의 반환 또는 파기 등을 명확히 규정하고 당해 계약 내용을 서면 또는 전자적으로 보관하겠습니다. 
				<br>
				<br>② 현재 <b>(주)KARFE</b>의 고객정보관리는 <b>(주)KARFE</b>에서처리하고 있으며, 고객정보관리가 엄격히 되고 있는 지 감독하고 있습니다. 
				<br>
				<br>13. 이용자의 권리와 의무 
				<br>① 귀하의 개인정보를 최신의 상태로 정확하게 입력하여 불의의 사고를 예방해 주시기 바랍니다. 이용자가 입력한 부정확한 정보로 인해 발생하는 사고의 책임은 이용자 자신에게 있으며 타인 정보의 도용 등 허위정보를 입력할 경우 회원자격이 상실될 수 있습니다. 
				<br>
				<br>② 귀하는 개인정보를 보호받을 권리와 함께 스스로를 보호하고 타인의 정보를 침해하지 않을 의무도 가지고 있습니다. 비밀번호를 포함한 귀하의 개인정보가 유출되지 않도록 조심하시고 게시물을 포함한 타인의 개인정보를 훼손하지 않도록 유의해 주십시오. 만약 이 같은 책임을 다하지 못하고 타인의 정보 및 존엄성을 훼손할 시에는 『정보통신망이용촉진및정보보호등에관한법률』등에 의해 처벌받을 수 있습니다. 
				<br>
				<br>14. 의견수렴 및 불만처리 
				<br>①당사는 귀하의 의견을 소중하게 생각하며, 귀하는 의문사항으로부터 언제나 성실한 답변을 받을 권리가 있습니다. 
				<br>
				<br>②당사는 귀하와의 원활환 의사소통을 위해 고객문의센터를 운영하고 있으며 연락처는 다음과 같습니다. 
				<br>
				<br>【 민원처리센터 】 
				<br> - 메인페이지 하단의 전화번호나 팩스, 고객센터로 연락주시면 됩니다.
				<br>
				<br>③전화상담은 평일 오전 9:00 ~ 오후 6:00 에만 가능합니다. 
				<br>
				<br>④전자우편이나 팩스 및 우편을 이용한 상담은 접수 후 빠른 시간 내에 성실하게 답변 드리겠습니다. 
				다만, 근무시간 이후 또는 주말 및 공휴일에는 익일 처리하는 것을 원칙으로 합니다. 
				<br>
				<br>⑤기타 개인정보에 관한 상담이 필요한 경우에는 개인정보침해신고센터로 문의하실 수 있습니다. 
				<br>
				<br>◑ 개인정보침해신고센터 
				<br> - 전화 : 1336 
				<br> - URL : <a href="http://www.cyberprivacy.or.kr" target="_blank">http://www.cyberprivacy.or.kr</a>
				<br>
				<br>◑ 정보보호마크 인증위원회 
				<br> - 전화 : 02-580-0533 
				<br> - URL : <a href="http://www.privacymark.or.kr" target="_blank">http://www.privacymark.or.kr</a>
				<br>
				<br>◑ 대검찰청 인터넷범죄수사센터 
				<br> - 전화 : 02-3480-3600 
				<br> - URL : <a href="http://icic.sppo.go.kr" target="_blank">http://icic.sppo.go.kr</a>
				<br>
				<br>◑ 경찰청 사이버범죄수사대 
				<br> - URL : <a href="http://www.police.go.kr/cybercenter" target="_blank">http://www.police.go.kr/cybercenter</a>
				<br>
				<br>15. 개인정보관리 책임자 및 담당자 
				<br><b>(주)KARFE</b>는 귀하가 좋은 정보를 안전하게 이용할 수 있도록 최선을 다하고 있습니다. 개인정보를 보호하는데 있어 귀하께 고지한 사항들에 반하는 사고가 발생할 시에 개인정보관리책임자가 모든 책임을 집니다. 
				<br>그러나 기술적인 보완조치를 했음에도 불구하고, 해킹 등 기본적인 네트워크상의 위험성에 의해 발생하는 예기치 못한 사고로 인한 정보의 훼손 및 방문자가 작성한 게시물에 의한 각종 분쟁에 관해서는 책임이 없습니다. 
				귀하의 개인정보를 취급하는 책임자 및 담당자는 다음과 같으며 개인정보 관련 문의사항에 신속하고 성실하게 답변해드리고 있습니다. 
				<br>
				<br>16. 아동의 개인정보 보호 
				<br><b>(주)KARFE</b>는 물품의 직거래, 부동산 정보의 거래 및 인터넷 리쿠르팅 의 특성상 해당 생활정보를 등록하고, 원활한 활동을 할 수 없다고 판단하여 14세 미만 아동의 회원가입을 허용하지 않습니다. 
				<br>
				<br>17. 광고성 정보전송 
				<br>① <b>(주)KARFE</b>는 귀하의 명시적인 수신거부의사에 반하여 영리목적의 광고성 정보를 전송하지 않습니다. 
				<br>
				<br>② <b>(주)KARFE</b>는 귀하가 뉴스레터 등 전자우편 전송에 대한 동의를 한 경우 전자우편의 제목란 및 본문란에 다음 사항과 같이 귀하가 쉽게 알아 볼 수 있도록 조치합니다. 
				<br>
				<br> - 전자우편의 제목란 : (광고)라는 문구를 제목란에 표시하지 않을 수 있으며 전자우편본문란의 주요 내용을 표시합니다. 
				<br>
				<br> - 전자우편의 본문란 : 
				<br>- 귀하가 수신거부의 의사표시를 할 수 있는 전송자의 명칭, 전자우편주소, 전화번호 및 주소를 명시합니다. 
				<br>- 귀하가 수신 거부의 의사를 쉽게 표시할 수 있는 방법을 한글 및 영문으로 각 각 명시합니다. 
				<br>- 귀하가 동의를 한 시기 및 내용을 명시합니다. 
				<br>
				<br>③ <b>(주)KARFE</b>는 상품정보 안내 등 온라인 마케팅을 위해 광고성 정보를 전자우편 등으로 전송하는 경우 전자우편의 제목란 및 본문란에 다음 사항과 같이 귀하가 쉽게 알아 볼 수 있도록 조치합니다. 
				<br>
				<br> - 전자우편의 제목란 : (광고)또는(성인광고)라는 문구를 제목란의 처음에 빈칸없이 한글로 표시하고 이어서 전자우편 본문란의 주요 내용을 표시합니다. 
				<br>
				<br> - 전자우편의 본문란 : 
				<br>- 귀하가 수신거부의 의사표시를 할 수 있는 전송자의 명칭, 전자우편주소, 전화번호 및 주소를 명시합니다. 
				<br>- 귀하가 수신 거부의 의사를 쉽게 표시할 수 있는 방법을 한글 및 영문으로 각 각 명시합니다. 
				<br>
				<br>④ 다음과 같이 청소년에게 유해한 정보를 전송하는 경우 "(성인광고)" 문구를 표시합니다. 
				<br>
				<br> - 본문란에 다음 각 항목 1에 해당하는 것이 부호, 문자, 영상 또는 음향의 형태로 표현된 경우(해당 전자우편의 본문란에는 직접 표현 되어있지 아니하더라도 수신자가 내용을 쉽게 확할인 수 있도록 기술적 조치가 되어 있는 경우를 포함한다)에는 해당 전자우편의 제 목란에 "(성인광고)" 문구를 표시합니다. 
				<br>- 청소년(19세미만의 자를 말한다. 이하 같다)에게 성적인 욕구를 자극하는 선정적인 것이거나 음란한 것 
				<br>- 청소년에게 포악성이나 범죄의 충동을 일으킬 수 있는 것 
				<br>- 성폭력을 포함한 각종 형태의 폭력행사와 약물의 남용을 자극하거나 미화하는 것 
				<br>- 청소년보호법에 의하여 청소년 유해매체물로 결정, 고시된 것 
				<br>
				<br> - 영리목적의 광고성 전자우편 본문란에서 제4항 각항목에 해당하는 내용을 다룬 인터넷 홈페이지를 알리는 경우에는 해당 전자우편 의 제목란에 "(성인광고)" 문구를 표시합니다. 
				<br>
				<br>⑤ 팩스, 휴대폰 문자전송등 전자우편 이외의 문자전송을 통해 영리 목적의 광고성 정보를 전송하는 경우에는 전송내용 처음에 "(광고)"라는 문구를 표기하고 전송내용 중에 전송자의 연락처와 수신거부용 무료전화서비스를 명시하도록 조치합니다. 
				<br>
				<br>18. 고지의 의무 
				<br>현 개인정보보호정책은 2014 년 07 월 01 일에 시행되었으며 정부의 정책 또는 보안기술의 변경에 따라 내용의 추가, 삭제 및 수정이 있을 시에는 개정 최소 10일 전부터 홈페이지의 "공지"란을 통해 고지할 것입니다. 
				<br>
				<br>
				<br>
				<br>
				<br>부  칙 
				<br>
				<br>
				<br>제 1 조 (시행일)
				<br>
				<br>이 약관은 2014년 7월 1일부터 시행합니다.  
				<br>
				<!---
				<br>
				<br>제 2 조 (수정일)
				<br>
				<br>이 약관은 2000년 3월 1일부터 시행합니다.  
				<br>
				--->
				</td></tr>
				</tbody></table>
									</td>
								</tr>
							</tbody></table>
						</td>
					</tr>
					<tr><td><img src="http://www.smartfitkorea.com/image/main_old/privacy_04.gif" alt="" width="624" height="142" border="0"></td></tr>
				</tbody>
			</table>
		`
		PrivacyPopup.document.write(HTML);
	}
	return (
		<NavLinkWarpper>
			<NavLinkInner>
				{/* <NavLinkItmes>
					<NavLinks href="http://www.smartfitkorea.com/sfa1991.php">스마트핏 소개</NavLinks>
				</NavLinkItmes> */}
				<NavLinkItmes>
					<NavLinks href="/appintoduce">KARFE 앱 소개</NavLinks>
				</NavLinkItmes>
				<NavLinkItmes>
					<NavLinks onClick={termPop}>이용약관</NavLinks>
				</NavLinkItmes>
				<NavLinkItmes>
					<NavLinks onClick={PrivacyPop}>개인정보취급방침</NavLinks>
				</NavLinkItmes>
				{/*<NavLinkItmes>
					<NavLinks>FAQ</NavLinks>
				</NavLinkItmes>*/}
			</NavLinkInner>
		</NavLinkWarpper>
	)
}

export default NavLink;