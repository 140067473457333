import { Avatar } from "@material-ui/core";
import Axios from "axios";
import React, { useEffect, useState } from "react";
import StarRating from "../../components/StartRating";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import url_check from "./url_check";
import dateRange from "./dateRange";
import moment from "moment";

export default function InstructorDetail({
  match: {
    params: { id },
  },
}) {
  const [trainerData, setTrainergData] = useState();
  useEffect(() => {
    const getTrainerData = async () => {
      const result = await Axios.post(
        "https://api.teamstc.org:3003/api/user/trainer/one",
        { id }
      );
      if (!result?.data?.success) return;
      const company = result.data.result.career.reduce((x, y) => {
        if (y.working === "Y") x.push(y);
        return x;
      }, []);
      setTrainergData({
        ...result.data.result,
        company: company[0],
      });
    };
    getTrainerData();
  }, []);

  console.log(trainerData);

  return trainerData ? (
    <div className="relative mx-auto max-w-[1000px]">
      <div className="w-full flex justify-center items-center pt-[4rem]">
        <Avatar
          // style="width:10rem;"
          className="!w-[10rem] !h-[10rem]"
          src={`https://api.teamstc.org:3003/api/img/trainerinfo/${
            trainerData.mcd
          }.jpg?v=${new Date().getTime()}`}
          alt=""
        />
      </div>
      <div className="flex justify-center items-center flex-col mt-5">
        <p className="text-2xl">{trainerData.name}</p>
        <p className="text-lg opacity-70">{trainerData.ename}</p>
        {trainerData.star.length === 0 || (
          <div className="flex items-center gap-1">
            <p className="text-sm opacity-50">{trainerData.star.rate}</p>
            <StarRating rating={trainerData.star.rate} size="15" />
            <p className="text-xs opacity-50">({trainerData.star.length})</p>
          </div>
        )}
        {trainerData.show_email === "Y" && (
          <p className="text-sm opacity-50">{trainerData.email}</p>
        )}
        {trainerData.show_phone === "Y" && (
          <p className="text-sm opacity-50">{trainerData.phone}</p>
        )}
      </div>
      {trainerData.company && (
        <div className="flex gap-1 mt-5 justify-center items-center">
          <LocationOnIcon className="text-red-400" />
          <p className="text-sm opacity-50">
            {trainerData.company.company_name} ({trainerData.company.position})
            근무중
          </p>
        </div>
      )}
      <div className="flex w-full justify-center gap-7 mt-10 opacity-70">
        {Object.keys(trainerData.sns).map(
          (key, index) =>
            trainerData.sns[key] !== "" && (
              <img
                key={index}
                alt=""
                src={require(`./img/${key}.svg`)}
                width="40"
                height="40"
                onClick={() => {
                  let userUrl = trainerData.sns[key];
                  let addurl = "";
                  if (!url_check(userUrl)) {
                    if (key === "insta") addurl = "https://www.instagram.com/";
                    if (key === "facebook")
                      addurl = "https://www.facebook.com/";
                    if (key === "blog") addurl = "https://blog.naver.com/";
                  }
                  window.open(addurl + userUrl);
                }}
              />
            )
        )}
      </div>

      <ul className="flex flex-wrap gap-2 justify-center mt-5 px-10">
        {trainerData.expects.map((data, index) => (
          <li key={index} className="bg-green-200 px-2 rounded-full text-sm">
            <span className="opacity-50">{data}</span>
          </li>
        ))}
      </ul>

      <div className="mt-10 border-t pt-10 px-3 whitespace-pre-line">
        {trainerData.text_info}
      </div>

      {/* 경력 학력 자격증 */}
      <div className="mt-10 border-t pt-10 px-3">
        <div className="mb-10">
          <p className="font-semibold text-lg mb-2">경력사항</p>
          <div className="ml-3 border-l pl-3 relative">
            {trainerData.career.map((data, index) => (
              <div key={index} className="mb-5">
                <div className="w-2 h-2 rounded-full bg-zinc-400 absolute left-0 -translate-x-[55%] mt-2" />
                {data.sdate !== "" && (
                  <p className="text-sm opacity-50 flex justify-between">
                    {data.sdate} ~{" "}
                    {data.working === "Y" ? "재직중" : data.edate}
                    <span>
                      (
                      {dateRange(
                        data.sdate,
                        data.working === "Y" ? moment() : data.edate
                      )}
                      )
                    </span>
                  </p>
                )}
                <p className="font-semibold opacity-70">{data.company_name}</p>
                {data.position !== "-" && (
                  <p className="text-sm opacity-70">· {data.position}</p>
                )}
              </div>
            ))}
          </div>
        </div>

        <div className="mb-10">
          <p className="font-semibold text-lg mb-2">학력사항</p>
          <div className="ml-3 border-l pl-3 relative">
            {trainerData.education.map((data, index) => (
              <div key={index} className="mb-5">
                <div className="w-2 h-2 rounded-full bg-zinc-400 absolute left-0 -translate-x-[55%] mt-2" />
                <p className="text-sm opacity-50 flex justify-between">
                  {data.sdate} ~{" "}
                  {data.attending === "Y" ? "재학중" : data.edate}
                  <span>
                    (
                    {dateRange(
                      data.sdate,
                      data.attending === "Y" ? moment() : data.edate
                    )}
                    )
                  </span>
                </p>
                <p className="font-semibold opacity-70">{data.school_name}</p>
                <p className="text-sm opacity-70">
                  · {data.major} ({data.degree})
                </p>
              </div>
            ))}
          </div>
        </div>

        <div className="mb-10">
          <p className="font-semibold text-lg mb-2">외부자격증</p>
          <div className="ml-3 border-l pl-3 relative">
            {trainerData.etc_certi.map((data, index) => (
              <div key={index} className="mb-5">
                <div className="w-2 h-2 rounded-full bg-zinc-400 absolute left-0 -translate-x-[55%] mt-2" />
                <div className="flex justify-between">
                  <p className="font-semibold opacity-70 break-keep break-words">
                    {data.certi_name}
                  </p>
                  <span className="text-sm opacity-50 whitespace-nowrap">
                    {data.date}
                  </span>
                </div>
                <p className="text-sm opacity-70">· {data.issuer}</p>
              </div>
            ))}
          </div>
        </div>

        <div className="mb-10">
          <p className="font-semibold text-lg mb-2">Karfe 배지</p>
          <div className="flex flex-col gap-4 ml-4">
            {trainerData.badge.map((data, index) => (
              <div key={index} className="">
                <p>{data.edu_name}</p>
                <p className="text-sm opacity-50">{data.date}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
}
