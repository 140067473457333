import React, {Component} from 'react';

import PesVideo from './Pes_video';
import PesBanner from './Pes_banner';
import PesContents from './Pes_Content';

class Academy_onPes extends Component {
	state = {
		ClientY: 0,
	}

	componentDidMount() {
		this.setState({ClientY: this.BoxTop.offsetTop});
	}

	render() {
		return (
			<div
				ref={ref => {
					this.BoxTop = ref;
				}}
			>
				{this.state.ClientY ? this.props.getCptOffset(this.BoxTop.offsetTop) : ''}
				<PesVideo />
				<PesBanner />
				<PesContents />
			</div>
		);
	}
}

export default Academy_onPes;