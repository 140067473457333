import React, {Component} from 'react';

import styled from 'styled-components';

import Person1 from './img/dns_person1.jpg';
import Person2 from './img/dns_person2.jpg';
import Person3 from './img/dns_person3.jpg';

import RecordThums1 from './img/record_thums1.jpg';
import RecordThums2 from './img/record_thums2.jpg';
import RecordThums3 from './img/record_thums3.jpg';

const RecordBox = styled.div`
	position:relative;
`

const InfoConTit = styled.h2`
    text-align: center;
    font-size: 63px;
    color: #3d3d3d;
	letter-spacing: -5px;
	padding:60px 0 36px 0;
	line-height: 130%;

	&>span{
		font-family: 'Impact';
	}

	@media(max-width:960px){
		font-size: 40px;
		padding-top:60px;
		padding-bottom: 30px;
	}
`

const RecodMenuWrap = styled.div`
	text-align:center;
`

const RecodMenuTopTxt = styled.p`
	padding:0 0 30px 0;
	font-size:20px;
	font-weight:300;
	line-height:140%;
	color: #3d3d3d;

	&>span{
		font-weight:700;
	}

	@media(max-width:960px){

		&>br,&>span>br{
			display:none;
		}
	}
`

const RecodMenuBox = styled.div`
	display:flex;
	max-width:840px;
	margin:0 auto;
`

const RecodMenuItems = styled.div`
	width:31.333%;
	margin:0 1%;
`

const RecordThums = styled.img`
	display:block;
	width:100%;
`

const RecordThumsTxt = styled.p`
	padding:14px 0;
	font-size:20px;
	font-weight:700;

	@media(max-width:960px){
		display:none;
	}
`

const RecordListTxt = styled.p`
	padding:48px 0 36px 0;
	font-size:20px;
	font-weight:300;
	line-height:140%;

	&>span{
		font-weight:700;
	}

	@media(max-width:720px){
		display: inline-block;
		font-size:18px;
		padding:30px 15px;
		&>br,&>span>br{
			display:none;
		}
	}
`

const RecordListThums = styled.img`
	display:block;
	width:100%;
	max-width: 840px;
	margin:0 auto;
`


class Record extends Component {
	componentDidMount() {
		this.props.getOffset('characteristic', this.characteristic);
	}

	render(){
		return(
			<RecordBox
				id="characteristic"
				ref={ref => {
					this.characteristic = ref;
				}}
			>
				<InfoConTit><span>DNS</span> 의 차별성</InfoConTit>

				<RecodMenuWrap>
					<RecodMenuTopTxt>
						1.프라하스쿨 재활운동 및 도수치료 분야의 최고의 권위자들을<br/>
						배출한<span>20세기 재활분야의 최고의 상아탑</span>입니다.
					</RecodMenuTopTxt>
					<RecodMenuBox>
						<RecodMenuItems>
							<span><RecordThums src={Person1} alt="Karel Lewit"/></span>
							<RecordThumsTxt>르윗(Karel Lewit)</RecordThumsTxt>
						</RecodMenuItems>
						<RecodMenuItems>
							<span><RecordThums src={Person2} alt="Vladimir Janda"/></span>
							<RecordThumsTxt>얀다(Vladimir Janda)</RecordThumsTxt>
						</RecodMenuItems>
						<RecodMenuItems>
							<span><RecordThums src={Person3} alt="Vaclav Vojta"/></span>
							<RecordThumsTxt>보이타(Vaclav Vojta)</RecordThumsTxt>
						</RecodMenuItems>
					</RecodMenuBox>
					<div>
						<div>
							<RecordListTxt>
								2. DNS는 급성통증 및 만성적인 통증관리는 물론, <span>스포츠재활 및 소아, 여성들에<br/>
								대한 재활치료분야까지 여러방면에서 적용</span>되고 있으며,  그 결과 또한 좋은 결과로<br />
								이어지고 있어 많은 재활 및 치료 분야에서  새로운 패러다임으로 제시되고 있다.
							</RecordListTxt>
							<span><RecordListThums src={RecordThums1} /></span>
						</div>
						<div>
							<RecordListTxt>
								3. DNS교육과정의 모든 강사들은 대학에서 학문 중심의 연구를 하는 교수로써<br />
								동시에 <span>대학병원 또는 클리닉에서 임상 활동을 통해</span> 이론적인 접근 뿐 아니라 <br />
								이를 기반으로 임상에 <span>실질적인 적용방법들을 그들에게 직접 들을 수 있다.</span>
							</RecordListTxt>
							<span><RecordListThums src={RecordThums2} /></span>
						</div>
						<div>
							<RecordListTxt>
								4. <span>DNS치료법이 유럽의 대부분의 국가에서 의료보험 수가청구가 가능한 제도로 변하</span>고 있다.<br/>
								이는 다른 신경계치료 방법들처럼 DNS의 철학과 치료적 가치를 높이 평가한다는 의미이다.   
							</RecordListTxt>
							<span><RecordListThums src={RecordThums3} /></span>
						</div>
					</div>
				</RecodMenuWrap>
			</RecordBox>
		)
	}
}

export default Record;