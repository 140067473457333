import React, {Component} from 'react';

import styled from 'styled-components';

import Strength1 from './img/strength_1.jpg';
import Strength2 from './img/strength_2.jpg';
import Strength3 from './img/strength_3.jpg';


const CurriculumBox = styled.div`
	display:flex;
	padding:60px 0;
	flex-direction:column;
	align-items: center;
`

const CurriculumTop = styled.div`
	display: flex;
    flex-direction: column;
    align-items: center;
`

const CurriculumTopTxt = styled.p`
	padding:60px 0;
	font-size:40px;
	font-weight:900;
	line-height:140%;
	color:#3d3d3d;
	text-align:center;

	@media(max-width:720px){
		font-size:36px;
		&>br{
			display:none;
		}
	}
`

const CurriculumBottom = styled.div`
	display:flex;
	flex-direction:row;
`

const CurriculumBottomTxt = styled.p`
	padding:24px 0;
	font-size:24px;
	font-weight:400;
	line-height:140%;
	color:#3d3d3d;
	text-align:center;

	@media(max-width:720px){
		font-size:20px;
		&>br{
			display:none;
		}
	}
`

const CurriculumBottomItmes = styled.div`
	width:48%;
	margin:0 1%;
`

const CurriculumThums = styled.img`
	display:block;
	width:100%;
`

const CurriculumThumsTop = styled(CurriculumThums)`
	@media(max-width:960px){
		width:32vw;
		margin:0 auto;
	}
`

class Curriculum extends Component {

	render(){
		return(
			<CurriculumBox 
				id="curriculum"
			>
				<CurriculumTop>
					<div>
						<CurriculumThumsTop src={Strength1}/>
					</div>
					<CurriculumTopTxt>스트렝스&amp;코디네이션 트레이닝의<br/> 새로운 패러다임!</CurriculumTopTxt>
				</CurriculumTop>
				<CurriculumBottom>
					<CurriculumBottomItmes>
						<div>
							<CurriculumThums src={Strength2} />
						</div>
						<CurriculumBottomTxt>중추명령 이론을 뛰엄 넘는<br />탈중추명령이론</CurriculumBottomTxt>
					</CurriculumBottomItmes>
					<CurriculumBottomItmes>
						<div>
							<CurriculumThums src={Strength3} />
						</div>
						<CurriculumBottomTxt>움직임 자기조직화를 통한<br />운동수행능력 향상 이론!</CurriculumBottomTxt>
					</CurriculumBottomItmes>
				</CurriculumBottom>
			</CurriculumBox>
		)
	}
}


export default Curriculum;