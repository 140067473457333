import React from 'react';

import BtmBannerBg from './img/btm_banner_bg.jpg';
import MobileBtmBannerBg from './img/m_btm_banner_bg.jpg';

import styled from 'styled-components';

const BtmBannerWarrper = styled.section`
	position:relative;
	top: 0;
    left: 0;
    width: 100%;
    height: 0;
    margin: 0 auto;
	padding-bottom: 35.5%;
	
	@media (max-width:720px){
		height:320px;
		padding-bottom:0;
	}
`

const BtmBannerBox = styled.div`
	position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
	background:url(${BtmBannerBg}) 0 0 no-repeat;
	background-size:100%;

	@media (max-width:720px){
		background:url(${MobileBtmBannerBg}) 0 0 no-repeat;
	}
`

const BtmBannerTxt = styled.p`
	position:absolute;
	top:50%;
	left:4%;
	font-size:24px;
	color:#fff;
	opacity: 1;

	@media (max-width:960px){
		font-size: 18px;
	}

	@media (max-width:720px){
		font-size: 12px;
	}
`


const BtmBanner = () => {
	return (
		<BtmBannerWarrper>
			<BtmBannerBox></BtmBannerBox>
			<BtmBannerTxt>Korea Baseball strength & conditioning Institution </BtmBannerTxt>
		</BtmBannerWarrper>
	)
}

export default BtmBanner;