import React from 'react';

import Member01 from './img/member_photo_01.jpg';
import Member02 from './img/member_photo_02.jpg';
import Member03 from './img/member_photo_03.jpg';
import Member04 from './img/member_photo_04.jpg';
import Member05 from './img/member_photo_05.jpg';
import Member06 from './img/member_photo_06.jpg';
import Member07 from './img/member_photo_07.jpg';

import styled from 'styled-components';

const MemberWarrper = styled.div`
	display: flex;
    flex-wrap: wrap;
	max-width:1370px;
	width:100%;
	margin:0 auto;

	&:after{
		content:"";
		display:block;
		clear:both;
	}
`

const MemberContainer = styled.div`
	width: 50%;
	border-bottom:1px solid #fff;

	&:last-child{
		border-bottom:0;
	}
`

const MemberItems = styled.div`
	display: flex;
    flex-direction: row;
	padding: 28px 50px 28px 50px;
	align-items: center;

	@media(max-width:960px){
		padding: 0 5% 0 5%;
	}

	@media(max-width:640px){
		flex-direction: column;
		padding: 28px 2% 28px 2%;
	}
`

const MemberFaceItem = styled.div`
	width:10vw;
	min-width:100px;

	@media(max-width:640px){
		width:100px;
		height:100px;
	}
`

const MemberFaceThums = styled.img`
	display:block;
	width:100%;
`

const MemberExplainBox = styled.div`
	display: flex;
    flex-direction: column;
	padding: 26px 0 26px 58px;
	color: #fff;
	flex: 1 1 auto;

	@media(max-width:960px){
		padding-left:5%;
	}

`

const PersonName = styled.p`
	padding-bottom: 13px;
	font-weight:700;

	@media(max-width:960px){
		font-size:12px;
	}
`

const PersonRecordBox = styled.p`
	flex: 1 0 auto;
`

const PersonRecord = styled.span`
	display:block;
	padding-bottom: 5px;
	opacity: 0.8;

	@media(max-width:960px){
		font-size:12px;
	}
`

const KbsiMemberList = () => {
	const data = [
		{imgs: Member01, name: '장유태 대표', txt: '현, KARFE 교육이사현,\n 연세알찬병원 스포츠재활 센터장현,\n 프리포먼스 교육이사\n현, (사)KFLA 부회장\n 현.스마트핏 프리미엄 교육강사'},
		{imgs: Member02, name: '허준환 대표', txt: '부산외국어대학교 스포츠재활학과 석사과정\n Conan Crossfit WinYourBody 헤드코치\n 영남대학교 사회체육학과 외래교수\n Crossfit Lv.1'},
		{imgs: Member03, name: '최병곤 대표', txt: '현, 청암대학교 재활스포츠학과 겸임교수\n현, NC다이노스 선수 트레이닝 코치\n현, 세팍타크로 국가대표 트레이닝 코치\n현.경남대학교 스포츠과학과 특강강사\n경남대학교 스포츠과학 석사  졸업'},
		{imgs: Member04, name: '정지환 강사', txt: '현, (주)스마트핏 이사\n현, KARFE 사무국장\n동아대학교 체육대학 경기지도학과\n부산가톨릭대학 물리치료학과\n부산외국어대학교 스포츠재활학 석사과정'},
		{imgs: Member05, name: '박지환 강사', txt: '프리포먼스 에슬리츠 코치\n프리포먼스 아카데미 교육강사\n스파이더코리아 공식스폰 선수\nU.S.A track and field Lv.1\nCrossfit Lv.1'},
		{imgs: Member06, name: '이채관 강사', txt: '현. 동원과학기술대학 스포츠재활운동학부 겸인교수\n현 .KARFE 교육이사\n부산대학교 체육교육대학원 석사\n부산카톨릭대 물리치료학 박사과정\n물리치료사 - 보건복지부'},
		{imgs: Member07, name: '김민성 강사', txt: '한서대학교 레저항공스포츠학과\nH퍼스널트레이닝 트레이너\nKARFE Master Instructor\nPerform Better Functional Training(USA)'},
	]

	const SplitMemberTxt = (text) => {
		return text.split('\n').map((splitTxt, idx) => {
			return (
				<PersonRecord key={idx}>{splitTxt}</PersonRecord>
			)
		});
	}
	return (
		<>
			<MemberWarrper>
				{
					data.map((MemberData, idx) => {
						return (
							<MemberContainer key={idx}>
								<MemberItems>
									<MemberFaceItem>
										<MemberFaceThums src={MemberData.imgs}/>
									</MemberFaceItem>
									<MemberExplainBox>
										<PersonName>{MemberData.name}</PersonName>
										<PersonRecordBox>
											{SplitMemberTxt(MemberData.txt)}
										</PersonRecordBox>
									</MemberExplainBox>
								</MemberItems>
							</MemberContainer>
						)
					})
				}
			</MemberWarrper>
		</>
	)
}

export default KbsiMemberList;