import React, { Component } from 'react';

import VideoBtn from './img/master_play.jpg';

import ReplayModal from './Record_Replay_modal';

import styled from 'styled-components';

const PlayBtn = styled.span`
	display: inline-block;
	margin-left:5px;
	vertical-align: middle;
	cursor: pointer;

	@media(max-width:640px){
		float:right;
	}
`

class VideoBtnThums extends Component {
	state = {
		isReply: false,
		isEduSystem: false,
		isNumber: null,
	}

	OpenReplayModal = () => {
		this.setState({
			isReply: true, 
			isNumber: this.props.videoNumber
		});
	}

	CloseReplayModal = () => {
		this.setState({isReply: false});
	}

	render() {

		return (
			<>
				<PlayBtn onClick={this.OpenReplayModal}>
					<img src={VideoBtn} alt="video images"/>
				</PlayBtn>

				{this.state.isReply ? <ReplayModal isReply={this.state.isReply} onCloseModal={this.CloseReplayModal} isNumber={this.state.isNumber}/> : ''}
			</>
		);
	}
}

export default VideoBtnThums;