import React from 'react';

import CourseThums from './img/course_table.jpg';
import MoCourseThums from './img/m_course_table.jpg';

import styled from 'styled-components';

const CourseContainer = styled.div`
	padding:0 0 90px 0;
	background:#e2f5fc;

	@media(max-width:1000px){

	}
`

const CourseImg = styled.img`
	display:block;
	width:100%;
	max-width:1250px;
	margin:0 auto;

	@media(max-width:960px){
		display:none;
	}
`

const MoCourseImg = styled.img`
	display:none;

	@media(max-width:960px){
		display:block;
		width:100%;
	}
`

const TeacherTit = styled.h2`
	padding:120px 0;
	font-size:64px;
	font-weight:100;
	color:#565656;
	text-align:center;

	&>span{
		color:#a49a21;
	}

	@media(max-width: 960px){
		padding:60px 0;
		font-size: 33px;
	}

	@media(max-width:480px){
		&>span{
			display:block;
		}
	}
`

const CourseTable = styled.div`
	display: flex;
    max-width: 1190px;
    width: 100%;
	margin: 0 auto;
	
	@media(max-width:640px){
		flex-direction: column;
		border-top:1px solid #595959;
	}
`

const CourseCol = styled.div`
	display: flex;
	flex-direction: column;
	width:33.333%;
	background: #fff;
	text-align: center;
	
	@media(max-width:640px){
		width:100%;
		flex-direction: row;
	}
`

const CourseRow = styled.div`
	padding:23px 0;
	border-right:1px solid #595959;
	border-bottom:1px solid #595959;
	font-size: 18px;
	font-weight: 700;
	color:#595959;
	box-sizing:border-box;

	&:last-child{
		border-bottom:0;
	}

	@media(max-width:640px){
		width: 16%;
		font-size:12px;

		&:first-child{
			background:#f3f3f3;
		}

		&:last-child{
			border-bottom:1px solid #595959;
		}
	}
`

const CourseRowHide = styled(CourseRow)`
	text-indent: -99999px;
`

const CourseRowBdrNone = styled(CourseRow)`
	border-right:0;

	@media(max-width:640px){
		border-right:1px solid #595959;
	}
`

const SixTable = () => {
	return(
		<CourseContainer>
			<div>
				<TeacherTit>카르페멘토링스쿨2기<span>6주 과정</span></TeacherTit>
			</div>
			<CourseTable>
				<CourseCol>
					<CourseRowHide>*</CourseRowHide>
					<CourseRow>1주차</CourseRow>
					<CourseRow>2주차</CourseRow>
					<CourseRow>3주차</CourseRow>
					<CourseRow>4주차</CourseRow>
					<CourseRow>5주차</CourseRow>
					<CourseRow>6주차</CourseRow>
				</CourseCol>
				<CourseCol>
					<CourseRow>서울</CourseRow>
					<CourseRow>7/6(토)</CourseRow>
					<CourseRow>7/13(토)</CourseRow>
					<CourseRow>8/10(토)</CourseRow>
					<CourseRow>8/17(토)</CourseRow>
					<CourseRow>8/31(토)</CourseRow>
					<CourseRow>9/7(토)~8(일)</CourseRow>
				</CourseCol>
				<CourseCol>
					<CourseRowBdrNone>부산</CourseRowBdrNone>
					<CourseRowBdrNone>7/13(토)</CourseRowBdrNone>
					<CourseRowBdrNone>7/27(토)</CourseRowBdrNone>
					<CourseRowBdrNone>8/10(토)</CourseRowBdrNone>
					<CourseRowBdrNone>8/24(토)</CourseRowBdrNone>
					<CourseRowBdrNone>8/31(토)</CourseRowBdrNone>
					<CourseRowBdrNone>9/7(토)~8(일)</CourseRowBdrNone>
				</CourseCol>
			</CourseTable>
		</CourseContainer>
	)
}

export default SixTable;