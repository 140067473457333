import moment from "moment";

export default function dateRange(sdate, edate) {
  const result = moment(edate).diff(moment(sdate), "month") + 1;
  let array = [];
  const year = Math.floor(result / 12);
  if (year !== 0) array.push(Math.floor(result / 12) + "년");
  const month = result % 12;
  if (month !== 0) array.push((result % 12) + "개월");
  return array.join(" ");
}
