import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import PageviewIcon from "@material-ui/icons/Pageview";
import CertModal from "./CertModal";

export default function CertSlider() {
  const certData = [
    {
      title: "STC어드밴스코스전문가",
      cert: "2",
    },
    {
      title: "STC베이직코스전문가",
      cert: "11",
    },
    {
      title: "STC프로페셔널코스전문가",
      cert: "17",
    },
    {
      title: "STC보강운동전문가",
      cert: "21",
    },
    {
      title: "동적근력파워전문가",
      cert: "23",
    },
    {
      title: "서스펜션트레이닝전문가",
      cert: "24",
    },
    {
      title: "모빌리티컨디셔닝전문가",
      cert: "26",
    },
    {
      title: "CES운동전문가",
      cert: "39",
    },
    {
      title: "퍼스널트레이닝전문가",
      cert: "42",
    },
    {
      title: "예방운동관리사",
      cert: "00",
    },
  ];

  return (
    <div className="max-w-[1358px] mx-auto mt-16">
      <div className="text-center">
        <p className="text-[21px] opacity-70">다 같은 자격증이 아닙니다.</p>
        <p className="text-[35px] text-[#2576b2] pt-[8px] sm:text-[40px] md:text-[50px]">
          카르페 민간자격증 등록
        </p>
        <div className="max-w-[600px] mx-auto mt-5 mb-10 text-base opacity-80 px-3 break-keep">
          <p>
            KARFE 에서 발급하는 자격증은 민간자격 등록제도에 등록되어 있습니다.
            이는 민간자격관리자가 민간자격을 신설하여 관리·운영하는 경우
            등록관리기관에 등록하는 것으로서, 등록대장에 자격의 종목명 및 등급,
            자격의 관리운영기관에 관한 사항, 등록의 신청일 및 등록결정일 등을
            기재하는 일련의 행정절차를 포함합니다. 또한 1년 이상 3회 이상
            검정실적(자격발급실적)을 제출, 민간자격 등록관리 기관에 등록한 자격
            중 우수한 자격을 자격정책심의회의 심의를 거쳐 공인 됩니다.
          </p>
          <p className="mt-2">
            KARFE의 교육을 통해 이런 신뢰성이 인정된 공인된 자격증을 발급받을 수
            있고 해당 분야에서 높은 수준의 전문성을 보장받을 수 있습니다.
          </p>
        </div>
      </div>
      <Slider
        infinite={true}
        speed={500}
        slidesToShow={5}
        slidesToScroll={1}
        centerMode={true}
        centerPadding={"60px"}
        className={`w-[calc(100%-70px)] mx-auto
        [&_.slick-slide>div>div]:transition-all
        [&_.slick-slide:not(.slick-current)>div>div]:scale-95
        [&_.slick-slide:not(.slick-current)>div>div]:opacity-50
        [&_.slick-arrow:before]:text-blue-500`}
        arrows={true}
        responsive={[
          {
            breakpoint: 1000,
            settings: {
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
            },
          },
        ]}
      >
        {certData.map((cert, index) => (
          <div
            key={index}
            className="text-center px-5 py-3 border border-zinc-600"
          >
            <div className="w-full relative">
              <img
                src={require(`./cert/${cert.cert}.png`)}
                alt=""
                className="object-contain w-full h-full"
              />
              <CertModal img={cert.cert}>
                <button className="absolute right-[10%] bottom-[5%] text-4xl opacity-50 hover:opacity-100">
                  <PageviewIcon fontSize="inherit" />
                </button>
              </CertModal>
            </div>
            <p className="opacity-60 text-sm">{cert.title}</p>
          </div>
        ))}
      </Slider>
    </div>
  );
}
