import React, { Component } from "react";

import KarfeBanner from "./Karfe_banner";
import KarfeOrganization from "./Karfe_organization";
import KarfeWhat from "./Karfe_what";
import President from "./img/president.png";

class index extends Component {
  render() {
    return (
      <div>
        <KarfeBanner />
        <div className="max-w-[900px] mx-auto text-[calc(1rem+1.5vw)] [&>p>span]:font-black [&>p>span]:text-[calc(1.5rem+2vw)] my-10 px-3 text-center lg:text-left break-keep">
          <p>
            <span>카르페</span>는
          </p>
          <p>
            <span>“(사)대한기능재활운동협회”</span> 입니다.
          </p>
          <p className="text-lg mt-14">
            최근 모든 미디어나 일방인들 사이에서는 근육이 많고 지방이 적은 소위
            ‘몸짱’이라고 불리는 몸의 형태를 지향합니다. 하지만 겉보기에 좋은
            몸이 반드시 좋은 기능을 가지는 것은 아닙니다. 오히려 보기에 좋은
            몸만을 위한 운동방법이 관절이나 여러 움직임에 좋지 않은 결과로
            이어지기도 합니다.
            <br></br>
            <br></br>
            카르페는 보기에 좋은 몸이 아닌 바른 몸에서 좋은 기능이 나온다는
            철학을 가지고 기능과 재활에 초점을 두고 설립된 공식 사단법인
            단체입니다. 한국의 많은 현장의 휘트니스 리더들에게 좋은 교육을
            제공하기 위함과 그들의 권익 및 좋은 정보를 제공하고자
            설립되었습니다.
          </p>
        </div>
        <img src={President} alt="" className="object-cover min-h-[300px]" />
        {/* <KarfeWhat/> */}
        <KarfeOrganization />
      </div>
    );
  }
}

export default index;
