import React, { Component } from 'react';

import animateScrollTo from 'animated-scroll-to';

import DnsHeader from './Dns_header';
import DnsBanner from './Dns_banner';
import Intro from './Dns_intro';
import Info from './Dns_info';
import Concept from './Dns_concept';
import Curriculum from './Dns_curriculum';
import Record from './Dns_record';
import Process from './Dns_process';
import Certificates from './Dns_certificates';
import OutLook from './Dns_outlook';
import Footer from './Dns_footer';

class index extends Component {
	state = {
		intro: null,
		characteristic: null,
		curriculum: null,
		record: null,
		process: null,
		outlook: null,
	}

	componentWillMount() {
		document.title = 'STC KOREA';
	}

	chkMov = ()=>{
		const typeChk = this.props.location.search;

		if (typeChk === '?intro') {
			animateScrollTo(this.state.intro.offsetTop);
		}

		if (typeChk === '?characteristic') {
			animateScrollTo(this.state.characteristic.offsetTop);
		}

		if (typeChk === '?curriculum') {
			animateScrollTo(this.state.curriculum.offsetTop);
		}

		if (typeChk === '?process') {
			animateScrollTo(this.state.process.offsetTop);
		}

		if (typeChk === '?certificates') {
			animateScrollTo(this.state.certificates.offsetTop);
		}

		if (typeChk === '?outlook') {
			animateScrollTo(this.state.outlook.offsetTop);
		}
	}

	getOffset = (type ,getNode) => {
		if(type === 'intro'){
			this.setState({
				intro: getNode,
			});
		} else if (type === 'characteristic'){
			console.log(getNode)
			this.setState({
				characteristic: getNode,
			});
		} else if (type === 'curriculum') {
			this.setState({
				curriculum: getNode,
			});
		} else if(type === 'process'){
			this.setState({
				process: getNode,
			});
		} else if (type === 'certificates'){
			this.setState({
				certificates: getNode,
			});
		} else if (type === 'outlook'){
			this.setState({
				outlook: getNode,
			});
		}
	}


	render() {
		return (
			<div>
				{this.state.intro ? this.chkMov() : ''}
				<DnsHeader onHandleClick={this.onHandleClick}/>
				<DnsBanner/>
				<Intro getOffset={this.getOffset}/>
				<Concept/>
				<Curriculum getOffset={this.getOffset}/>
				<Record getOffset={this.getOffset}/>
				<Process getOffset={this.getOffset}/>
				<Certificates getOffset={this.getOffset}/>
				<OutLook getOffset={this.getOffset}/>
				<Footer/>
			</div>
		);
	}
}

export default index;