import React from 'react';

import BannerBg from './img/cft_bg1.jpg'

import styled from 'styled-components';

const BannerBox = styled.div`
	display: flex;
    justify-content: center;
	position:relative;
	min-height: 216px;
	padding: 20px 0;
	background:url(${BannerBg}) center center no-repeat;
	background-size: cover;
`

const BannerInner = styled.div`
	display: flex;
    justify-content: center;
	align-items: center;
	max-width: 1060px;
	color:#fff;
	box-sizing: border-box;
    padding: 0 20px;
`

const LftTxt = styled.p`
	font-size: 60px;
`

const RghtTxt = styled.p`
	font-size: 20px;
    padding-left: 50px;
	line-height: 27px;
	
	@media(max-width:520px){
		font-size:16px;
		line-height:140%;
	}
`

const CftBanner = () => {
	return (
		<BannerBox>
			<BannerInner>
				<LftTxt>
					CFT
				</LftTxt>
				<RghtTxt>
					펑셔널 트레이닝 전문가과정 (CFT)은 단순 근육량의 증가나 근고립운동을 통한 근력의 증가나 근고립운동을 통한 근력의 증가가 인체의 기능적인 움직임의 향상으로 이어지지 않는다는 이론적 배경을 토대로 인체의 기능적 움직임의 특성과 그 기초이론과 실기를 배울 수 있는 교육과정입니다. 
				</RghtTxt>
			</BannerInner>
		</BannerBox>
	)
}

export default CftBanner;