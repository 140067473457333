import React from "react";

import Contents01 from "./img/cft_contents_01.jpg";
import MoContents01 from "./img/m_cft_contents_01.jpg";

import CftBook from "./img/cft_book.jpg";

import TimeThums from "./img/Time_ico.jpg";
import TeacherThums from "./img/Teacher_ico.jpg";
import EduThums from "./img/Edu_ico.jpg";
import PeopleThums from "./img/Peoples_ico.jpg";
import ComputerThums from "./img/Computer_ico.jpg";

import Edu1 from "./img/edu_1.png";
import Edu2 from "./img/edu_2.png";
import Edu3 from "./img/edu_3.png";
import Edu4 from "./img/edu_4.png";

import Teacher1 from "./img/teacher_1_1.jpg";

import Teacher1Txt from "./img/teacher_1_txt.jpg";

import styled from "styled-components";

const ContentsBox = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 1350px;
  margin: 0 auto;
  padding-top: 120px;

  @media (max-width: 1100px) {
    flex-direction: column;
    align-items: center;
    width: 90%;
    padding-top: 60px;
  }
`;

const MoContentsBox = styled(ContentsBox)`
  @media (max-width: 1100px) {
    display: none;
  }
`;

const ContentsLftCon = styled.div`
  width: 37%;

  @media (max-width: 1100px) {
    width: 100%;
  }
`;

const ContentsRghtCon = styled.div`
  flex: 1 0 auto;
  padding-top: 70px;
  max-width: 781px;

  @media (max-width: 1100px) {
    padding-top: 0;
  }
`;

const ContentsLayout = styled.div`
  position: relative;
  padding-left: 90px;
  padding-top: 70px;
  box-sizing: border-box;

  @media (max-width: 1100px) {
    padding-left: 0;
    text-align: center;
  }
`;

const ContentsTit = styled.h3`
  padding-bottom: 40px;
  font-size: 48px;
  font-weight: 700;
  color: #565656;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background: #7ad3f3;
    display: block;
    width: 249px;
    height: 29px;

    @media (max-width: 1100px) {
      left: 50%;
      transform: translate(-50%, 0);
    }
  }
`;

const ContentsMenu = styled.ul`
  font-size: 18px;

  & > li {
    padding-bottom: 10px;
    line-height: 140%;
    letter-spacing: -2px;

    & > br {
      @media (max-width: 1100px) {
        display: none;
      }
    }
  }
`;

const ContentsRghtMenu = styled(ContentsMenu)`
  & > li {
    position: relative;
    padding-left: 60px;
    line-height: 140%;

    @media (max-width: 1100px) {
      padding-left: 0;
      padding-bottom: 20px;
      text-align: center;

      &:after {
        display: none;
      }
    }

    &:after {
      content: "";
      position: absolute;
      top: 4px;
      left: 0;
      width: 18px;
      height: 18px;
      background: #7ad3f3;
      border-radius: 50%;
    }
  }
`;

const ContentsRghtDiffrent = styled(ContentsRghtMenu)`
  & > li {
    display: flex;
    padding-left: 0;
    padding-bottom: 40px;
		justify-content: center;
		align-items: center;
		gap: 2rem;

    &:after {
      display: none;
    }

    @media (max-width: 1100px) {
      flex-direction: column;
      padding-bottom: 40px;
      text-align: center;
    }
  }
  & > li > span:first-child {
    width: 85px;
  }
`;

const ContentsRghtSpan = styled.span`
  flex: 1 1 auto;
	width: 100%;

  @media (max-width: 1100px) {
    padding-top: 10px;
  }
`;

const ContentsImg = styled.img`
  display: block;
  width: 100%;

  @media (max-width: 1100px) {
    display: none;
    margin: 0 auto;
  }
`;

const MoContentsImg = styled.img`
  display: none;
  width: 100%;

  @media (max-width: 1100px) {
    display: block;
    margin: 0 auto;
  }

  @media (max-width: 790px) {
    max-width: 450px;
  }
`;

const ContentsTeacherTit = styled.h2`
  font-size: 28px;
  padding-bottom: 22px;
  color: #565656;

  @media (max-width: 1100px) {
    text-align: center;
  }
`;

const ContentsTeacherTxt = styled.p`
  font-size: 20px;
  line-height: 140%;

  @media (max-width: 1100px) {
    text-align: center;
  }
`;

const TeacherUl = styled.ul`
  display: block;
  width: 100%;
`;

const TeacherLi = styled.li`
  display: inline-block;
  width: 49%;
  padding-bottom: 10px;
  margin: 0 0.5%;

  & > img {
    display: block;
    width: 100%;
  }

  @media (max-width: 960px) {
    width: 100%;
  }
`;

const EduPeople = styled.ul`
  display: flex;
  flex-wrap: wrap;
  text-align: center;
`;

const EduPeopleList = styled.li`
  width: 24%;
  margin: 0 0.5%;
  color: #565656;

  @media (max-width: 920px) {
    width: 48%;
    margin: 0 1%;
    padding-bottom: 20px;
  }

  & > img {
    display: block;
    width: 100%;
  }

  & > p {
    padding-top: 25px;
    font-size: 20px;
    line-height: 120%;
  }
`;

const EduPeopleTxt = styled.p`
  text-align: center;
  text-decoration: underline;
  color: red;
  font-style: italic;
  margin-top: 36px;
`;

const ContentsExplainBox = styled.div`
  display: flex;
  align-items: flex-start;
`;

const ContentsExplainLft = styled.p``;

const ContentsExplainRght = styled.p`
  padding-left: 80px;
  padding-top: 30px;
`;

const ContentsExplainToptit = styled.p`
  font-size: 24px;
  padding-bottom: 29px;
  font-weight: 700;
  letter-spacing: -0.8px;
  color: #555;

  & > span {
    font-size: 18px;
    position: relative;
    top: -2px;
    padding-left: 5px;
  }
`;

const ContentsExplainBtmtxt = styled.p`
  line-height: 180%;
  letter-spacing: -2px;
  color: #555;
`;

const TeacherItems = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 720px) {
    flex-direction: column;
  }
`;

const TeacherProfilThums = styled.span`
  width: 50%;

  @media (max-width: 1200px) {
    width: auto;
  }

  @media (max-width: 720px) {
    width: 80%;
  }
`;

const TeacherTxtBox = styled.span`
  flex: 1 1 auto;
  padding-left: 36px;
  color: #565656;

  @media (max-width: 960px) {
    padding-left: 15px;
  }

  @media (max-width: 720px) {
    width: 80%;
    padding: 15px;
    border: 1px solid #999;
    border-top: 0;
    box-sizing: border-box;
  }
`;

const TeacherThumsTopTxt = styled.span`
  display: block;
  width: 210px;
  text-align: center;
`;

const TeacherTxtTop = styled.span`
  display: block;
  font-size: 20px;
  text-decoration: underline;
`;

const TeacherProfilImg = styled.img`
  display: block;
  width: 100%;
`;

const TeacherNames = styled.span`
  display: block;
  font-size: 44px;
  font-weight: 900;
  padding: 14px 0 24px 0;
`;

const TeacherRecordList = styled.li`
  padding-bottom: 7px;
`;

const PesContents = () => {
  return (
    <>
      <ContentsBox>
        <ContentsLftCon>
          <ContentsLayout>
            <ContentsTit>교육내용</ContentsTit>
            <ContentsMenu>
              <li>펑셔널 트레이닝의 개요</li>
              <li>스포츠 부상예방의 역학</li>
              <li>동적 신경근 안정화 (DNS)</li>
              <li>임상적 테스트 절차와 핵심 고리</li>
              <li>잘못된 움직임 패턴들의 기능적 평가</li>
              <li>운동체계 훈련의 기본 원리들</li>
            </ContentsMenu>
          </ContentsLayout>
        </ContentsLftCon>
        <ContentsRghtCon>
          <ContentsImg src={Contents01} alt="이미지" />
          <MoContentsImg
            src={MoContents01}
            style={{ paddingTop: "60px" }}
            alt="이미지"
          />
        </ContentsRghtCon>
      </ContentsBox>

      <ContentsBox>
        <ContentsLftCon>
          <ContentsLayout>
            <ContentsTit>교육효과</ContentsTit>
          </ContentsLayout>
        </ContentsLftCon>
        <ContentsRghtCon>
          <ContentsRghtMenu>
            <li>
              엘리트 스포츠 선수들을 위한 기능강화 트레이닝 방법론을 학습할 수
              있습니다.
            </li>
            <li>
              펑셔널 트레이닝 ( Functional Training)의 기초와 개념을 익힐 수
              있으며, 실제 현장에서 적용할 수 있습니다.
            </li>
            <li>
              다양한 기능적 움직임의 평가방법을 학습하여 기능적 움직임 향상을
              위한 트레이닝을 현장에서 적용할 수 있습니다.
            </li>
          </ContentsRghtMenu>
        </ContentsRghtCon>
      </ContentsBox>

      <ContentsBox>
        <ContentsLftCon>
          <ContentsLayout>
            <ContentsTit>차별성</ContentsTit>
          </ContentsLayout>
        </ContentsLftCon>
        <ContentsRghtCon>
          <ContentsRghtDiffrent>
            <li>
              <span>
                <img src={TimeThums} alt="이미지" />
              </span>
              <ContentsRghtSpan>
                매주 일요일 교육으로 구성되어 있어 토요근무에 따른 불편함이
                없으며, 하루 7시간씩 6주에 걸쳐 진행되므로 강의 시간이 너무 길어
                지루함과 하루 교육내용이 많아 따라가지 못하는 부분이 없도록
                집중도 높게 교육이 이루어질 수 있도록 하였습니다.
              </ContentsRghtSpan>
            </li>
            <li>
              <span>
                <img src={TeacherThums} alt="이미지" />
              </span>
              <ContentsRghtSpan>
                국내 최초의 펑셔널 트레이닝 전문 단체인
                (사)한국재활기능운동협회의 강사들로 구성되어 있으며, 현직대학
                교수는 물론 물리치료사 및 펑셔널 트레이닝 전문가들로 구성되어
                있습니다.
              </ContentsRghtSpan>
            </li>
            <li>
              <span>
                <img src={EduThums} alt="이미지" />
              </span>
              <ContentsRghtSpan>
                기초 펑셔널 트레이닝 이론교육과 세계에서 유행하는 펑셔널
                트레이닝 툴들을 통한 실기 교육들을 소개하고 오늘 배운 내용들을
                내일 바로 적용시킬 수 있는 교육방식으로 구성되어 있습니다.
              </ContentsRghtSpan>
            </li>
            <li>
              <span>
                <img src={PeopleThums} alt="이미지" />
              </span>
              <ContentsRghtSpan>
                2016년부터 20명이라는 교육정원제를 적용하여 실기수업 및
                이론수업의 집중도와 교육의 질을 한층 더 높였습니다.
              </ContentsRghtSpan>
            </li>
            <li>
              <span>
                <img src={ComputerThums} alt="이미지" />
              </span>
              <ContentsRghtSpan>
                기초 운동학 및 해부학이 부족한 교육생들을 위해 ‘스마트핏’ 인터넷
                강의 시스템과 연동으로 본 교육과정과 함께 기초해부학 및 기능
                해부학 교육을 따로 영상으로 공부할 수 있도록 서비스하고
                있습니다.
                <br />
                (단, 패키지 등록자에 한해 서비스되고 있습니다.)
              </ContentsRghtSpan>
            </li>
          </ContentsRghtDiffrent>
        </ContentsRghtCon>
      </ContentsBox>

      <ContentsBox>
        <ContentsLftCon>
          <ContentsLayout>
            <ContentsTit>교육대상</ContentsTit>
          </ContentsLayout>
        </ContentsLftCon>
        <ContentsRghtCon>
          <EduPeople>
            <EduPeopleList>
              <img src={Edu1} alt="pt트레이너" />
              <p>PT트레이너</p>
            </EduPeopleList>
            <EduPeopleList>
              <img src={Edu2} alt="헬스트레이너" />
              <p>헬스트레이너</p>
            </EduPeopleList>
            <EduPeopleList>
              <img src={Edu3} alt="물리치료사" />
              <p>물리치료사</p>
            </EduPeopleList>
            <EduPeopleList>
              <img
                src={Edu4}
                alt="운동에 관심이 많은 일반인 및 체육관련 대학생"
              />
              <p>운동에 관심이 많은 일반인 및 체육관련 대학생</p>
            </EduPeopleList>
          </EduPeople>
          <EduPeopleTxt>
            * 문의 : 박일봉 박사 (010-4433-4106)에게 연락주시기 바랍니다.
          </EduPeopleTxt>
        </ContentsRghtCon>
      </ContentsBox>

      <MoContentsBox>
        <ContentsLftCon>
          <ContentsLayout>
            <ContentsTit>교재안내</ContentsTit>
          </ContentsLayout>
        </ContentsLftCon>
        <ContentsRghtCon>
          <ContentsExplainBox>
            <ContentsImg
              style={{ maxWidth: "182px" }}
              src={CftBook}
              alt="이미지"
            />
            <ContentsExplainRght>
              <ContentsExplainToptit>
                운동수행능력 향상 트레이닝<span>[한미의학 2015년]</span>
              </ContentsExplainToptit>
              <ContentsExplainBtmtxt>
                본 서적은 현존하는 기능훈련 분야에 있어 이론과 실기를 잘 활용할
                수 있도록 구성되어 있습니다. 이론적인 토대 위에서 다양한
                분야에서 기능훈련을 적용할 수 있도록 수많은 전문가들이 참여하여
                이론과 실기를 기술하고 있습니다.
                <br />본 서적에서는 Gray Cook의 FMS, Y-Balance test등과 같은
                전문평가 파트에서부터 각 스포츠 종목들의 시즌기에 따른 트레이닝
                소개는 물론, 전문재활기술까지 소개되어 있어 본 서적의
                제목처럼'재활기능운동'을 제대로 학습할 수 있는 도서입니다.
              </ContentsExplainBtmtxt>
            </ContentsExplainRght>
          </ContentsExplainBox>
        </ContentsRghtCon>
      </MoContentsBox>

      <ContentsBox>
        <ContentsLftCon>
          <ContentsLayout>
            <ContentsTit>강사소개</ContentsTit>
          </ContentsLayout>
        </ContentsLftCon>
        <ContentsRghtCon>
          <ContentsTeacherTit>퍼스널 트레이닝 전문가 (CFT)</ContentsTeacherTit>
          <ContentsTeacherTxt>
            퍼스널 트레이닝 전문가 과정을 가장 빠르게 확실히 학습하는 방법!
            카르페에서 찾으세요. 카르페 강사진 모두 NASM CPT, CES, PES의
            자격증을 획득함은 물론, 물리치료사 및 현직대학 교수들로 구성되어
            있습니다.
          </ContentsTeacherTxt>
        </ContentsRghtCon>
      </ContentsBox>
      <ContentsBox>
        <TeacherUl>
          <TeacherLi>
            <TeacherItems>
              <TeacherProfilThums>
                <TeacherProfilImg src={Teacher1} alt="박일봉 박사" />
              </TeacherProfilThums>
              <TeacherTxtBox>
                <TeacherThumsTopTxt>
                  <span>
                    <img src={Teacher1Txt} alt="재활 교정운동의 대가" />
                  </span>
                  <TeacherTxtTop>퍼스널트레이너들의 멘토</TeacherTxtTop>
                  <TeacherNames>박일봉 박사</TeacherNames>
                </TeacherThumsTopTxt>
                <ul>
                  <TeacherRecordList>현)카르페협회장</TeacherRecordList>
                  <TeacherRecordList>
                    현)(주)스마트핏대표,(주)스마트핏 운동센터 대표
                  </TeacherRecordList>
                  <TeacherRecordList>
                    현)부산 외국어대학교 사회체육학부 외래교수
                  </TeacherRecordList>
                </ul>
              </TeacherTxtBox>
            </TeacherItems>
          </TeacherLi>
        </TeacherUl>
      </ContentsBox>
    </>
  );
};

export default PesContents;
