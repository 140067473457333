import React, { Component } from 'react';

import CesVideo from './Ces_video';
import CesBanner from './Ces_banner';
import CesContents from './Ces_Content';

class Academy_onCes extends Component {
	state = {
		ClientY: 0,
	}

	componentDidMount() {
		this.setState({ClientY: this.BoxTop.offsetTop});
	}

	render() {
		return (
			<div
				ref={ref => {
					this.BoxTop = ref;
				}}
			>
				{this.state.ClientY ? this.props.getCptOffset(this.BoxTop.offsetTop) : ''}
				<CesVideo />
				<CesBanner />
				<CesContents />
			</div>
		);
	}
}

export default Academy_onCes;