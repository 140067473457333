import React, {Component} from 'react';

import CesVideoThums from './img/ces1.jpg';

import CesModal from './CesModal';

import styled from 'styled-components';

const VideoWarrper = styled.div`
	max-width:1350px;
	margin:0 auto;
	text-align:center;
	padding:100px 0 30px 0;
	color:#565656;

	@media(max-width:960px){
		padding:60px 0 30px 0;
	}
`

const VideoTit = styled.h2`
	padding-bottom:20px;
	font-size: 50px;
	font-weight:700;

	@media(max-width:1100px){
		font-size:33px;
	}
`

const VideoTxt = styled.p`
	font-size: 30px;

	@media(max-width:1100px){
		font-size:20px;
	}
`

const VideoThums = styled.img`
	display: block;
	max-width: 813px;
    width: 100%;
	margin: 47px auto;
	cursor:pointer;
`

class CesVideo extends Component {
	state = {
		isReply: false,
		isEduSystem: false,
	}

	OpenReplayModal = () => {
		this.setState({isReply: true});
	}

	CloseReplayModal = () => {
		this.setState({isReply: false});
	}
	render() {
		return (
			<VideoWarrper>
				<VideoTit>재활교정운동 전문가과정</VideoTit>
				<VideoTxt>Corrective Exercise Specialist (CES)</VideoTxt>
				<VideoThums src={CesVideoThums} onClick={this.OpenReplayModal} />
				{
					this.state.isReply
						?
						<CesModal isReply={this.state.isReply} onCloseModal={this.CloseReplayModal} />
						:
						''
				}
			</VideoWarrper>
		);
	}
}

export default CesVideo;