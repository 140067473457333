import React, {Component} from 'react';

import styled from 'styled-components';

import ConceptImg from './img/concept_bg.jpg';
import MoConceptImg from './img/m_concept_bg.jpg';

const ConceptWrap = styled.div`
	position:relative;
`

const ConceptThums = styled.img`
	display:block;
	width:100%;

	@media(max-width:960px){
		display:none;
	}
`

const MoConceptThums = styled.img`
	display:none;

	@media(max-width:960px){
		display:block;
		width:100%;
	}
`

const ConceptTxtBox = styled.div`
	position:absolute;
	top:50%;
	right:10%;
	color:#fff;
	transform:translate(0,-50%);

	@media(max-width:960px){
		width: 422px;
	}

	@media(max-width:640px){
		width:51%;
	}
`

const ConceptTit = styled.p`
	font-size:42px;
	font-weight:700;
	padding-bottom:32px;

	@media(max-width:960px){
		font-size:36px;
	}

	@media(max-width:640px){
		font-size:26px;
	}
`

const ConceptTxt = styled.p`
	font-size:28px;
	font-weight:300;
	line-height:140%;

	@media(max-width:960px){
		font-size:22px;
	}

	@media(max-width:640px){
		font-size:12px;
	}
`

class Concept extends Component {
	componentDidMount() {
		this.props.getOffset('concept', this.concept);
	}

	render(){
		return(
			<ConceptWrap 
				id="concept"
				ref={ref => {
					this.concept = ref;
				}}
			>
				<ConceptTxtBox>
					<ConceptTit>프랜스 보쉬의 컨셉을 배우다!</ConceptTit>
					<ConceptTxt>기능적 움직임,협응성 트레이닝의 대가 프랜스 보쉬의 컨셉을 배우다!<br />운동조절 이론에 기초한 운동역학 및 운동학적 움직임 조절 과정!</ConceptTxt>
				</ConceptTxtBox>
				<ConceptThums src={ConceptImg} />
				<MoConceptThums src={MoConceptImg} />
			</ConceptWrap>
		)
	}
}

export default Concept;