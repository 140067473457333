import React from 'react';

import FtLogo from './img/logo_bottom.jpg'

import styled from 'styled-components';

const StcFooterBox = styled.div`
	display:block;
	padding:70px 0;
	background:#252424;
`

const StcFooterInner = styled.div`
	display:flex;
	max-width: 1350px;
	margin:0 auto;
	justify-content: center;
	align-items: center;
	color:#fff;

	@media(max-width:640px){
		flex-direction: column;
	}
`

const StcLogoBox = styled.div`
	padding-right:15px;
`

const StcAddressBox = styled.div`
	line-height: 140%;
`

const Footer = () => {
	return(
		<StcFooterBox>
			<StcFooterInner> 
				<StcLogoBox>
					<img src={FtLogo} alt="STC LOGO"/>
				</StcLogoBox>
				<StcAddressBox>
					STCKOREA 대표자 : 박일봉 | 개인정보 책임자 : 노근역<br/>
					부산 해운대구 수영강변대로 140 부산문화콘텐츠콤플렉스 712호<br />
					사업자등록번호 : 621-86-10575<br />
					대표전화 : 051-583-9645 | FAX : 051-518-9643<br />
				</StcAddressBox>
			</StcFooterInner>
		</StcFooterBox>
	)
}

export default Footer;