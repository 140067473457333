import React, { Component } from 'react';

import BufsMain from './BufsMain';

class index extends Component {
    render() {
        return(
            <div>
                <BufsMain />
            </div>
        )
    }
}
export default index;