import React, { useEffect, useState } from "react";
import axios from "axios";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function EduVideo() {
  const [videos, setVideos] = useState();
  const [selectVideo, setSelectVideo] = useState("");
  const [mobileVideoOpen, setMobileVideoOpen] = useState(false);

  useEffect(() => {
    const getVideos = async () => {
      const result = await axios.post(
        "https://api.teamstc.org:3003/api/user/eduvideos"
      );
      console.log(result);
      if (!result?.data?.success) return;
      const data = {
        KARFE: { videos: [], youtube: "HgTfzVD6Als" },
        ...result.data.result,
      };
      setVideos(data);
      setSelectVideo(data.KARFE.youtube);
    };
    getVideos();
  }, []);

  const openVideo = (id) => {
    setSelectVideo(id);
    setMobileVideoOpen(true);
  };

  return (
    <>
      <p className="text-center text-[#565656] text-4xl my-10">교육 소개영상</p>
      {videos && (
        <div className="flex flex-col md:flex-row gap-2 max-w-[1354px] mx-auto items-start">
          <div className="flex-[0.4] px-3">
            {Object.keys(videos).map((category, vi) => (
              <div key={vi} className="my-1 border rounded">
                <button
                  className="w-full py-2 px-4 text-lg text-left flex justify-between items-center"
                  onClick={() =>
                    category === "KARFE"
                      ? openVideo(videos[category].youtube)
                      : setVideos((state) => {
                          const copy = state[category];
                          copy.open = !copy.open;
                          return { ...state, [category]: copy };
                        })
                  }
                >
                  {category}
                  <img
                    src={require("./img/next.svg")}
                    alt=""
                    className={`w-3 h-3 opacity-50 transition-transform ${
                      videos[category].open ? "rotate-90" : ""
                    }`}
                  />
                </button>
                <div
                  className={`${
                    videos[category].open
                      ? " grid-rows-[1fr]"
                      : " grid-rows-[0fr]"
                  } transition-[grid-template-rows] duration-600 grid row-span-2`}
                >
                  <div className="overflow-hidden">
                    {videos[category].videos.map((video, index) => (
                      <button
                        className="flex w-full items-center gap-4 p-3 text-left first:border-t even:bg-zinc-200 my-1"
                        key={index}
                        onClick={() => openVideo(video.youtubeid)}
                      >
                        <img
                          src={"https://api.teamstc.org:3003/" + video.badge}
                          alt=""
                          className="w-[4.5rem] h-[4.5rem] object-contain"
                          onError={(e) => {
                            e.target.src = "/svg/emptybadge.svg";
                            e.target.classList.add("opacity-50");
                          }}
                        />
                        <p>{video.title}</p>
                      </button>
                    ))}
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div
            className={`flex-1 fixed top-0 left-0 z-10 md:relative w-full h-full md:h-auto flex justify-center items-center p-3 md:p-0 bg-black bg-opacity-50 ${
              mobileVideoOpen ? "" : "hidden md:block"
            }`}
          >
            <div className="w-full pb-[56.4%] max-w-full relative">
              <iframe
                src={`https://www.youtube.com/embed/${selectVideo}`}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
                className="w-full h-full absolute"
              />
            </div>
            {mobileVideoOpen && (
              <button
                className="absolute bottom-10 w-10 h-10 rounded-full text-white bg-blue-500 p-3 md:hidden"
                onClick={() => setMobileVideoOpen(false)}
              >
                X
              </button>
            )}
          </div>
        </div>
      )}
    </>
  );
}
