import React, { Component } from 'react';

import animateScrollTo from 'animated-scroll-to';

import styled from 'styled-components';

import CfscHeader from './Cfsc_header';
import CfscBanner from './Cfsc_banner';
import Intro from './Cfsc_intro';
import Info from './Cfsc_info';
import Concept from './Cfsc_concept';
import Curriculum from './Cfsc_curriculum';
import Record from './Cfsc_record';
import Process from './Cfsc_process';
import Certificates from './Cfsc_certificates';
import OutLook from './Cfsc_outlook';
import Footer from './Cfsc_footer';

const TopMoveBtn = styled.span`
	position: fixed;
    bottom: 10%;
    right: 5%;
    display: block;
    padding: 25px 20px;
    background: rgba(0,0,0,0.8);
    color: #fff;
	border-radius: 10px;
	cursor:pointer;

	&:hover{
		background: rgba(255,33,33,.8);
	}
`

class index extends Component {
	state = {
		intro: null,
		course: null,
		teacher:null,
		record: null,
		process:null,
		Enrollment:null,
		scrollOffset: false,
	}

	componentWillMount() {
		document.title = 'Cfsc KOREA';
	}

	topMov = () => {
		animateScrollTo(0);
	}

	chkMov = ()=>{
		const typeChk = this.props.location.search;

		if (typeChk === '?intro') {
			animateScrollTo(this.state.intro.offsetTop);
		}
		if (typeChk === '?course') {
			animateScrollTo(this.state.course.offsetTop);
		}

		if(typeChk === '?teacher'){
			animateScrollTo(this.state.teacher.offsetTop);
		}

		if(typeChk === '?record'){
			animateScrollTo(this.state.record.offsetTop);
		}

		if (typeChk === '?process'){
			animateScrollTo(this.state.process.offsetTop);
		}

		if (typeChk === '?enrollment'){
			animateScrollTo(this.state.enrollment.offsetTop);
		}
	}

	getOffset = (type ,getNode) => {

		if(type === 'intro'){
			this.setState({
				intro: getNode,
			});
		}else if(type === 'course'){
			this.setState({
				course: getNode,
			});
		} else if (type === 'teacher'){
			this.setState({
				teacher: getNode,
			});
		} else if(type === 'record'){
			this.setState({
				record: getNode,
			});
		} else if (type === 'process'){
			this.setState({
				process: getNode,
			});
		} else if (type === 'enrollment'){
			this.setState({
				enrollment: getNode,
			});
		}
	}


	render() {
		return (
			<div onWheel={(e)=>{
				if (window.scrollY > 300){
					this.setState({
						scrollOffset: true,
					});
				}else{
					this.setState({
						scrollOffset: false,
					});
				}
			}}>
				{this.state.intro ? this.chkMov() : ''}
				<CfscHeader onHandleClick={this.onHandleClick}/>
				<CfscBanner/>
				<Intro getOffset={this.getOffset}/>
				<Info getOffset={this.getOffset}/>
				<Concept/>
				<Curriculum getOffset={this.getOffset}/>
				<Record getOffset={this.getOffset}/>
				<Process getOffset={this.getOffset}/>
				<Certificates getOffset={this.getOffset}/>
				<OutLook/>
				<Footer/>
				{this.state.scrollOffset ? <TopMoveBtn 
					onClick={
						this.topMov
					}
				>TOP</TopMoveBtn> : ""}
			</div>
		);
	}
}

export default index;