import React from 'react';

import Sixbg from './img/six_bg.png';
import SixProcessImg from './img/SixProcess_thums.png';

import moto1 from './img/mento_ico1.png';
import moto2 from './img/mento_ico2.png';
import moto3 from './img/mento_ico3.png';
import moto4 from './img/mento_ico4.png';
import moto5 from './img/mento_ico5.png';
import moto6 from './img/mento_ico6.png';

import arrow from './img/process_arrow_ico.png';

import styled from 'styled-components';

const SixWeekBox = styled.section`
	padding:130px 0;
	background:url(${Sixbg}) 0 0 no-repeat;
	text-align: center;
    background-size: cover;
`

const SixWeekTit = styled.h2`
	padding:64px 0;
	font-size:64px;
	color:#fff;
	font-weight: 300;

	@media(max-width: 960px){
		font-size: 33px;
	}
`

const SixWeekImg = styled.img`
	display:block;
	width:100%;
	max-width:1126px;
	margin:0 auto;
	padding: 0 20px;
	padding-bottom:120px;
	box-sizing: border-box;

	@media(max-width: 960px){
		padding-bottom:60px;
	}
`

const SixSchoolList = styled.ul`
	display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
	max-width: 797px;
	width: 100%;
	margin: 0 auto;
	
	&>li{
		width: 31%;
		color:#fff;

		&>img{
			display:block;
			width:100%;
		}

		&>p{
			padding:20px 0;
		}
	}

	@media(max-width:640px){
		width:90%;

		&>li{
			width: 49%;
			line-height:140%;

			&>p{
				&>br{
					display:none;
				}
			}
		}

	}
`

const SixProcessBox = styled.ul`
	display: flex;
    justify-content: center;
	color: #fff;
	
	@media(max-width:960px){
		flex-wrap: wrap;
	}

	@media(max-width:480px){
		flex-direction: column;
    	align-items: center;
	}
`

const SixProcessItems = styled.li`
	position:relative;
	width: 190px;
    margin-left: 30px;
    min-height: 264px;
    background: rgba(0,0,0.2);
    display: flex;
    align-items: center;
	justify-content: center;
	font-size: 22px;
	font-weight:700;
    line-height: 130%;
	
	&:first-child{
		margin-left:0;
	}

	&:nth-child(3),&:nth-child(4){
		color:#2d9fd3;
	}

	&:last-child:after{
		display:none;
	}

	&:after{
		content: "";
		position: absolute;
		top: 50%;
		right: -48px;
		width: 53px;
		height: 38px;
		margin-top: -19px;
		background:url(${arrow}) 0 0 no-repeat;
		z-index:1;
	}

	@media(max-width:960px){
		&:after{
			position: absolute;
			top: 0;
			left: 50%;
			width: 30px;
			height: 30px;
			margin-top: -15px;
			margin-left: -15px;
			z-index: 1;
			border: 1px solid #2ba0d2;
			border-radius: 50%;
			background: #2ba0d2;
			color: #fff;
			font-size: 20px;
    line-height: 30px;
			letter-spacing: 0px;
		}

		&:nth-child(1):after{
			content:"1";
		}

		&:nth-child(2):after{
			content:"2";
		}

		&:nth-child(3):after{
			content:"3";
		}

		&:nth-child(4):after{
			content:"4";
		}

		&:nth-child(5):after{
			display:block;
			content:"5";
		}

	}

	@media(max-width:960px){
		margin:2% 2%;
	}
`

const SixWeek = () => {
	return(
		<SixWeekBox>
			<SixWeekTit>6주 멘토링 스쿨 진행 과정</SixWeekTit>
			<div>
				<SixProcessBox>
					<SixProcessItems>
						<span>서류접수 및 면접<br />합격자발표</span>
					</SixProcessItems>
					<SixProcessItems>
						<span>멘토링스쿨 시작</span>
					</SixProcessItems>
					<SixProcessItems>
						<span>1주차<br />박일봉 교장의<br />멘토링스쿨<br />오리엔테이션</span>
					</SixProcessItems>
					<SixProcessItems>
						<span>2~5주차<br/>멘토와멘티간의<br/>멘토링스쿨 미팅<br/>트레이닝 이론,<br/>지도방법,미션수행</span>
					</SixProcessItems>
					<SixProcessItems>
						<span>6주차<br/>9/7~8일<br/>1박2일간<br/>멘토링스쿨<br/>졸업여행</span>
					</SixProcessItems>
				</SixProcessBox>
			</div>
			<SixWeekTit>6주 멘토링 스쿨</SixWeekTit>
			<SixSchoolList>
				<li>
					<img src={moto1} alt="운동능력 / 기초이론 / 트레이닝 지도스킬<br/>
					테스트 결과를 토대로  멘토링을 배정"/>
					<p>운동능력 / 기초이론 / 트레이닝 지도스킬<br/>
					테스트 결과를 토대로  멘토링을 배정</p>
				</li>
				<li>
					<img src={moto2} alt="영상강의로 매주 월요일마다
					그 주의 미션 수행"/>
					<p>영상강의로 매주 월요일마다<br />
					그 주의 미션 수행</p>
				</li>
				<li>
					<img src={moto3} alt="카톡 및 밴드를 통해
					멘티와 교류"/>
					<p>카톡 및 밴드를 통해 <br />
					멘티와 교류</p>
				</li>
				<li>
					<img src={moto4} alt="일주일정도의 분량을 정하여
					개개인에게 첨삭지도"/>
					<p>일주일정도의 분량을 정하여 <br />
					개개인에게 첨삭지도</p>
				</li>
				<li>
					<img src={moto5} alt="멘티들의 성장보고서를
					일주일 단위로 작성"/>
					<p>멘티들의 성장보고서를 <br />
					일주일 단위로 작성</p>
				</li>
				<li>
					<img src={moto6} alt="6주간  멘티들과 만나서
					운동하고 교류"/>
					<p>6주간  멘티들과 만나서<br />
					운동하고 교류</p>
				</li>
			</SixSchoolList>
		</SixWeekBox>
	)
}

export default SixWeek;