import React, {Component} from 'react';

import styled from 'styled-components';

import InfoBg from './img/cfsc_info_bg.jpg';
import MoInfoBg from './img/m_cfsc_info_bg.jpg';

import CfscRecord from './img/cfsc_record_img.png';

const ConceptBox = styled.div`
	position:relative;
	background:url(${InfoBg}) 50% 50% no-repeat;
	height: 1226px;

	@media(max-width:720px){
		background:url(${MoInfoBg}) 50% 50% no-repeat;
		height: 756px;
	}
`


const InfoConTit = styled.h2`
    text-align: center;
    font-size: 63px;
    color: #fff;
	letter-spacing: -5px;
	padding:140px 0 50px 0;
	line-height: 130%;

	&>span{
		position: relative;
		top: 6px;
		left: 6px;
		font-family: 'Impact';
		font-size: 68px;
		color: #fff;
		letter-spacing: 2px;
	}

	&>br{
		display:none;
	}

	@media(max-width:960px){
		font-size: 40px;
		padding-top:60px;
		padding-bottom: 60px;

		&>span{
			font-size: 44px;
		}

		&>br{
		display:block;
	}
	}
`

const RecordBox = styled.span`
	display:block;
	max-width:809px;
	width:100%;
	margin:0 auto;

	@media(max-width:720px){
		max-width:406px;
	}
`

const RecordThums = styled.img`
	display:block;
	width:100%;
`

const ConceptTxt = styled.div`
	position:absolute;
	bottom:0;
	width: 100%;
	height: 212px;
	line-height:120%;
	padding: 44px 0;
	font-size: 46px;
	font-weight:300;
    color: #fff;
    text-align: center;
    display: flex;
    flex-direction: column;
	justify-content: space-evenly;
	
	@media(max-width:820px){
		&>span>br{
			display:none;
		}
	}

	@media(max-width:720px){
		height: 110px;
		padding:24px 0;
		font-size: 26px;

		&>span>br{
			display:block;
		}
	}

	@media(max-width:460px){
		font-size: 16px;

	}
`

const ConceptYelloTxt = styled.span`
	color:#f0ff00;
	font-weight:400;
`

class Concept extends Component {

	render(){
		return(
			<div>
				<ConceptBox>
					<InfoConTit><span>CFSC</span> 인증 과정을<br />취득해야 하는 이유?</InfoConTit>
					<RecordBox>
						<RecordThums src={CfscRecord}/>
					</RecordBox>
					<ConceptTxt>
						<span>
							미국 <ConceptYelloTxt>CFSC본사의 공인 자격증</ConceptYelloTxt>을 바로 취득!<br/>
							CFSC코치 멤버로 등록되어 <ConceptYelloTxt>본사 홈페이지에서 바로 검색</ConceptYelloTxt>이 가능!
						</span>
					</ConceptTxt>
				</ConceptBox>
				
			</div>
		)
	}
}

export default Concept;