import React from 'react';

import GroupThums from './img/group_thums.jpg';
import KarfeLogo from './img/karfe_logos.png'

import styled from 'styled-components';

const GroupBg = styled.div`
	position:relative;
	height: 0;
    background:url(${GroupThums}) 0 0 no-repeat;
    padding-bottom: 50%;
    background-size: 100%;
`

const GroupTxtBox = styled.div`
	position:absolute;
	top:59%;
	right: 23%;
	display: flex;
	color:#fff;
	font-size: 1.5vw;
    font-style: italic;
	font-weight: 700;
	
	@media(max-width:1024px){
		top:72%;
	}
`

const GroupConBox = styled.div`
	font-size: 4.5vw;
    font-style: normal;
    position: relative;
    top: -1vw;
	left: 2%;
`

const GroupTxtYellow = styled.p`
	color:#f4d10d;
	font-weight:400;
`

const LogoBox = styled.div`
	position: absolute;
    display: flex;
    align-items: center;
    color: #fff;
    left: 50%;
    top: 30px;
	transform: translate(-50%, 0);
	
	@media(max-width:1024px){
		flex-direction: column;
    	text-align: center;
	}
`

const LogoThums = styled.span`
	max-width:300px;
	width:100%;

	&>img{
		width:100%;
	}

	@media(max-width:1024px){
		&>img{
			width:50%;
		}
	}
`

const LogoTxt = styled.div`
	flex:1 0 auto;
	padding-left:5px;
`

const GroupPhoto = () => {
	return(
		<GroupBg>
			<LogoBox>
				<LogoThums>
					<img src={KarfeLogo} />
				</LogoThums>
				<LogoTxt>
					<p>(사)대한기능재활운동협회</p>
					<p>Korea Association of Rehavilitation Functional Exercise</p>
				</LogoTxt>
			</LogoBox>
			<GroupTxtBox>
				<p>Personal Trainer Mentoring School</p>
				<GroupConBox>
					<p>퍼스널 트레이너</p>
					<GroupTxtYellow>멘토링 스쿨 2기</GroupTxtYellow>
				</GroupConBox>
			</GroupTxtBox>
		</GroupBg>
	)
}

export default GroupPhoto;