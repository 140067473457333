import React from 'react';

import profil1 from './img/mento_profil_1.jpg';
import profil2 from './img/mento_profil_2.jpg';
import profil3 from './img/mento_profil_3.jpg';
import profil4 from './img/mento_profil_4.jpg';
import profil5 from './img/mento_profil_5.jpg';
import profil6 from './img/mento_profil_6.jpg';
import profil7 from './img/mento_profil_7.jpg';
import profil8 from './img/mento_profil_8.jpg';
import profil9 from './img/mento_profil_9.jpg';
import profil10 from './img/mento_profil_10.jpg';
import profil11 from './img/mento_profil_11.jpg';
import profil12 from './img/mento_profil_12.jpg';
import profil13 from './img/mento_profil_13.jpg';
import profil14 from './img/mento_profil_14.jpg';
import profil15 from './img/mento_profil_15.jpg';
import profil16 from './img/mento_profil_16.jpg';
import profil17 from './img/mento_profil_17.jpg';

import styled from 'styled-components';

const OrgContainer = styled.div`
	max-width:1800px;
	margin:0 auto;
	border:1px solid #000;
`

const TeacherTitBox = styled.div`
	text-align:center;
	background:#f4f4f4;
	padding-bottom:100px;
`

const TeacherTit = styled.h2`
	padding:120px 0;
	font-size:64px;
	font-weight:100;
	color:#565656;
	text-align:center;

	&>span{
		color:#a49a21;
	}

	@media(max-width: 960px){
		padding:60px 0;
		font-size: 33px;
	}

	@media(max-width:480px){
		&>span{
			display:block;
		}
	}
`


const TeacherTop = styled.div`
	padding: 40px 15px;
	text-align: center;
`

const TeacherProfile = styled.div`
	display:inline-block;
	text-align:left;
`

const ProfileInner = styled.div`
	display:flex;
	align-items: center;
`


const ProfileRght = styled.div`
	flex: 1 0 auto;
    padding-left: 18px;
`

const ProfileTop = styled.p`
	font-size: 20px;
    padding-bottom: 30px;
`

const ProfileName = styled.p`
	font-size: 34px;
    font-weight: 900;
    letter-spacing: -4px;
	padding-bottom: 20px;
	color:#565656;
`

const ProfileBtm = styled.p`
	font-size: 13px;
    line-height: 130%;
`

const ProfileBody = styled.div`
	display:flex;

	@media(max-width:1480px){
		flex-direction:column;
	}
`

const ProfileBodyInner = styled.div`
	width: 50%;

	&:first-child{
		border-right:1px solid #000;
	}

	@media(max-width:1480px){
		width:100%;
	}
`

const ProfileBox = styled.div`
	width:19%;
	margin: 0 .5%;
	padding-bottom:32px;

	@media(max-width:640px){
		width: 28%;
	}

`

const ProfileBodyLoc = styled.div`
	padding:8px 0;
	font-size:20px;
	color:#fff;
	background:#2096c8;
`

const ProfileLocTop = styled.div`
	display: flex;
	justify-content: center;
	
	@media(max-width:640px){
		flex-wrap: wrap;
	}
`

const ProfileNameTop = styled.p`
	padding: 14px 0;
	font-weight:700;
	font-size:20px;	
`

const ProfileInfo = styled.p`
	font-size:12px;
	padding-bottom:3px;
	letter-spacing: -1.8px;
`

const ProfileThums = styled.img`
	display:block;
	width:100%;
`


const data1 = [
	{img: profil2, name:'정주연 강사', txt:'카르페 전임강사', txt2:'H퍼스널 트레이닝 대표'},
	{img: profil3, name: '장유태 강사', txt: '카르페 전임강사', txt2: '키넥틱 퍼포먼스 대표'},
	{img: profil4, name: '강준 강사', txt: '카르페 전임강사', txt2: '다듬체 퍼스널 트레이닝 센터 대표'}, 
];

const data2= [
	{img: profil5, name: '강슈호 강사', txt: '카르페 마스터 트레이너', txt2: '필라테스'},
	{img: profil6, name: '김민성 강사', txt: 'H퍼스널트레이닝 트레이너', txt2: '카르페 마스터 트레이너'},
	{img: profil7, name: '김찬욱 강사', txt: '크로스핏', txt2: ''},
	{img: profil8, name: '정연창 강사', txt: '선수트레이닝', txt2: '(NC다이노스 스트렝스 컨디셔닝 코치)'},
	{img: profil9, name: '최혁준 강사', txt: '대전 다듬체 퍼스널', txt2: '트레이닝센터 실장'}, 
]

const data3 = [
	{img: profil10, name: '김동우 강사', txt: '카르페 전임강사', txt2: 'RAD 마스터 트레이너'},
	{img: profil11, name: '이채관 교수', txt: '동원과학기술대학교 겸임교수', txt2: '물리치료사,체육교육학 석사'},
	{img: profil12, name: '임병권 교수', txt: '부산외국어대학교,경성대학교', txt2: '동원과학기술대학교 외래교수'},
]

const data4 = [
	{img: profil13, name: '김근주 강사', txt: '스마트핏 운동센터 옥포점 점장', txt2: '카르페 교육강사'},
	{img: profil14, name: '허준환 강사', txt: 'Conan Crossfit WinYourBody 헤드코치', txt2: '영남대학교 사회체육학과 외래교수'},
	{img: profil15, name: '이지연 강사', txt: '카르페 마스터 트레이너', txt2: '(그룹 엑서사이즈)'},
	{img: profil16, name: '조민정 강사', txt: '스마트핏 운동센터 거제 옥포점 팀장', txt2: '현 카르페 교육강사'},
	{img: profil17, name: '최병권 강사', txt: '청암대학교 재활스포츠학과겸임교수', txt2: 'NC다이노스 선수 트레이닝 코치'},
]

const Teacher = () => {

	function Renders(list, idx){
		return(
			<ProfileBox key={idx}>
				<div>
					<ProfileThums src={list.img} alt={list.name} />
				</div>
				<div>
					<ProfileNameTop>{list.name}</ProfileNameTop>
					<ProfileInfo>{list.txt}</ProfileInfo>
					<ProfileInfo>{list.txt2}</ProfileInfo>
				</div>
			</ProfileBox>
		)
	}
	
	function lftTop(){
		return data1.map((list, idx)=>{
			return Renders(list, idx);
		})
	}

	function lftBottom(){
		return data2.map((list, idx) => {
			return Renders(list, idx);
		})
	}

	function RghtTop() {
		return data3.map((list, idx) => {
			return Renders(list, idx);
		})
	}

	function RghtBottom() {
		return data4.map((list, idx) => {
			return Renders(list, idx);
		})
	}

	return(
		<TeacherTitBox>
			<div>
				<TeacherTit>카르페멘토링스쿨 <span>멘토강사</span></TeacherTit>
			</div>
			<OrgContainer>
				<TeacherTop>
					<TeacherProfile>
						<ProfileInner>
							<div><ProfileThums src={profil1} /></div>
							<ProfileRght>
								<ProfileTop>교장</ProfileTop>
								<ProfileName>박일봉 교수</ProfileName>
								<ProfileBtm>
									현, (사)대한기능재활운동협회 KARFE 대표<br />
									현, (주)스마트핏 대표이사<br />
									현, 부산외국어대학교 대학원 스포츠재활학과 주임교수<br />
									이학박사, 의학박사, 경영컨설팅학 박사 과정 이수<br />
									물리치료사, 건강운동관리사<br />
								</ProfileBtm>
							</ProfileRght>
						</ProfileInner>
					</TeacherProfile>
				</TeacherTop>
				<ProfileBody>
					<ProfileBodyInner>
						<ProfileBodyLoc>서울</ProfileBodyLoc>
						<div style={{padding: '30px 0'}}>
							<ProfileLocTop>
								{lftTop()}
							</ProfileLocTop>
							<ProfileLocTop>
								{lftBottom()}
							</ProfileLocTop>
						</div>
					</ProfileBodyInner>
					<ProfileBodyInner>
						<ProfileBodyLoc>부산</ProfileBodyLoc>
						<div style={{padding: '30px 0'}}>
							<ProfileLocTop>
								{RghtTop()}
							</ProfileLocTop>
							<ProfileLocTop>
								{RghtBottom()}
							</ProfileLocTop>
						</div>
					</ProfileBodyInner>
				</ProfileBody>
			</OrgContainer>
		</TeacherTitBox>
	)
}

export default Teacher;