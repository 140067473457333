import React, {Component} from 'react';

import styled from 'styled-components';

import CurriculumBg from './img/curriculum_bg.jpg';
import MoCurriculumBg from './img/m_curriculum_bg.jpg';


const CurriculumBox = styled.div`
	height:1260px;
	background:url(${CurriculumBg}) 0 0 no-repeat;

	@media(max-width:1098px){
		height:1048px;
		background:url(${MoCurriculumBg}) 0 0 no-repeat;
		background-size:cover;
	}
`

const InfoConTit = styled.h2`
    text-align: center;
    font-size: 63px;
    color: #fff;
	letter-spacing: -5px;
	padding:60px 0 36px 0;
	line-height: 130%;

	&>span{
		font-family: 'Impact';
	}

	@media(max-width:960px){
		font-size: 40px;
		padding-top:110px;
		padding-bottom: 30px;
	}
`

const CurriculumTxtBox = styled.div`
	text-align:center;
`

const CurriculumTxtItems = styled.div`
	color:#fff;
	padding-bottom: 26px;
`

const CurriculumTxt = styled.p`
	font-size:30px;
	font-weight:900;
	padding-bottom:24px;
	padding-top:24px;

	&>span{
		color:#9fddc4;
	}

	@media(max-width:960px){
		font-size:22px;
		padding:16px 0;
	}
`

const CurriculumSubTxt = styled.p`
	font-size:22px;
	line-height:140%;
	font-weight:300;

	@media(max-width:960px){
		font-size:16px;
	}

	@media(max-width:560px){
		padding:0 10px;
		&>br{
			display:none;
		}
	}
`

class Curriculum extends Component {
	componentDidMount() {
		this.props.getOffset('curriculum', this.curriculum);
	}

	render(){
		return(
			<CurriculumBox 
				id="curriculum"
				ref={ref => {
					this.curriculum = ref;
				}}
			>
				<InfoConTit><span>DNS</span> 코스소개</InfoConTit>
				<CurriculumTxtBox>
					<CurriculumTxtItems>
						<CurriculumTxt>1.<span>DNS정규과정 A-D</span>(DNS Clinical course)</CurriculumTxt>
						<CurriculumSubTxt>
							DNS 정규 교육과정. 의료인 및 스포츠 전문가, 석사 이상의 스포츠 전공자를 대상으로<br/>
							DNS의 기본적인 과정을 배웁니다. A~D 코스는 단계별 교육과정입니다. A~C 코스는 별도의 <br />
							테스트 없이  수료가 가능합니다. D코스를 등록하려며 A~C코스 수료와  4개 이상의 <br />
							다른 DNS코스 과정을 수료해야 합니다.   Practitioner과정은 프라하 스쿨에서 인증하는 자격과정으로<br />
							A~D 과정의 TEST를 완료 해야 자격 취득을 할 수 있습니다.
						</CurriculumSubTxt>
					</CurriculumTxtItems>
					<CurriculumTxtItems>
						<CurriculumTxt>2.<span>DNS소아과정 part Ⅰ~Ⅲ</span>(DNS Pediatric)</CurriculumTxt>
						<CurriculumSubTxt>
							의료인 및 스포츠 전문가, 석사 이상의 스포츠 전공자를 대상으로 DNS기본 원리에 근거하여<br />
							소아 파트를 배우는 심화과정입니다.  Ⅰ~Ⅲ 단계별 교육과정으로 진행하며 Practitioner과정은<br />
							프라하 스쿨에서 인증하는 자격과정으로  PartⅠ~Ⅲ 과정과 DNS D코스를 수료하고 각 과정의 <br />
							TEST를 완료 해야 자격 취득을 할 수 있습니다.
						</CurriculumSubTxt>
					</CurriculumTxtItems>
					<CurriculumTxtItems>
						<CurriculumTxt>3.<span>DNS Exercise</span>(part Ⅰ~Ⅲ)</CurriculumTxt>
						<CurriculumSubTxt>
							의료인 및 스포츠 전문가를 대상으로 DNS의 이론을 학습하고 발달운동학에 근거한 운동방법 및 <br />
							능동적 재활 운동 접근 방법을 배우는 과정입니다.  Certified DNS Exercise Trainer 과정은<br />
							프라하 스쿨 인정 자격과정으로 PartⅠ~Ⅲ 과정 수료 후 Part Ⅲ 과정에서 TEST를<br />
							통과해야 자격 취득이 가능합니다.
						</CurriculumSubTxt>
					</CurriculumTxtItems>
					<CurriculumTxtItems>
						<CurriculumTxt>4.<span>Specialized DNS Course</span></CurriculumTxt>
						<CurriculumSubTxt>
							의료인 및 스포츠 전문가들을 대상으로 한다. 단일 코스로 진행되며 척추측만증과 같은<br />
							특정 질환이나 경추, 어깨, 발과 같이 인체 특정 부위의 기능부전에 대한 DNS접근 방법, <br />
							그리고 필라테스, 바벨 리프팅, 요가, 다양한 스포츠 종목들을 대상으로 DNS관점의 접근 방법을<br />
							배우는 과정 이다. 별도의 자격 과정은 없습니다.
						</CurriculumSubTxt>
					</CurriculumTxtItems>
				</CurriculumTxtBox>
			</CurriculumBox>
		)
	}
}


export default Curriculum;