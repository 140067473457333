import React from 'react';

import WhatPeople from './img/karfeis_people.jpg'
import MoWhatPeople from './img/m_karfeis_people.jpg'

import styled from 'styled-components';

const WhatWarrper = styled.section`
	margin:96px 0 0 0;

	@media (max-width: 960px){
		margin:60px 0 0 0;
	}
`

const WhatTextBox = styled.div`
	color:#565656;
	text-align:center;
`

const WhatTit = styled.h1`
	font-size:64px;
	font-weight:100;
	letter-spacing: -6px;

	@media (max-width:640px){
		font-size:54px;
	}
`

const WhatSubTit = styled.span`
	font-weight:900;
	letter-spacing: -6px;
`

const WhatTxt = styled.p`
	padding: 20px 0;
	font-size:30px;
	font-weight:900;

	@media (max-width:640px){
		font-size:20px;
	}
`

const WhatSubTxt = styled.p`
	line-height:130%;

	@media (max-width:640px){
		padding:0 15px;
	}
` 

const WhatPeopleImg = styled.div`
	height:0;
	margin-top: 60px;
	background:url(${WhatPeople}) center center no-repeat;
	background-size: cover;
	padding-bottom:26%;
	@media (max-width:960px){
		padding-bottom:46%;
	}

	@media(max-width:720px){
		display:none;
	}
`

const MoWhatPeopleImg = styled.div`
	display:none;
	height: 410px;
	margin-top: 60px;
	background:url(${MoWhatPeople}) center center no-repeat;
	background-size: cover;
	@media(max-width:720px){
		display:block;
		height: 0;
    	padding-bottom: 71%;
	}
`

const MobileBr = styled.br`
	@media (max-width:640px){
		display:none;
	}
`

const KarfeWhat = () => {
	return(
		<WhatWarrper>
			<WhatTextBox>
				<WhatTit>What is <WhatSubTit>KARFE</WhatSubTit>?</WhatTit>
				<WhatTxt>카르페는 ‘(사)대한기능재활운동협회’ 입니다.</WhatTxt>
				<WhatSubTxt>
					최근 모든 미디어나 일반인들 사이에서는 근육이 많고 지방이 적은 소위  ‘몸짱’이라고 <MobileBr/>
					불리는 몸의 형태를 지향합니다. 하지만 겉보기에 좋은 몸이 반드시 좋은 기능을 가지는 것은 아닙니다. <MobileBr />
					오히려 보기에 좋은 몸만을 위한 운동방법이 관절이나 여러 움직임에 좋지 않은 결과로 이어지기도 합니다. <MobileBr />
					카르페는 보기에 좋은 몸이 아닌 바른 몸에서 좋은 기능이 나온다는 철학을 가지고<MobileBr />
					기능과 재활에 초점을 두고 설립된 공식 사단법인 단체입니다. 한국의 많은 현장의 휘트니스 리더들에게 좋은 교육을<MobileBr />
					제공하기 위함과 그들의 권익 및 좋은 정보를 제공하고자 설립되었습니다.
				</WhatSubTxt>
				<WhatPeopleImg/>
				<MoWhatPeopleImg/>
			</WhatTextBox>
		</WhatWarrper>
	)
}

export default KarfeWhat;