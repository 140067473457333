import React,{Component} from 'react';

import animateScrollTo from 'animated-scroll-to';

import OnCpt from './Academy_onCpt';
import OnCes from './Academy_onCes';
import OnPes from './Academy_onPes';
import OnCft from './Academy_onCft';

import AcEdu01 from './img/01.jpg';
import AcEdu02 from './img/02.jpg';
import AcEdu03 from './img/03.jpg';
import AcEdu04 from './img/04.jpg';

import ArrowDown from './img/down_arrow.png'

import styled from 'styled-components';

class AcademyEdu extends Component {

	state = {
		statusNum : null,
	}

	onCptEle = () => {
		this.setState({statusNum: 0});
	}

	getOffset = (data) => {
		animateScrollTo(data);
	}

	onCptCes = () => {
		this.setState({statusNum: 1});
	}

	onCptPes = () => {
		this.setState({statusNum: 2});
	}

	onCptCft  = () => {
		this.setState({statusNum: 3});
	}
	
	render() {
		const AcademyEduWarrper = styled.section`
			padding:93px 0;
			background:#f4f4f4;

			@media (max-width: 960px){
				padding:60px 0;
			}
		`

		const AcademyEduInner = styled.div`
			max-width:1350px;
			width:100%;
			margin:0 auto;
		`

		const AcademyEduTit = styled.h1`
			padding-bottom:93px;
			font-size:64px;
			text-align:center;
			color: #565656;

			@media(max-width:1100px){
				font-size:48px;
			}

			@media (max-width: 960px){
				padding:0 0 60px 0;
			}
		`

		const AcademyEduMenu = styled.ul`
			&:after{
				content:"";
				display:block;
				clear:both;
			}
		`

		const AcademyEduList = styled.li`
			float:left;
			width: 24%;
			margin: 0 .5%;

			@media(max-width:960px){
				width:48%;
				margin: 1% 1%;
			}
		`

		const AcademyEduThums = styled.img`
			display:block;
			width:100%;
		`

		const AcademyEduBtns = styled.span`
			position:relative;
			display:block;
			cursor:pointer;
		`

		const AcademyListBox = styled.div`
			position:absolute;
			bottom:0;
			display:flex;
			flex-direction: column;
			align-items: center;
			width: 100%;
			height: 128px;
			padding: 30px 50px;
			background:rgba(0,0,0,0.8);
			box-sizing: border-box;
			
			@media(max-width:960px){
				padding:10% 20%;
			}
		`

		const AcademyListTit = styled.span`
			color:#fff;
			font-size: 1.3vw;
			font-weight: 700;

			@media(max-width:960px){
				font-size: 2vw;
			}

			@media(max-width:720px){
				font-size:16px;
			}
		`

		const AcademyListBtn = styled.span`
			text-wrap: nowrap;
			padding: 10px 0;
			margin-top: 10px;
			border: 1px solid #fff;
			font-size: 0.8vw;
			color:#fff;
			background:url(${ArrowDown}) 85% 50% no-repeat;
			width: 100%;
			text-align: center;

			@media(max-width:960px){
				font-size: 1.5vw;
			}

			@media(max-width:720px){
				width: 100%;
				font-size:12px;
			}
		`

		return (
			<>
				<AcademyEduWarrper>
					<AcademyEduInner>
						<AcademyEduTit>카르페 교육안내</AcademyEduTit>
						<AcademyEduMenu>
							<AcademyEduList>
								<AcademyEduBtns onClick={this.onCptEle}>
									<AcademyEduThums src={AcEdu01} />
									<AcademyListBox>
										<AcademyListTit>퍼스널트레이닝(CPT)</AcademyListTit>
										<AcademyListBtn>상세보기</AcademyListBtn>
									</AcademyListBox>
								</AcademyEduBtns>
							</AcademyEduList>
							<AcademyEduList>
								<AcademyEduBtns onClick={this.onCptCes}>
									<AcademyEduThums src={AcEdu02} />
									<AcademyListBox>
										<AcademyListTit>재활교정운동(CES)</AcademyListTit>
										<AcademyListBtn>상세보기</AcademyListBtn>
									</AcademyListBox>
								</AcademyEduBtns>
							</AcademyEduList>
							<AcademyEduList>
								<AcademyEduBtns onClick={this.onCptPes}>
									<AcademyEduThums src={AcEdu03} />
									<AcademyListBox>
										<AcademyListTit>선수트레이닝(PES)</AcademyListTit>
										<AcademyListBtn>상세보기</AcademyListBtn>
									</AcademyListBox>
								</AcademyEduBtns>
							</AcademyEduList>
							<AcademyEduList>
								<AcademyEduBtns onClick={this.onCptCft}>
									<AcademyEduThums src={AcEdu04} />
									<AcademyListBox>
										<AcademyListTit>펑셔널트레이닝(CFT)</AcademyListTit>
										<AcademyListBtn>상세보기</AcademyListBtn>
									</AcademyListBox>
								</AcademyEduBtns>
							</AcademyEduList>
						</AcademyEduMenu>
					</AcademyEduInner>
				</AcademyEduWarrper>
				{this.state.statusNum === 0 && <OnCpt getCptOffset={this.getOffset}/>}
				{this.state.statusNum === 1 && <OnCes getCptOffset={this.getOffset}/>}
				{this.state.statusNum === 2 && <OnPes getCptOffset={this.getOffset}/>}
				{this.state.statusNum === 3 && <OnCft getCptOffset={this.getOffset}/>}
			</>
		);
	}
}

export default AcademyEdu;
