import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Axios from "axios";
import Avatar from "@material-ui/core/Avatar";
import StarRating from "../../components/StartRating";

export default function OnlineEducationDetail({
  match: {
    params: { id },
  },
}) {
  const [data, setData] = useState();
  const router = useHistory();

  useEffect(() => {
    const getData = async () => {
      const result = await Axios.post(
        "https://api.teamstc.org:3003/api/user/vod/all",
        { id }
      );
      if (!result?.data?.success) return;
      setData(result.data.result[0]);
    };
    getData();
  }, []);

  const goDownloadApp = () => {
    const isMobile = () => {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      if (/android/i.test(userAgent)) return true;
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) return true;
      return window.innerWidth <= 768;
    };
    if (isMobile())
      window.location.href =
        "https://api.teamstc.org:3003/appgate?id=/user/video/" + id;
    else router.push("/appintoduce");
  };

  return (
    <div className="min-h-[60vh] p-5 max-w-[1000px] mx-auto">
      {data && (
        <div className="p-3">
          <p className="text-center text-xl font-bold break-words break-keep">
            {data.title}
          </p>
          {data.star.count === 0 || (
            <div className="flex items-center gap-1 justify-center">
              <p className="text-sm opacity-50">{data.star.avg}</p>
              <StarRating rating={data.star.avg} size="15" />
              <p className="text-xs opacity-50">({data.star.count})</p>
            </div>
          )}
          <div className="px-5 my-5 [&>div>p:first-child]:opacity-50 text-sm [&>div]:flex [&>div]:justify-between [&>div]:items-center mx-auto max-w-[500px]">
            <div>
              <p>수강료</p>
              <div>
                {data.price.sale != data.price.origin && (
                  <div className="flex gap-2 justify-end items-center">
                    <p className="text-sm bg-emerald-500 text-white px-2 rounded">
                      {data.sale.end} 까지
                    </p>
                    <p className="text-sm opacity-50 line-through">
                      {(+data.price.origin).toLocaleString()} 원
                    </p>
                  </div>
                )}
                <p className="font-bold text-right text-base">
                  {(+data.price.sale).toLocaleString()} 원
                </p>
              </div>
            </div>
            <div>
              <p>배지</p>
              <p>{data.test.badge_yn === "Y" ? "발급" : "미발급"}</p>
            </div>
            <div>
              <p>시험종류</p>
              <p>{data.test.badge_yn === "Y" ? "온라인필기" : "없음"}</p>
            </div>
            <div>
              <p>유효기간</p>
              <p>구입 후 {data.period}일</p>
            </div>
          </div>

          <div className="mx-auto max-w-[1000px]">
            <div>
              <p className="text-lg opacity-70 font-bold">강사</p>
              <div className="flex w-full">
                <div className="p-4">
                  <Avatar
                    src={`https://api.teamstc.org:3003/api/img/trainerinfo/${data.teacher.id}.jpg`}
                    sx={{ width: 50, height: 50 }}
                  />
                </div>
                <div className="flex-1 overflow-hidden">
                  <div className="flex items-end justify-between">
                    <p className="text-lg">{data.teacher.name}</p>
                  </div>
                  {data.teacher.star.count === 0 || (
                    <div className="flex items-center gap-1">
                      <p className="text-sm opacity-50">
                        {data.teacher.star.avg}
                      </p>
                      <StarRating rating={data.teacher.star.avg} size="15" />
                      <p className="text-xs opacity-50">
                        ({data.teacher.star.count})
                      </p>
                    </div>
                  )}
                  <div className="text-xs leading-[120%] opacity-50">
                    {data.teacher.career.map((data, index) => (
                      <p className="truncate" key={index}>
                        {data}
                      </p>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-5">
              <p className="text-lg opacity-70 font-bold">강의소개</p>
              <div className="px-1">
                {[
                  { text: "학습목표", obj: "object" },
                  { text: "커리큘럼", obj: "curriculum" },
                  { text: "상세설명", obj: "detail" },
                ].map((info, index) => (
                  <div key={index}>
                    <p className="p-2 border">{info.text}</p>
                    <p className="text-sm p-2 whitespace-pre-line ml-2">
                      {data.info[info.obj].replaceAll("\r", "\n")}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="mt-5">
            <p className="text-lg opacity-70 font-bold">강의목차</p>
            <div className="px-1 text-sm">
              {data.index.map((vod, index) => (
                <div
                  key={index}
                  className="flex gap-2 mb-1 items-start odd:bg-zinc-200 rounded px-2 py-1"
                >
                  <p>{vod.no}.</p>
                  <p className="break-keep break-words"> ※ {vod.title}</p>
                  <div className="ml-auto flex gap-2">
                    <p className="whitespace-nowrap">({vod.time})</p>
                    {/* {vod.free_yn === "Y" && (
                      <button
                        className="whitespace-nowrap text-white bg-red-400 px-1 rounded"
                        onClick={() =>
                          open_video_player({
                            no: vod.no,
                            type: "free",
                            vid: data.id,
                          })
                        }
                      >
                        맛보기
                      </button>
                    )} */}
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="mt-[4rem] flex justify-center items-center">
            <button
              className="w-[95%] rounded bg-blue-500 py-5 text-lg text-white"
              onClick={goDownloadApp}
            >
              카르페 앱에서 자세히 보기
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
