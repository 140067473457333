import React, { Component } from 'react';

import KbsiTop from './Kbsi_top';
import KbsiQuestion from './Kbsi_question';
import KbsiTodos from './Kbsi_todos';
import KbsiBanner from './Kbsi_banner';
import KbsiGreetings from './Kbsi_greetings';
import KbsiMember from './Kbsi_member';
import BtmBanner from './Kbsi_btm_banner';

class index extends Component {
	render() {
		return (
			<div>
				<KbsiTop/>
				<KbsiQuestion/>
				<KbsiTodos/>
				<KbsiBanner/>
				<KbsiGreetings/>
				<KbsiMember/>
				<BtmBanner/>
			</div>
		);
	}
}

export default index;