import React, {Component} from 'react';

import MichaelBoyle from './img/michael_boyle.jpg';

import styled from 'styled-components';

const IntroWarrper = styled.section`
	padding:100px 0;
	text-align:center;

	@media(max-width:1024px){
		padding:60px 0;
	}
`

const IntroTit = styled.h1`
	font-family:'Impact';
	font-size:70px;
	color: #3d3d3d;

	@media(max-width:960px){
		font-size:42px;
	}
`

const IntroTxt = styled.p`
	margin: 0 auto;
	padding: 0 20px;
	padding-top: ${props => props.top ? '50px' : '0px'};
	color: #565656;
	font-size: 31.67px;
	font-weight: ${props=>props.lighter ? 100 : 700};
	letter-spacing: -1px;
	line-height: ${props => props.lineHeight ? '140%' : ''};

	@media(max-width:960px){
		font-size:22px;
	}

	@media(max-width:780px){
		display:inline-block;

		&>br,&>span>br{
			display:none;
		}
	}
`

const IntroSubTxt = styled.span`
	font-weight: ${props => props.lighter ? 100 : 700};
	line-height: ${props => props.lineHeight ? '120%' : ''};

	@media(max-width:780px){
		&>br{
			display:none;
		}
	}
`

const Emdeb = styled.div`
	position: relative;
	width:100%;
	margin-top: 55px;
	background: #595959;
`

const EmdebInner = styled.div`
	display:flex;
	max-width: 1350px;
	margin: 0 auto;
	
	@media(max-width:720px){
		flex-direction: column;
	}
`

const EmdebVideo = styled.div`
	position: relative;
	width: 50%;
	box-sizing: border-box;
    padding: 20px 20px 66px 0;
	background: #000;
	
	@media(max-width:720px){
		width: 100%;
		padding: 0 0 56% 0;
	}
`

const EmdebIframe = styled.iframe`
	width: 100%;
	height: 100%;

	@media(max-width:720px){
		position: absolute;
		top: 0;
		left: 0;
	}
`

const EmdebRghtCon = styled.div`
	width:50%;
	padding: 25px 36px;
	box-sizing: border-box;
	
	@media(max-width:720px){
		width:100%;
	}
`

const EmdebThums = styled.img`
	display:block;
	width:100%;
`

const EmdebThumsTxt = styled.p`
	text-align: left;
	color:#fff;
	padding:30px 0 0 0;

	@media(max-width:720px){
		padding-top:15px;
	}
`

const EmdebThumsTxtBtm = styled(EmdebThumsTxt)`
	text-align: left;
    color: #d8d8d8;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: -1px;
`

const ThumsNameTxt = styled.span`
	display: inline-block;
	font-size: 42px;
    font-weight: 300;
	font-family: 'impact';
	vertical-align: middle;
`

const ThumsNameKoTxt = styled.span`
	display: inline-block;
	font-size:14px;
	vertical-align: middle;
	padding-left: 10px;

	&>span{
		color:#e6dd01;
	}
`

class Intro extends Component {
	componentDidMount(){
		this.props.getOffset('intro',this.intro);
	}

	render() {
		return (
			<IntroWarrper 
				id="intro"
				ref={ref => {
					this.intro = ref;
				}}
			>
				<IntroTit>What is CFSC?</IntroTit>
				<IntroTxt lighter lineHeight top>
					마이클 보일이 만든 북미 최고의 펑셔널 스트렝스 코치 자격과정입니다.<br/>
					<span style={{fontWeight:"900"}}>CFSC 코치로 미국의 퍼스널 트레이닝 분야 그리고 선수 트레이닝 분야에서
					필수적인<br /> 교육과정으로 자리매김하고 있습니다.</span>
					<IntroSubTxt lighter lineHeight>CFSC 코스는 Level 1, 2로 나누어져 있으며<br />  최적의 기능적 움직임을 만들기 위한 최고의 코스입니다.</IntroSubTxt>
				
				</IntroTxt>
				<Emdeb>
					<EmdebInner>
						<EmdebVideo>
							<EmdebIframe src="https://www.youtube.com/embed/iq-Ix0JyWbA" frameborder="0" allowfullscreen="allowfullscreen"></EmdebIframe>
						</EmdebVideo>
						<EmdebRghtCon>
							<div>
								<EmdebThums src={MichaelBoyle}/>
								<EmdebThumsTxt>
									<ThumsNameTxt>Michael Boyle</ThumsNameTxt>
									<ThumsNameKoTxt><span>CFSC 창시자</span> 마이크 보일</ThumsNameKoTxt>
								</EmdebThumsTxt>
								<EmdebThumsTxtBtm>
									2013년 미국 프로야구 보스턴 레드삭스의 우승 당시 스트렝스 &amp; 컨디셔닝 코치였으며,  또한 미국 하키 
									국가대표 코치로서 몇 번의 올림픽에서 금,은메달을 거머쥐게 했던 일등공신이었다. 현재 미국에서
									가장 영향력 있는 프리젠터 및 트레이닝 교육자로서, MBSC, CFSC 창업자 이자 교육자로 활동하고 있다.  
								</EmdebThumsTxtBtm>
							</div>
						</EmdebRghtCon>
					</EmdebInner>
				</Emdeb>
			</IntroWarrper>
		)
	}
}

export default Intro;