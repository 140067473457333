import React from 'react';

import BannerThums from './img/master_trainer_bg.jpg';
import MoBannerThums from './img/m_master_trainer_bg.jpg';

import styled from 'styled-components';

const BannerWarrper = styled.div`
	height:830px;
	background:url(${BannerThums}) center center no-repeat;
	background-size: cover;

	@media(max-width:960px){
		height:560px;
		background:url(${MoBannerThums}) center center no-repeat;
		background-size: cover;
	}

	@media(max-width:480px){
		height:380px;
	}
`

const MasterBanner = () => {
	return(
		<BannerWarrper/>
	)
}

export default MasterBanner;