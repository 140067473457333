import React, { Component } from 'react';

import styled from 'styled-components';

import SnsFaceBookThums from './img/facebook_ico.jpg';
import SnsKakaoTalkThums from './img/kakao_ico.jpg';

const SnsWarpper = styled.div`
	padding:74px 0 74px 0;
	background:#f4f4f4;
`

const SnsInner = styled.div`
	display: flex;
	max-width: 1354px;
	flex-wrap: wrap;
	margin: 0 auto;
	
	@media (max-width:1480px){
		width:80%;
		text-align: center;
		flex-direction: column;
	}

	@media (max-width:1024px){
		width:70%;
	}

	@media (max-width:640px){
		width:90%;
	}
`

const SnsItems = styled.div`
	flex:1 1 auto;

	&:after{
		content:"";
		clear:both;
	}
`

const SnsExtends = styled.p`
	padding-bottom: 20px;
	color:#565656;
	letter-spacing: -1.4px;
`

const SnsEngTxt = styled(SnsExtends)`
	font-size: 48px;
	font-weight:100;

	@media (max-width:720px){
		font-size:48px;
	}
`

const SnsInfoTxt = styled(SnsExtends)`
	font-size: 19px;
	font-weight:400;
	line-height: 130%;
	padding-bottom: 21px;

	@media (max-width:720px){
		font-size:16px;
	}

	@media (max-width:640px){
		font-size:14px;
	}
`

const SnsNumberTxt = styled(SnsExtends)`
	font-size:65px;
	font-weight:900;
	padding-bottom: 13px;
	text-shadow:
    -1px -1px 0 #565656,
    1px -1px 0 #565656,
    -1px 1px 0 #565656,
	1px 1px 0 #565656;  
	
	@media(max-width:600px){
		font-size:50px;
	}
`

const SnsTimeStampTxt = styled(SnsExtends)`
	font-size:16px;
	letter-spacing: 0;
    font-weight: 700;
`

const SnsSocailBox = styled.div`
	width:49%;
	max-width: 222px;
	float:left;

	@media (max-width:1480px){
		float:none;
		display: inline-block;
		max-width: 160px;
	}
`

const SnsFaceBook = styled(SnsSocailBox)`

`

const SnsFaceBookImg = styled.img`
	display:block;
	width:100%;
`

const SnsKakaoTalk = styled(SnsSocailBox)`
	margin-left: 2%;
`

const SnsKakaoTalkImg = styled.img`
	display:block;
	width:100%;
`

class index extends Component {
	render() {
		return (
			<SnsWarpper>
				<SnsInner>
					<SnsItems>
						<SnsEngTxt>customer center</SnsEngTxt>
						<SnsInfoTxt>안녕하세요!카르페 고객센터입니다.무엇이든 친절히 안내하여 드리겠습니다.</SnsInfoTxt>
						<SnsNumberTxt>051-583-9645</SnsNumberTxt>
						<SnsTimeStampTxt>MON-FRI 09:00 ~ 18:00(주말, 공휴일 휴무)</SnsTimeStampTxt>
					</SnsItems>
					<SnsItems>
						<SnsFaceBook>
							<a href="https://www.facebook.com/karfekorea" target="_blank" rel="noopener noreferrer"><SnsFaceBookImg src={SnsFaceBookThums} /></a>
						</SnsFaceBook>
						<SnsKakaoTalk>
							<a href="http://pf.kakao.com/_hrKXxl" target="_blank" rel="noopener noreferrer"><SnsKakaoTalkImg src={SnsKakaoTalkThums} /></a>
						</SnsKakaoTalk>
					</SnsItems>
				</SnsInner>
			</SnsWarpper>
		);
	}
}

export default index;