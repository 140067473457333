import React, { Component } from 'react';

import CptVideo from './Cpt_video';
import CptBanner from './Cpt_banner';
import CptContents from './Cpt_Content';

class Academy_onCpt extends Component {
	state = {
		ClientY:0,
	}

	componentDidMount(){
		this.setState({ClientY: this.BoxTop.offsetTop});
	}

	render() {
		return (
			<div
				ref={ref=>{
					this.BoxTop = ref;
				}}
			>
				{this.state.ClientY ? this.props.getCptOffset(this.BoxTop.offsetTop) : ''}
				<CptVideo/>
				<CptBanner/>
				<CptContents/>
			</div>
		);
	}
}

export default Academy_onCpt;