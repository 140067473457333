import React, { Component } from 'react';

import PeopleReply from './img/poster1.jpg';
import EduSystem from './img/poster2.jpg';

import ReplayModal from './Replay_modal';
import EduSysModal from './Edusystem_modal';

import styled from 'styled-components';

const VideoWarpper = styled.div`

	&:after{
		content:"";
		display:block;
		clear:both;
	}
`

const ColorBox = styled.div(props => ({
	float: 'left',
	width: '50%',
	padding: '45px 30px 40px 30px',
	background: props.background,
	boxSizing: 'border-box',
}));

const ColorBoxExtends = styled(ColorBox)`
	@media (max-width: 960px) {
		float:none;
		width:100%;
		padding: 45px 0 0 0;
	}

`

const LayoutBox = styled.div(props => ({
	float: props.direction,
	maxWidth: '640px',
	width: '100%',
	textAlign: `center`,
}));

const LayoutBoxExtends = styled(LayoutBox)`
	@media (max-width: 960px) {
		float:none;
		width:100%;
		max-width:inherit;
	}
`

const LayoutHeader = styled.p`
	font-size:33px;
	color:#fff;
	letter-spacing: -3px;
`

const TextBold = styled.span`
	font-weight:900;
`

const VideoImages = styled.img`
	display:block;
	width: 100%;
	margin-top:40px;
	cursor:pointer;
`



class HomeVideo extends Component {
	state = {
		isReply: false,
		isEduSystem: false,
	}

	OpenReplayModal = () => {
		this.setState({isReply: true});
	}

	CloseReplayModal = () => {
		this.setState({isReply: false});
	}

	OpenEduSysModal = () => {
		this.setState({isEduSystem: true});
	}

	CloseEduSysModal = () => {
		this.setState({isEduSystem: false});
	}
	

	render() {
		return (
			<VideoWarpper>
				<ColorBoxExtends background="#7ad3f3">
					<LayoutBoxExtends direction="right">
						<LayoutHeader>교육생들의 <TextBold>생생한 교육후기</TextBold></LayoutHeader>
						<VideoImages src={PeopleReply} onClick={this.OpenReplayModal}/>
						{this.state.isReply ? <ReplayModal isReply={this.state.isReply} onCloseModal={this.CloseReplayModal}/> : ''}
					</LayoutBoxExtends>
				</ColorBoxExtends>
				<ColorBoxExtends background="#a8e5fa">
					<LayoutBoxExtends direction="left">
						<LayoutHeader>차별화된 <TextBold>교육시스템</TextBold></LayoutHeader>
						<VideoImages src={EduSystem} onClick={this.OpenEduSysModal}/>
						{this.state.isEduSystem ? <EduSysModal isEdu={this.state.isEduSystem} onCloseModal={this.CloseEduSysModal} /> : ''}
					</LayoutBoxExtends>
				</ColorBoxExtends>
			</VideoWarpper>
		);
	}
}

export default HomeVideo;