import React, { Component } from 'react';

import styled from 'styled-components';

import KarfeBlue from './img/home_karfe_blue.png';

const KarfeBlueContents = styled.div`
	position: relative;
`

const KarfeBlueImg = styled.img`
	width: 100%;
    max-width: 211px;
    margin: 0 auto;
    display: block;
	padding: 110px 0 44px 0;
	
	@media (max-width:960px){
		max-width: 180px;
		padding: 60px 0;
	}
`

const KarfeTitle = styled.p`
	text-align: center;
	font-size: 64px;
	color: #575757;
	font-weight: 300;

	@media (max-width:960px){
		font-size: 51px;
	}

	@media (max-width:640px){
		font-size: 6.5vw;
	}
`

const KarfeTxt = styled(KarfeTitle)`
	font-size: 22px;
	padding:30px 0 66px 0;
	line-height: 130%;

	@media (max-width:960px){
		width: 80%;
    	margin: 0 auto;
		font-size: 22px;
		padding: 15px 0 60px 0;
	}

	@media (max-width:640px){
		font-size: 3.2vw;
	}

	@media(max-width:480px){
		font-size: 13px;
	}
`

const KarfeBr = styled.br`
	@media (max-width:960px){
		display:none;
	}
`

class HomeQuestion extends Component {
	render() {
		return (
			<KarfeBlueContents>
				<KarfeBlueImg src={KarfeBlue}/>
				<KarfeTitle>카르페는 어떤 단체인가요?</KarfeTitle>
				<KarfeTxt>카르페는 보기에 좋은 몸이 아닌  바른 몸에서 좋은 기능이 나온다는<KarfeBr/>
				철학을 가지고 기능과 재활에 초점을 두고 설립된 공식 사단법인단체입니다.</KarfeTxt>
			</KarfeBlueContents>
		);
	}
}

export default HomeQuestion;