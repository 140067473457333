import React, {Component} from 'react';

import styled from 'styled-components';

const IntroWarrper = styled.section`
	padding:100px 0;
	text-align:center;
`

const IntroTit = styled.h1`
	font-family:'Impact';
	font-size:70px;
	color: #3d3d3d;

	@media(max-width:960px){
		font-size:42px;
	}
`

const IntroTxt = styled.p`
	max-width: 720px;
	margin: 0 auto;
	padding: 0 20px;
	padding-top: ${props => props.top ? '50px' : '0px'};
	padding-bottom: 5px;
	color: #565656;
	font-size: 24px;
	font-weight: ${props=>props.lighter ? 100 : 700};
	letter-spacing: -3px;
	line-height: ${props => props.lineHeight ? '120%' : ''};

	&>span{
		font-weight:100;
	}

	@media(max-width:960px){
		font-size:24px;
	}
`

const Emdeb = styled.div`
	position: relative;
	width:100%;
	margin-top: 55px;
	padding-bottom: 19%;
	
	@media(max-width:1024px){
		padding-bottom: 50%;
	}
`

const EmdebIframe = styled.iframe`
	position: absolute;
    top: 0;
    left: 0;
	width: 100%;
	height: 100%;
`

class Intro extends Component {
	componentDidMount(){
		this.props.getOffset('intro',this.intro);
	}

	render() {
		return (
			<IntroWarrper 
				id="intro"
				ref={ref => {
					this.intro = ref;
				}}
			>
				<IntroTit>What is DNS?</IntroTit>
				<IntroTxt lighter top>동적 신경근 안정화 운동은 발달운동학(Developmental Kinesiology)에 근거하여</IntroTxt>
				<IntroTxt lineHeight>
					간의 발달 단계에  따라 나타나는 패턴들을 평가와 동시에 치료하는 접근 방법 <span>입니다.</span>
				</IntroTxt>
				<IntroTxt lighter lineHeight>중추신경계의 발달에 따른 인간 고유의 시스템을 이해하는 새로운 통합적 접근 방법 및 재활치료이론을 제시하고 있습니다.</IntroTxt>
				<Emdeb>
					<EmdebIframe src="https://player.vimeo.com/video/300240337" frameborder="0" allowfullscreen="allowfullscreen"></EmdebIframe>
				</Emdeb>
			</IntroWarrper>
		)
	}
}

export default Intro;