import React from 'react';

import BannerBg from './img/ces_bg1.jpg'

import styled from 'styled-components';

const BannerBox = styled.div`
	display: flex;
    justify-content: center;
	position:relative;
	min-height: 216px;
	padding: 20px 0;
	background:url(${BannerBg}) center center no-repeat;
	background-size: cover;
`

const BannerInner = styled.div`
	display: flex;
    justify-content: center;
	align-items: center;
	max-width: 1060px;
	color:#fff;
	box-sizing: border-box;
    padding: 0 20px;
`

const LftTxt = styled.p`
	font-size: 60px;
`

const RghtTxt = styled.p`
	font-size: 20px;
    padding-left: 50px;
	line-height: 27px;
	
	@media(max-width:520px){
		font-size:16px;
		line-height:140%;
	}
`

const CesBanner = () => {
	return (
		<BannerBox>
			<BannerInner>
				<LftTxt>
					CES
				</LftTxt>
				<RghtTxt>
					현대인들은 잘못된 생활습관 등으로 인한 근골격계통의 구종와 기능이 상실되어가 있습니다. 따라서 척추의 건강과, 신체의 기능향상이 필요합니다. 카르페 재활교정운동 전문가 과정은 간단하면서도 디테일한 CES 자세 및 체력 평가시스템을 통하여 근막완-스트레칭-근육활성화-통합운동 기법으로 구성된 모듈로 재활교정운동 전문가가 될 수 있는 교육시스템으로 구성되어 있습니다.
				</RghtTxt>
			</BannerInner>
		</BannerBox>
	)
}

export default CesBanner;