import React from 'react';

import styled from 'styled-components';

import KbsiTopBg from './img/kbsi_top_bg.png';
import KbsiTopBgTxt from './img/kbsi_top_txt.png';

const KbsiTopWarrper = styled.div`
	position: relative;
    top: 0;
	left: 0;
	width: 100%;
    height: 0;
    margin: 0 auto;
    padding-bottom: 46%;
`

const KbsiTopBox = styled.div`
	position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
	background:url(${KbsiTopBg}) 0 0 no-repeat;
	background-size: 100%;
`

const KbsiTopLogoBox = styled.p`
	position: absolute;
    top: 57%;
    right: 14.2%;
	width: 30.5%;
	
	@media(max-width:640px){
		width: 38%;
   		top: 33%;
	}
`

const KbsiTopThums = styled.img`
	width:100%;
`

const KbsiTopTit = styled.span`
	display: block;
	font-size: 1.65vw;
	font-weight: 700;
    color: #fff;
	text-align: right;
	
	@media(max-width:640px){
		font-size: 2.4vw;
	}
`

const KbsiTopTxt = styled.span`
	display: block;
	font-size: 1.19vw;
	font-weight:300;
	color:#fff;
	text-align: right;

	@media(max-width:640px){
		font-size: 1.3vw;
	}
`

const KbsiTop = () => {
	return(
		<KbsiTopWarrper>
			<KbsiTopBox>
				<KbsiTopLogoBox>
					<KbsiTopThums src={KbsiTopBgTxt} />
					<KbsiTopTit>한국 야구 스트렝스&amp;컨디셔닝 연구소</KbsiTopTit>
					<KbsiTopTxt>Korea Baseball strength &amp; conditioning Institution</KbsiTopTxt>
				</KbsiTopLogoBox>
			</KbsiTopBox>
		</KbsiTopWarrper>
	)
}

export default KbsiTop;