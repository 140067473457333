import React from 'react';

import HealthImg from './img/dns_health_bg.jpg';
import MoHealthImg from './img/m_dns_health_bg.jpg';

import BannerThums from './img/banner_thums.jpg';

import styled from 'styled-components';

const HealthWarrper = styled.div`
	position:relative;
	height:605px;
	background:url(${HealthImg}) 50% 50% no-repeat;
	background-size:cover;

	@media(max-width:720px){
		height:560px;
		background:url(${MoHealthImg}) 50% 50% no-repeat;
		background-size:cover;
	}
`

const HealthImpact = styled.span`
	font-size: 69px;
	text-shadow: -2px -2px 0 #fff, 2px -2px 0 #fff, -2px 2px 0 #fff, 2px 2px 0 #fff;
	padding-right: 8px;

	@media(max-width:710px){
		font-size:45px;
	}
`

const GroupTxtBox = styled.div`
	position: absolute;
    top: 29%;
    right: 16%;
	display: flex;
	flex-direction: column;
	width: auto;
	color:#fff;
	font-size: 24px;
	font-weight: 700;
	text-align: left;

	@media(max-width:960px){
		top:50%;
		left:50%;
		transform:translate(-50%,-50%);
		width: 100%;
    	text-align: center;
	}
`

const GroupConBox = styled.div`
	font-size: 4.5vw;
    font-style: normal;
`


const GrouptTxtMid = styled.p` 
	font-size:69px;

	@media(max-width:710px){
		font-size:45px;
	}
`

const GroupSubTxt = styled.p`
	font-size: 22px;
	font-style: italic;
	
	@media(max-width:710px){
		font-size:16px;
	}
`

const GroupBannerImg = styled.span`
	position: absolute;
    top: 51%;
    right: 16%;
	max-width: 360px;

	&>img{
		display:block;
		width:100%;
	}

	@media(max-width:960px){
		top:initial;
		bottom: 5%;
		right: 5%;
		max-width: 200px;
	}
`

const StcBanner = () => {
	return(
		<HealthWarrper>
			<GroupTxtBox>
				<GroupConBox>
					<GrouptTxtMid>
						<HealthImpact>DNS</HealthImpact>
						KOREA
					</GrouptTxtMid>
				</GroupConBox>
				<GroupSubTxt>Dynamic Neuromuscular Stabilization</GroupSubTxt>
			</GroupTxtBox>

			<GroupBannerImg><img src={BannerThums} /></GroupBannerImg>
		</HealthWarrper>
	)
}

export default StcBanner;