import React from 'react';

import Trainer1 from './img/master_trainer_photo1.jpg';
import Trainer2 from './img/master_trainer_photo2.jpg';
import Trainer3 from './img/master_trainer_photo3.jpg';

import VideoBtn from './record_video_btn';

import styled from 'styled-components';

const RecordWarrper = styled.section`
	padding: 90px 0 0 0;
`

const RecordInner = styled.div`
	
`

const RecordTit = styled.h1`
	padding-bottom:90px;
	font-size:33px;
	text-align:center;
`

const RecordProfileBox = styled.div`
	max-width:780px;
	margin:0 auto;
`

const RecordProfile = styled.div`
	display: flex;
    flex-wrap: wrap;
    flex-direction: row;
	align-items: flex-start;
	padding-bottom: 8%;

	@media(max-width:640px){
		flex-direction:column;
	}
`

const RecordProfileThumsBox = styled.div`
	width:50%;

	@media(max-width:640px){
		width:100%;
	}
`

const RecordProfileThums = styled.img`
	width:100%;
	display:block;
`

const RecordProfileTxt = styled.div`
	width: 50%;
    box-sizing: border-box;
	padding-left: 8%;

	@media(max-width:720px){
		padding-right: 10px;
	}

	@media(max-width:640px){
		width: 100%;
		padding: 6%;
		flex:1 0 auto;
	}
`

const RecordProfileName = styled.div`
	padding-bottom: 5%;
	font-size: 32px;
	font-weight:900;
`

const RecordProfileItems =styled.p`
	padding-bottom: 10%;
	font-size: 16px;
	line-height: 120%;
	color: #565656;

	@media (max-width:720px){
		font-size:16px;
		padding-bottom: 5%;
	}
`

const RecordProfileBold = styled.span`
	display:block;
	padding-bottom: 1px;
	font-weight:900;
`

const BenefitRecord = () => {
	return(
		<RecordWarrper>
			<RecordInner>
				<RecordTit>KARFE Master Trainer</RecordTit>
				<RecordProfileBox>
					<RecordProfile>
						<RecordProfileThumsBox>
							<RecordProfileThums src={Trainer1}/>
						</RecordProfileThumsBox>
						<RecordProfileTxt>
							<RecordProfileName>이자연<VideoBtn videoNumber={1}/></RecordProfileName>
							<RecordProfileItems>
								<RecordProfileBold>학력</RecordProfileBold>
								부산외국어대학교 사회체육학부 생활체육전공
							</RecordProfileItems>
							<RecordProfileItems>
								<RecordProfileBold>경력</RecordProfileBold>
								함안 대산고 여자 축구부 팀닥터<br/>
								스마트핏운동센터 헤드트레이너
							</RecordProfileItems>
							<RecordProfileItems>
								<RecordProfileBold>KARFE자격증</RecordProfileBold>
								퍼스널 트레이닝 전문가과정 (CPT)<br />
								선수트레이닝 전문가과정 (PES)<br />
								재활교정운동 전문가과정 (CES)<br />
								펑셔널 실전적용 자격과정 (GV)<br />
								펑셔널 실전적용 자격과정 (SR)<br />
								펑셔널 실전적용 자격과정 (KP)<br />
								중력가속진동운동 전문가과정<br />
								트리거포인트 전문가과정<br />
								재활&교정운동전략<br />
								수기관리 전문가 과정<br />
								산전산후 운동 프로그램<br />
								펑셔널트레이닝 워크샵<br />
							</RecordProfileItems>
						</RecordProfileTxt>
					</RecordProfile>

					<RecordProfile>
						<RecordProfileThumsBox>
							<RecordProfileThums src={Trainer2} />
						</RecordProfileThumsBox>
						<RecordProfileTxt>
							<RecordProfileName>박영주<VideoBtn videoNumber={2}/></RecordProfileName>
							<RecordProfileItems>
								<RecordProfileBold>학력</RecordProfileBold>
								동아대학교 스포츠과학대학 스포츠지도학과
							</RecordProfileItems>
							<RecordProfileItems>
								<RecordProfileBold>경력</RecordProfileBold>
								TEAM 1% 헤드트레이너
							</RecordProfileItems>
							<RecordProfileItems>
								<RecordProfileBold>KARFE자격증</RecordProfileBold>
								퍼스널 트레이닝 전문가과정 (CPT)<br />
								선수트레이닝 전문가과정 (PES)<br />
								재활교정운동 전문가과정 (CES)<br />
								펑셔널 트레이닝 전문가과정 (CFT)<br />
								3D 펑셔널 트레이닝 전문가과정<br />
								서스펜션 트레이닝 강사과정<br />
								펑셔널 불가리안백 강사과정<br />
								펑셔널 케틀벨 전문가과정<br />
								얀다의 근육 불균형의 평가와 치료<br />
								트리거포인트 전문가과정<br />
								재활&교정운동전략<br />
								수기관리 전문가 과정<br />
								펑셔널트레이닝 워크샵
							</RecordProfileItems>
						</RecordProfileTxt>
					</RecordProfile>

					<RecordProfile>
						<RecordProfileThumsBox>
							<RecordProfileThums src={Trainer3} />
						</RecordProfileThumsBox>
						<RecordProfileTxt>
							<RecordProfileName>금윤하<VideoBtn videoNumber={3}/></RecordProfileName>
							<RecordProfileItems>
								<RecordProfileBold>경력</RecordProfileBold>
								Team-Posfit 팀장<br />
								IFBB 1급 심판<br />
								경산시 체육회 보디빌딩 연합회 실무 부회장

							</RecordProfileItems>
							<RecordProfileItems>
								<RecordProfileBold>KARFE자격증</RecordProfileBold>
								선수트레이닝 전문가과정 (PES)<br />
								재활교정운동 전문가과정 (CES)<br />
								펑셔널 트레이닝 전문가과정 (CFT)<br />
								펑셔널 케틀벨 전문가과정<br />
								트리거포인트 전문가과정<br />
								수기관리 전문가과정<br />
								중력가속운동 전문가과정
							</RecordProfileItems>

							<RecordProfileItems>
								<RecordProfileBold>기타자격증</RecordProfileBold>
								NASM CPT 공인 퍼스널 트레이너<br />
								KEMA 과정 이수<br />
								모던 필라테스, 리햅 필라테스 자격<br />
								스포츠 지도자 2급 자격
							</RecordProfileItems>
						</RecordProfileTxt>
					</RecordProfile>
				</RecordProfileBox>
			</RecordInner>
		</RecordWarrper>
	)
}


export default BenefitRecord;