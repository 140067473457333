import React, {Component} from 'react';
import Modal from 'react-responsive-modal';

import styled from 'styled-components';

const VideoPlayer = styled.video`
	width:100%;
`

class ReplayModal extends Component {
	state ={
		videoUrl:'http://karfe1.cache.iwinv.net/156075574971397.mp4'
	}

	render() {
		return (
			<div>
				<Modal open={this.props.isReply} onClose={this.props.onCloseModal} center>
					<VideoPlayer controls autoPlay>
						<source src={this.state.videoUrl}/>
					</VideoPlayer>
				</Modal>
			</div>
		);
	}
}

export default ReplayModal;