import React from 'react';
import { useState } from 'react';

import styled from 'styled-components';

import Bufs630 from './img/bufs_630.png'
import Bufs530 from './img/bufs_530_mo.jpg'
import mobility01 from './img/course_mobility01.jpg'
import mobility02 from './img/course_mobility02.jpg'
import mobility03 from './img/course_mobility03.jpg'
import prevent01 from './img/course_prevent01.jpg'
import prevent02 from './img/course_prevent02.jpg'
import prevent03 from './img/course_prevent03.jpg'
import yoga01 from './img/course_yoga01.jpg'
import yoga02 from './img/course_yoga02.jpg'
import yoga03 from './img/course_yoga03.jpg'
import { Link } from 'react-router-dom';

const Wrap = styled.div`
    width: 100%;
`
const MainImgPC = styled.img`
    display: block;
    width: 100%;

    @media(max-width:768px){
		display:none;
	}
`

const MainImgMo = styled.img`
	display:none;
	width:100%;

	@media(max-width:768px){
		display:block;
	}
`

const Title1PC = styled.div`
    width:100%;
    text-align: center;
    font-size: 35px;
	@media(max-width:768px){
		text-align: center;
        font-size: 20px;
        margin-top: 10px;
        padding: 0;
	}
`

const Title2PC = styled.div`
    text-align: center;
    font-weight: bold;
    font-size: 50px;
    margin: 10px;
    padding: 0;
	@media(max-width:768px){
		text-align: center;
        font-weight: bold;
        font-size: 25px;
        margin: 0;
        padding: 0;
	}
`

const SubtitlePC = styled.div`
	text-align: center;
    font-size: 20px;
    display: block;
	@media(max-width:768px){
		display:none;
	}
`

const SubtitleMo = styled.div`
	display: none;

	@media(max-width:768px){
		display: block;
        text-align: center;
        width: 100%;
        padding: 0 2%;
        font-size: 14px;
	}
`

const CoursePC = styled.div`
    display: flex;
    justify-content: center;
    height: 70%;
    width: 100%;
    background: #EFEFEF;
    align-items: center;
    margin-top: 5%;
    margin-bottom: 5%;
	@media(max-width:768px){
		display:none;
	}
`

const CourseMo = styled.div`
	display: none;

	@media(max-width:768px){
		display: block;
        margin: 10px 0 30px;
        width: 100%;
	}
`

const BufsMain = () => {
    const [mobilityColor, setMobilityColor] = useState(false);
    const [preventColor, setPreventColor] = useState(false);
    const [yogaColor, setYogaColor] = useState(false);

    // const history = useHistory();
    return (
        <div>
            <Wrap>
                <div>
                    <MainImgPC src={Bufs630} />
                    <MainImgMo src={Bufs530} />
                </div>
                <Title1PC>
                    지역주민과 함께하는
                </Title1PC>
                <Title2PC>
                    행복학습! <span style={{ color: "#079fb4" }}>Life!</span> <span style={{ color: "#079fb4" }}>Up!</span>
                </Title2PC>

                <SubtitlePC>
                    국제화와 해외취업 분야에서 국내외의 주목을 끌어온 글로벌 중심 대학<br></br>
                    부산외국어대학교에서 평생교육 양성과정을 신청하세요.
                </SubtitlePC>

                <SubtitleMo>
                    국제화와 해외취업 분야에서 국내외의 주목을 끌어온 <br></br>글로벌 중심 대학<br></br>
                    부산외국어대학교에서 평생교육 양성과정을 신청하세요.
                </SubtitleMo>
                <CoursePC>
                    <div
                        onMouseOver={() => setMobilityColor(true)}
                        onMouseOut={() => setMobilityColor(false)}
                    >
                        <Link to="/bufs_mobility">
                            <img className="course_mobility" src={mobilityColor ? mobility02 : mobility01}
                                alt="mobility01"
                                style={{ height: "100%", width: "100%" }}
                            />
                        </Link>
                    </div>
                    <div
                        onMouseOver={() => setPreventColor(true)}
                        onMouseOut={() => setPreventColor(false)}
                    >

                        <Link to="/bufs_prevent">
                            <img className="course_prevent" src={preventColor ? prevent02 : prevent01}
                                alt="prevent01"
                                style={{ height: "100%", width: "100%" }}
                            />
                        </Link>
                    </div>
                    <div
                        onMouseOver={() => setYogaColor(true)}
                        onMouseOut={() => setYogaColor(false)}>
                        <Link to="/bufs_yoga">
                            <img className="course_yoga" src={yogaColor ? yoga02 : yoga01}
                                alt="yoga01"
                                style={{ height: "100%", width: "100%" }} />
                        </Link>
                    </div>
                </CoursePC>
                <CourseMo>
                    <Link to="/bufs_mobility">
                        <img className="course_mobility" src={mobility03}
                            style={{ width: "100%" }} />
                    </Link>
                    <Link to="/bufs_prevent">
                        <img className="course_prevent" src={prevent03}
                            style={{ width: "100%" }} />
                    </Link>
                    <Link to="/bufs_yoga">
                        <img className="course_yoga" src={yoga03}
                            style={{ width: "100%" }} />
                    </Link>
                </CourseMo>
            </Wrap>
        </div>
    )
}

export default BufsMain;