import React, { Component } from 'react';

import GroupPhoto from './Mentoring_group_photo';
import HowMentoring from './Mentoring_how';
import Process from './Mentoring_process';
import SixWeek from './Mentoring_six_week';
import Price from './Mentoring_price';
import Teacher from './Mentoring_teacher';
import SixTable from './Mentoring_table';
import Qualification from './Mentoring_qualification';
import Mento from './Mentoring_mento';

class index extends Component {
	render() {
		return (
			<div>
				<GroupPhoto/>
				<HowMentoring/>
				<Process/>
				<SixWeek/>
				<Price/>
				<Teacher/>
				<SixTable/>
				<Qualification/>
				<Mento/>
			</div>
		);
	}
}

export default index;