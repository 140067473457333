import React, { Component } from "react";

import url from "../../config";

import axios from "axios";

import styled from "styled-components";

import OutLookList from "./Outlook_list";

const OutLookWarpper = styled.section`
  position: relative;
  overflow: hidden;
  padding: 94px 0;

  @media (max-width: 720px) {
    padding: 72px 0;
  }
`;

const OutLookTit = styled.h1`
  padding: 0 0 91px 0;
  color: #575757;
  font-size: 64px;
  font-weight: 300;
  text-align: center;

  @media (max-width: 1280px) {
    font-size: 6.5vmax;
  }

  @media (max-width: 640px) {
    font-size: 5vmax;
  }
`;

const OutLookBlueTxt = styled.span`
  color: #1a6faf;
`;

const OutLookListBox = styled.div`
  display: flex;
  max-width: 1354px;
  flex-wrap: wrap;
  margin: 0 auto;

  @media (max-width: 640px) {
    padding: 0 15px;
  }
`;

class HomeOutlook extends Component {
  state = {
    karfeList: null,
  };

  componentDidMount() {
    axios
      .get(url.Api + "/rest/karfe/edu")
      .then((res) => {
        this.setState({ karfeList: res.data });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  render() {
    return (
      <OutLookWarpper>
        <OutLookTit>
          카르페 <OutLookBlueTxt>교육일정</OutLookBlueTxt>
        </OutLookTit>
        <OutLookListBox>
          {this.state.karfeList && Array.isArray(this.state.karfeList)
            ? this.state.karfeList.map((list, index) => {
                return (
                  <OutLookList
                    key={index}
                    OutLookImg={list.img}
                    OutLookTit={list.sfa5106}
                    OutLookCode={list.sfa5102}
                    OutLookStartDate={list.sfa5107}
                    OutLookEndData={list.sfa5108}
                    OutLookCourse={list.area}
                    OutLookAddres={list.sfa51177}
                    OutLookLoc={list.sfa5118}
                    OutLookLink={list.url}
                    OutLookState={list.state}
                  />
                );
              })
            : "리스트 불러오는중..."}
        </OutLookListBox>
      </OutLookWarpper>
    );
  }
}

export default HomeOutlook;
