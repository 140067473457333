import React from 'react';

import whatdo1 from './img/whatdo_01.jpg';
import whatdo2 from './img/whatdo_02.jpg';
import whatdo3 from './img/whatdo_03.jpg';
import whatdo4 from './img/whatdo_04.jpg';
import whatdo5 from './img/whatdo_05.jpg';
import whatdo6 from './img/whatdo_06.jpg';
import whatdo7 from './img/whatdo_07.jpg';
import whatdo8 from './img/whatdo_08.jpg';

import styled from 'styled-components';

const TodoContainer = styled.ul`
	display:flex;
	max-width: 1354px;
	flex-wrap: wrap;
    margin: 0 auto;
`

const TodoItems = styled.li`
	width: 24%;
	margin: 0 .5% 0 .5%;
	padding-bottom: 32px;
	box-sizing: border-box;
	
	@media (max-width: 1280px) {
		display:block;
		width:32.333%;
		margin: 0 .5%;
	}

	@media (max-width: 960px) {
		display:block;
		width:48%;
		margin: 0 1%;
	}

	@media(max-width:720px){
		padding-bottom:0;
	}

	&:after{
		content:"";
		display:block;
		clear:both;
	}
`

const TodoThums = styled.img`
	display:block;
	width:100%;
`

const TodoItemsTxt = styled.p`
	padding:17px 0;
	font-size:20px;
	font-weight: 700;
	line-height: 140%;

	@media(max-width:720px){
		font-size:14px;
	}

	@media(max-width:640px){
		font-size:2.5vw;
	}
`



const TodosList = () => {
	const data = [
		{txtFront: "야구선수들의 부상방지 및", txtBack:"재활훈련 연구", imgurl: whatdo1},
		{txtFront: "유소년부터 프로에 이르기까지 ", txtBack: "야구선수들의 성장발달에 관한 연구", imgurl: whatdo2},
		{txtFront: "야구의 역학과 생리학적 ", txtBack: "관점에서의 연구", imgurl: whatdo3},
		{txtFront: "포지션에 따른 ", txtBack: "선수관리 연구", imgurl: whatdo4},
		{txtFront: "코칭 및 티칭에 관한 ", txtBack: "코칭론 연구", imgurl: whatdo5},
		{txtFront: "야구선수들의 스트렝스 ", txtBack: "& 컨디셔닝에 관한 연구", imgurl: whatdo6},
		{txtFront: "야구와 관련된 ", txtBack: "해외사료 연구", imgurl: whatdo7},
		{txtFront: "전반적인 야구와 ", txtBack: "관련된 모든 연구", imgurl: whatdo8},
	]

	const listItems = data.map((list,idx) =>{
		return (
			<TodoItems key={idx}>
				<div>
					<TodoThums src={list.imgurl} alt={list.txtFront + list.txtBack}/>
				</div>
				<div>
					<TodoItemsTxt>{list.txtFront}<br/>{list.txtBack}</TodoItemsTxt>
				</div>
			</TodoItems>
		)
	})

	return (
		<TodoContainer>
			{listItems}
		</TodoContainer>
	)
}

export default TodosList;