import React, {Component} from 'react';

import styled from 'styled-components';

const IntroWarrper = styled.section`
	padding:100px 0;
	text-align:center;
`

const IntroTit = styled.h1`
	font-family:'Impact';
	font-size:70px;
	color: #3d3d3d;

	@media(max-width:960px){
		font-size:42px;
	}
`

const IntroTxt = styled.p`
	max-width: 720px;
	margin: 0 auto;
	padding: 0 20px;
	padding-top: ${props => props.top ? '50px' : '0px'};
	padding-bottom: 10px;
	color: #565656;
	font-size: 31.67px;
	font-weight: ${props=>props.lighter ? 100 : 700};
	letter-spacing: -4px;
	line-height: ${props => props.lineHeight ? '120%' : ''};

	@media(max-width:960px){
		font-size:24px;
	}
`

const Emdeb = styled.div`
	position: relative;
	width:100%;
	margin-top: 55px;
	padding-bottom: 19%;
	
	@media(max-width:1024px){
		padding-bottom: 50%;
	}
`

const EmdebIframe = styled.iframe`
	position: absolute;
    top: 0;
    left: 0;
	width: 100%;
	height: 100%;
`

class Intro extends Component {
	componentDidMount(){
		this.props.getOffset('intro',this.intro);
	}

	render() {
		return (
			<IntroWarrper 
				id="intro"
				ref={ref => {
					this.intro = ref;
				}}
			>
				<IntroTit>What is STC?</IntroTit>
				<IntroTxt lighter top>Strength Training &amp; Coodination 코스로서</IntroTxt>
				<IntroTxt lineHeight>
					운동조절 &amp; 운동역학의 세계적인 석학 프랜스보쉬의 컨셉으로 만든
					스트렝스 &amp; 코디네이션 트레이닝 코스
			</IntroTxt>
				<Emdeb>
					<EmdebIframe src="https://player.vimeo.com/video/338786529" frameborder="0" allowfullscreen="allowfullscreen"></EmdebIframe>
				</Emdeb>
			</IntroWarrper>
		)
	}
}

export default Intro;