import React, {Component} from 'react';

import axios from 'axios';

import url from '../../config';

import styled from 'styled-components';

import ClickThums from './img/click_ico.png';
import InfoRegistration from './img/infoRegistration_thums.jpg'

const CertificatesBox = styled.div`
	position:relative;
	background:#eeeeee;
`

const ConceptBox = styled.div`
	padding:60px 0 0 0;
`

const InfoConTit = styled.h2`
    text-align: center;
    font-size: 63px;
    color: #333;
	letter-spacing: -5px;
	padding:0 0 60px 0;
	line-height: 130%;

	&>span{
		font-family: 'Impact';
	}

	@media(max-width:960px){
		font-size: 40px;
		padding-bottom: 60px;
	}

`

const EnrollMentWrap = styled.div`
	width:100%;
	max-width:450px;
	margin:0 auto;
`

const EnrollMentTop = styled.div`
	width: 100%;
    max-width: 670px;
	margin: 0 auto;
	
	&>img{
		display:block;
		width:100%;
	}
`

const EnrollMentBody = styled.div`
	padding:50px 0;
`

const EnrollMentBodyTop = styled.div`
	padding:12px 0;
	text-align:center;
	background:#595959;
	color:#fff;
`

const EnrollMentMenu = styled.ul`
	width:85%;
	margin:44px auto;
`

const EnrollMentList = styled.li`
	position:relative;
	padding-left:8px;
	line-height:140%;

	&:after{
		content:"·";
		position:absolute;
		top:1px;
		left:0;
	}
`

const EnrollMentRed = styled.span`
	display:inline-block;
	color:#db191b;
`

const EnrollMentMoveLink = styled.a`
	position:relative;
	display: block;
    color: #fff;
    background: red;
    padding: 60px 0;
    text-align: center;
    font-size: 30px;
	font-weight: 700;
	
	&:after{
		content: "";
		position: absolute;
		top: -36px;
		right: 65px;
		width: 96px;
		height: 87px;
		background:url(${ClickThums}) 0 0 no-repeat;
	}
`


class Certificates extends Component {
	state = {
		InfoRegistration:null,
	}

	componentDidMount() {
		this.props.getOffset('enrollment', this.enrollment);

		axios.get(url.Api + '/rest/course/cfsc')
			.then((res) => {
				this.setState({InfoRegistration: res.data});
			})
			.catch((err) => {
				console.log(err);
			})
	}

	InfoRegistrationRender(){
		return this.state.InfoRegistration.map((list,idx)=>{

			return(
				<EnrollMentWrap key={idx}>
					<EnrollMentBody>
						<EnrollMentBodyTop>{list.title}</EnrollMentBodyTop>
						<EnrollMentMenu>
							<EnrollMentList>교육일정:{list.date2}</EnrollMentList>
							<EnrollMentList>참여인원:{list.member_limit}명(선착순)</EnrollMentList>
							<EnrollMentList>교육시간:{list.time}</EnrollMentList>
							<EnrollMentList>
								접수기간:{list.reg_start}~{list.reg_end}
							</EnrollMentList>
							<EnrollMentList>
								교육장소:{list.addr1}<br />
								({list.addr2})
							</EnrollMentList>
							<EnrollMentList>
								정규 교육비:{list.price}원 <span>{list.price_save}</span>
							</EnrollMentList>
							<EnrollMentList>
								등록방법:www.smartfitkorea.com
							</EnrollMentList>
							<EnrollMentList>
								<EnrollMentRed>{list.ask1}</EnrollMentRed><br />
							</EnrollMentList>
							<EnrollMentList>
								<EnrollMentRed>{list.ask2}</EnrollMentRed >
							</EnrollMentList>
						</EnrollMentMenu>
						{
							list.state === '1' || list.state === '2'
							?
							<div>
								<EnrollMentMoveLink href={list.url}>등록 바로가기</EnrollMentMoveLink>
							</div>
							: ""
						}
					</EnrollMentBody>
				</EnrollMentWrap>
			)
		})
	}

	render() {
		return (
			<CertificatesBox
				id="enrollment"
				ref={ref => {
					this.enrollment = ref;
				}}
			>
				<ConceptBox>
					<InfoConTit><span>CFSC</span> 등록안내</InfoConTit>
				</ConceptBox>
				<EnrollMentTop>
					<img src={InfoRegistration} />
				</EnrollMentTop>
				{this.state.InfoRegistration !== null ? this.InfoRegistrationRender() : '리스트 불러오는중....'}
			</CertificatesBox>
		)
	}
}

export default Certificates;