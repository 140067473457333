import React, { Component } from "react";

import AcademyEdu from "./Academy_edu";
import EduVideo from "./EduVideo";
import CertSlider from "./CertSlider";

class index extends Component {
  render() {
    return (
      <div>
        {/* <AcademyEdu /> */}
        <CertSlider />
        <EduVideo />
      </div>
    );
  }
}

export default index;
