import React from 'react';

import FooterLogo from './img/ft_logo.jpg';
import Smartfit from './img/smartfit.png';
import SmartMail from './img/smartmail.png';
import VibroGym from './img/vibrogym.png';
import Rad from './img/rad.png';

import styled from 'styled-components';

const FooterWarpper = styled.div`
	background:#000;
	color:#fff;
`

const FooterInner = styled.div`
	display: flex;

	&:after{
		content:"";
		display:block;
		clear:both;
	}
`

const FooterAddres = styled.div`
	float: left;
	width: 60%;
	padding:62px 5% 54px 15px;
	background: #000;
	box-sizing:border-box;

	@media (max-width:1280px){
		font-size:12px;
	}

	@media (max-width:960px){
		width:100%;
		float:none;
	}
`

const RghtItems = styled.div`
	float:right;

	@media (max-width:960px){
		float:none;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
	}
`

const FooterLogoBox = styled.span`
	float: left;
	width: 30%;

	@media (max-width:960px){
		display:block;
		float:none;
		width: 25%;
	}
`

const FooterLogoIco = styled.img`
	display:block;
	width:100%;

	@media (max-width:960px){
		max-width:170px;
	}
`

const FooterAddressTxt = styled.address`
	float: left;
	width: 70%;
    padding-left: 10%;
    line-height: 22px;
	box-sizing: border-box;
	font-size: 14px;
	letter-spacing: -1.2px;

	@media (max-width:1024px){
		font-size:11px;
	}
	
	@media (max-width:960px){
		float:none;
		width: auto;
		padding-left:8%;
		font-size:10px;
	}
`

const LftItems = styled.div`
	float:left;
`

const FooterFamaily = styled.div`
	float: left;
	width: 40%;
	padding:62px 15px 54px 60px;
	background: #252424;
	box-sizing:border-box;

	@media (max-width:960px){
		display:none;
	}
`

const FamailyTxt = styled.div`
	text-align:center;
	padding-bottom:15px;
`

const FamailyBox = styled.div`
	display:flex;
`

const FamailyItems = styled.div`
	width:25%;
	margin:0 5px;
`

const FamailyImages = styled.img`
	display:block;
	width:100%;
`

const Footer = () => {
	return (
		<FooterWarpper>
			<FooterInner>
				<FooterAddres>
					<RghtItems>
						<FooterLogoBox>
							<a href="/">
								<FooterLogoIco src={FooterLogo} />
							</a>
						</FooterLogoBox>
						<FooterAddressTxt>
							사단법인 대한기능재활운동협회<br />
							대표자 : 박일봉 | 개인정보 책임자 : 노근역<br />
							부산광역시 금정구 중앙대로 1841번길 91  마루빌딩 2층<br />
							사업자등록번호 : 420-82-00097<br />
							대표전화 : 051-583-9645<br />
							Copyright ＠2014-2017 KARFE all rights reserved.<br />
						</FooterAddressTxt>
					</RghtItems>
				</FooterAddres>
				<FooterFamaily>
					<LftItems>
						<FamailyTxt>패밀리사이트</FamailyTxt>
						<FamailyBox>
							<FamailyItems>
								<a href="http://www.smartfitkorea.com/" target="_blank" rel="noopener noreferrer">
									<FamailyImages src={Smartfit} />
								</a>
							</FamailyItems>
							<FamailyItems>
								<a href="http://smartmall.kr/" target="_blank" rel="noopener noreferrer">
									<FamailyImages src={SmartMail} />
								</a>
							</FamailyItems>
							{/* <FamailyItems>
								<a href="http://www.vibrogym.co.kr/" target="_blank" rel="noopener noreferrer">
									<FamailyImages src={VibroGym} />
								</a>
							</FamailyItems> */}
							<FamailyItems>
								<a href="http://www.radkorea.co.kr/" target="_blank" rel="noopener noreferrer">
									<FamailyImages src={Rad} />
								</a>
							</FamailyItems>
						</FamailyBox>
					</LftItems>
				</FooterFamaily>
			</FooterInner>
		</FooterWarpper>
	)
}

export default Footer;