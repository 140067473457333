import axios from "axios";
import React from "react";
import { useState, useEffect } from "react";
import moment from "moment";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Avatar } from "@material-ui/core";

export default function Index() {
  const [edus, setEdus] = useState();
  const router = useHistory();
  useEffect(() => {
    const getEdus = async () => {
      const result = await axios.post(
        "https://api.teamstc.org:3003/api/user/education/current"
      );
      setEdus(result.data.rows);
    };
    getEdus();
  }, []);

  return (
    <div className="min-h-[60vh] p-5 max-w-[1354px] mx-auto">
      {edus ? (
        <div className="grid grid-cols-2 md:grid-cols-6 gap-5">
          {edus.map((data, index) => (
            <button
              key={index}
              className="flex flex-col items-stretch hover:scale-105 transition-transform"
              onClick={() =>
                router.push(`/offline_education/${data.bid}/${data.id}`)
              }
            >
              <div className="w-full overflow-hidden rounded relative">
                <img
                  src={data.img}
                  alt={"ba_seq=" + data.ba_seq}
                  className={`w-full h-full object-cover object-bottom ${
                    data.state === "마감" ? "opacity-70 grayscale" : ""
                  }`}
                  loading="eager"
                />
                {data.state === "마감임박" && (
                  <div className="bg-red-400 text-white text-center text-sm absolute bottom-0 w-full left-0">
                    {data.state}
                  </div>
                )}
                {data.state === "마감" && (
                  <div className="bg-gray-500 text-white text-center absolute bottom-0 w-full left-0 h-full flex justify-center items-center font-bold bg-opacity-50 text-xl">
                    {data.state}
                  </div>
                )}
              </div>
              <div className="text-sm opacity-60 flex gap-1 items-center">
                <img
                  alt=""
                  src={require("./earth_black.svg")}
                  className="w-4"
                />
                {data.area}
              </div>
              <div className="text-sm opacity-60 flex gap-1 items-center">
                <img alt="" src={require("./calendar.svg")} className="w-4" />
                {data.sdate}
              </div>
              <div className="flex items-center gap-2 my-1">
                <Avatar
                  src={`https://api.teamstc.org:3003/api/img/trainerinfo/${data.tcd}.jpg`}
                  className="!w-[30px] !h-[30px]"
                />
                <p className="opacity-70 text-left break-all">{data.tname}</p>
              </div>
              {data.early.end && (
                <div>
                  <div className="text-sm text-center bg-emerald-500 text-white">
                    <p className="text-xs break-keep break-words">
                      {moment(data.early.end).format("YYYY년MM월DD일")} 까지
                    </p>
                    <p className="font-bold text-base">조기등록할인</p>
                  </div>
                  <p className="opacity-50 line-through text-sm text-right leading-tight">
                    {data.amount.toLocaleString()} 원
                  </p>
                </div>
              )}
              <p className="font-bold text-right">
                {(data.amount - data.early.discount).toLocaleString()} 원
              </p>
            </button>
          ))}
        </div>
      ) : (
        <div className="w-full min-h-[60vh] flex justify-center items-center">
          현장교육을 불러오는 중 입니다.
        </div>
      )}
    </div>
  );
}

// export default class index extends Component {
//   render() {
//     return (
//       <div>
//         asdsa
//       </div>
//     );
//   }
// }
