import React from 'react';

import TodosList from './Todos_list';

import styled from 'styled-components';

const TodosWarrper = styled.section`
	position:relative;
	padding:170px 0 130px 0;
	text-align:center;

	@media (max-width: 960px) {
		padding:0;
	}
`

const QuestionTit = styled.h1`
	padding-bottom: 127px;

	@media(max-width:960px){
		padding-bottom:60px;
	}
`

const QuestionTopTxt = styled.div`
	padding: 23px 9% 30px 9%;
	font-size:64px;
	font-weight:500;
	color:#777;
	letter-spacing: -6.4px;

	@media (max-width: 960px) {
		padding: 60px 15px 30px 15px;
		font-size: 43px;
	}
`

const QuestionTopSpan = styled.span`
	position: relative;
    top: 5px;
	font-family: 'Arial';
	font-size: 75px;
    font-weight: 900;
	letter-spacing: -4.8px;

	@media (max-width: 960px) {
		font-size: 50px;
	}
`

const KbsiTodos = () => {
	return (
		<TodosWarrper>
			<QuestionTopTxt>
				<QuestionTit>
					<QuestionTopSpan>KBSI</QuestionTopSpan> 가 하는일
				</QuestionTit>
				<TodosList/>
			</QuestionTopTxt>
		</TodosWarrper>
	)
}

export default KbsiTodos;