import React from 'react';

import KbsiBannerBg from './img/kbsi_banner_bg.jpg';
import MobileKbsiBannerBg from './img/m_kbsi_banner_bg.jpg';

import styled from 'styled-components';

const KbsiBannerWarrper = styled.section`
	position:relative;
	top: 0;
    left: 0;
    width: 100%;
    height: 0;
    margin: 0 auto;
	padding-bottom: 35.5%;
	
	@media (max-width:720px){
		height:320px;
		padding-bottom:0;
	}
`

const KbsiBannerBox = styled.div`
	position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
	background:url(${KbsiBannerBg}) 0 0 no-repeat;
	background-size:100%;

	@media (max-width:720px){
		background:url(${MobileKbsiBannerBg}) 0 0 no-repeat;
	}
`

const KbsiBannerTxt = styled.p`
	position:absolute;
	top:65%;
	right:10%;
	font-size:24px;
	color:#fff;
	opacity: .7;

	@media (max-width:960px){
		top:51%;
		font-size: 18px;
	}

	@media (max-width:720px){
		font-size: 12px;
	}
`


const KbsiBanner = () =>{
	return(
		<KbsiBannerWarrper>
			<KbsiBannerBox></KbsiBannerBox>
			<KbsiBannerTxt>Korea Baseball strength & conditioning Institution </KbsiBannerTxt>
		</KbsiBannerWarrper>
	)
}

export default KbsiBanner;