import React from 'react';

import ProcessImg from './img/process_thums.jpg';
import MoProcessImg from './img/m_process_thums.jpg';

import styled from 'styled-components';

const ProcessThums = styled.img`
	display:block;
	width:100%;

	@media(max-width:960px){
		display:none;
	}
`

const MoProcessThums = styled.img`
	display:none;

	@media(max-width:960px){
		display:block;
	width:100%;
	}
`
const Process = () => {
	return(
		<div>
			<ProcessThums src={ProcessImg} alt="멘토링과정"/>
			<MoProcessThums src={MoProcessImg} alt="멘토링과정"/>
		</div>
	)
}

export default Process;