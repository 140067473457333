import React, { Component } from 'react';

import PermitImg from './img/home_permit_bg.jpg';
import PermitImage from './img/karfe_cert_big.jpg';

import body from './img/body_ico.jpg';
import functional from './img/functional_ico.jpg';
import posture from './img/posture_ico.jpg';

import PermitModal from './Permit_modal';

import styled from 'styled-components';


const PermitWrap = styled.div`
	padding:77px 0 67px 0;
	background-color:#f4f4f4;
	
	@media (max-width:960px){
		padding: 60px 0 33px 0;
	}
`

const PermitInner = styled.div`
	display: flex;
    flex-direction: row;
	width:100%;
	max-width:970px;
	margin:0 auto;

	&:after{
		content:"";
		clear:both;
		display:block;
	}

	@media (max-width: 1024px) {
		width:95%;
		flex-direction: column;
    	align-items: center;
	}
`

const PermitLeft = styled.img`
	max-width:237px;
	cursor:pointer;

	@media (max-width: 1024px) {
		display:block;
		float:none;
		max-width:237px;
		margin:0 auto;
	}
`

const PermitRight = styled.div`
	display: flex;
	flex:1 1 auto;
	flex-direction: column;
	justify-content: space-around;
    padding: 20px 0 35px 120px;
	
	@media (max-width: 1024px) {
		display:block;
		float:none;
		margin:0 auto;
		width:100%;
		max-width:608px;
		padding: 20px 0;
	}
`

const PermitItems = styled.div`
	display:flex;
	flex-direction: row;
	align-items: center;

	@media(max-width:1100px){
		padding-bottom: 10px;
	}

	@media(max-width:480px){
		flex-direction:column;
	}
`

const PermitConBox = styled.div`
	padding-left: 35px;

	@media(max-width:480px){
		padding:10px 0;
		text-align: center;
	}
`

const PermitConTxt = styled.p`
	font-size: 35px;
    font-weight: 700;
	color: #00aeef;

	@media(max-width:640px){
		font-size:25px;
	}
	
	&>span{
		font-size:20px;
		padding-left: 6px;

		@media(max-width:640px){
			font-size:14px;
		}
	}
`

const PermitConSubTxt = styled.p`
	padding-top: 10px;
	padding-left: 8px;
    color: #565656;
	font-size: 17px;
	
	@media(max-width:640px){
		font-size:14px;
	}
`

class HomePermit extends Component {
	state = {
		open: false,
	};

	onOpenModal = () => {
		this.setState({open: true});
	};

	onCloseModal = () => {
		this.setState({open: false});
	};
	
	render() {
		
		return (
			<PermitWrap>
				<PermitInner>
					<PermitLeft src={PermitImg} onClick={this.onOpenModal}/>
					<PermitRight>
						<PermitItems>
							<img src={body} alt="Control body"/>
							<PermitConBox>
								<PermitConTxt>Control body<span>컨트롤 바디</span></PermitConTxt>
								<PermitConSubTxt>자신의 몸을 마음껏 조절할 수 있는 능력을 만든다.</PermitConSubTxt>
							</PermitConBox>
						</PermitItems>
						<PermitItems>
							<img src={functional} alt="Functional Movement"/>
							<PermitConBox>
								<PermitConTxt>Functional Movement<span>기능적 움직임</span></PermitConTxt>
								<PermitConSubTxt>기능적 움직임을 통해 운동수행능력을 향상시킨다.</PermitConSubTxt>
							</PermitConBox>
						</PermitItems>
						<PermitItems>
							<img src={posture} alt="Good Posture"/>
							<PermitConBox>
								<PermitConTxt>Good Posture<span>바른자세</span></PermitConTxt>
								<PermitConSubTxt>좋은 자세가 좋은 기능과 움직임을 만들어 줍니다.</PermitConSubTxt>
							</PermitConBox>
						</PermitItems>
					</PermitRight>
				</PermitInner>
				{this.state.open ? <PermitModal open={this.state.open} onCloseModal={this.onCloseModal} PermitImage={PermitImage}/> : ''}
			</PermitWrap>
		);
	}
}

export default HomePermit;