import { ReactComponent as Star } from "./star.svg";
import React from "react";

export default function StarRating({ rating = 0, size = 20, onClick }) {
  return (
    <span className="relative inline-block flex-none overflow-hidden" onClick={onClick}>
      <div className="flex text-zinc-400">        
        {new Array(5).fill().map((_, index) => (
          <Star key={index} width={size} height={size} />
        ))}
      </div>
      <div
        className="flex text-orange-400 absolute top-0 left-0 overflow-hidden"
        style={{ width: rating * 20 + "%" }}
      >
        {new Array(5).fill().map((_, index) => (
          <Star key={index} width={size} height={size} className="shrink-0" />
        ))}
      </div>
    </span>
  );
}
