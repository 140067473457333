import React, {Component} from 'react';

import axios from 'axios';

import url from '../../config';

import OutLookImg from './img/stc_peoples.jpg';

import ClickThums from './img/click_ico.png';

import styled from 'styled-components';

const OutLookBox = styled.section`
	padding:70px 0 70px 0;
`

const OutLookTit = styled.h2`
	padding-bottom:46px;
	font-size:70px;
	color:#3d3d3d;
	text-align:center;

	@media(max-width:960px){
		font-size:42px;
	}
`

const OutLookTitSpan = styled.span`
	position: relative;
    top: 5px;
	font-family:'Impact';
`

const OutLookThums = styled.img`
	display: block;
    width: 100%;
    max-width: 670px;
	margin: 0 auto;
	box-sizing: border-box;

	@media(max-width:960px){
		padding:0 5%;
	}
`

const OutLookMenuBox = styled.div`
	width:100%;
	max-width:950px;
	margin:0 auto;
	padding-top: 40px;
`

const OutLookMenu = styled.ul`
	display:flex;
	flex-direction: row;
	color: #c4d8ff;
	font-size:24px;
	margin-bottom: 1px;

	@media(max-width:720px){
		font-size: 16px;
	}
`

const OutLookLft = styled.li`
	width: 40%;
	padding: 20px 50px 20px 0;
	text-align: right;
	background: #0c234f;

	@media(max-width:720px){
		width:30%;
	}
`

const OutLookRght = styled.li`
	display: flex;
	padding: 20px 0;
    flex-direction: row;
	flex: 1 1 auto;
	background: #0c234f;
`

const OutLookSpan = styled.span`
	color:#fff;
`

const OutLookInfoTxt = styled.p`
	text-align: center;
    margin-top: 20px;
`

const EnrollMentWrap = styled.div`
	width:100%;
	max-width:450px;
	margin:0 auto;
`

const EnrollMentTop = styled.div`
	width: 100%;
    max-width: 670px;
	margin: 0 auto;
	
	&>img{
		display:block;
		width:100%;
	}
`

const EnrollMentBody = styled.div`
	padding:50px 0;
`

const EnrollMentBodyTop = styled.div`
	padding:12px 0;
	text-align:center;
	background:#595959;
	color:#fff;
`

const EnrollMentMenu = styled.ul`
	width:85%;
	margin:44px auto;
`

const EnrollMentList = styled.li`
	position:relative;
	padding-left:8px;
	line-height:140%;

	&:after{
		content:"·";
		position:absolute;
		top:1px;
		left:0;
	}
`

const EnrollMentRed = styled.span`
	display:inline-block;
	color:#db191b;
`

const EnrollMentRedExtends = styled(EnrollMentRed)`
	padding-left:32px;
`

const EnrollMentMoveLink = styled.a`
	position:relative;
	display: block;
    color: #fff;
    background: red;
    padding: 60px 0;
    text-align: center;
    font-size: 30px;
	font-weight: 700;
	
	&:after{
		content: "";
		position: absolute;
		top: -36px;
		right: 65px;
		width: 96px;
		height: 87px;
		background:url(${ClickThums}) 0 0 no-repeat;
	}
`

class OutLook extends Component {
	state = {
		InfoRegistration: null,
	}

	componentDidMount() {
		this.props.getOffset('outlook', this.outlook);

		axios.get(url.Api + '/rest/course/stc')
			.then((res) => {
				this.setState({InfoRegistration: res.data});
			})
			.catch((err) => {
				console.log(err);
			})
	}

	InfoRegistrationRender() {
		return this.state.InfoRegistration.map((list, idx) => {
			
			return (
				<EnrollMentWrap key={idx}>
					<EnrollMentBody>
						<EnrollMentBodyTop>{list.title}</EnrollMentBodyTop>
						<EnrollMentMenu>
							<EnrollMentList>교육일정:{list.date2}</EnrollMentList>
							<EnrollMentList>참여인원:{list.member_limit}명(선착순)</EnrollMentList>
							<EnrollMentList>교육시간:{list.time}</EnrollMentList>
							<EnrollMentList>
								정규 교육비:{list.price}원 <span>{list.price_save}</span>
							</EnrollMentList>
							<EnrollMentList>
								교육장소:{list.addr1}<br />
								({list.addr2})
							</EnrollMentList>
							<EnrollMentList>
								등록방법:www.smartfitkorea.com
							</EnrollMentList>
							<EnrollMentList>
								<EnrollMentRed>{list.ask1}</EnrollMentRed><br />
							</EnrollMentList>
							<EnrollMentList>
								<EnrollMentRed>{list.ask2}</EnrollMentRed >
							</EnrollMentList>
						</EnrollMentMenu>
						{
							list.state == 1 || list.state == 2
								?
								<div>
									<EnrollMentMoveLink href={list.url}>등록 바로가기</EnrollMentMoveLink>
								</div>
								: ""
						}
					</EnrollMentBody>
				</EnrollMentWrap>
			)
		})
	}

	render() {
		return (
			<OutLookBox 
				id="outlook"
				ref={ref => {
					this.outlook = ref;
				}}
			>
				<OutLookTit>
					<OutLookTitSpan>2019 STC</OutLookTitSpan>
					과정 일정
				</OutLookTit>
				<OutLookThums src={OutLookImg} alt="단체사진" />
				{/*<OutLookMenuBox>
					<OutLookMenu>
						<OutLookLft>
							STC베이직
					</OutLookLft>
						<OutLookRght>
							<div>
								서울 <OutLookSpan>8/24, 10/13</OutLookSpan>
							</div>
							<div>
								부산 <OutLookSpan>8/31, 10/12</OutLookSpan>
							</div>
						</OutLookRght>
					</OutLookMenu>

					<OutLookMenu>
						<OutLookLft>
							STC어드밴스
					</OutLookLft>
						<OutLookRght>
							<div>
								서울 <OutLookSpan>7/14, 9/22</OutLookSpan>
							</div>
							<div>
								부산 <OutLookSpan>7/21, 9/29</OutLookSpan>
							</div>
						</OutLookRght>
					</OutLookMenu>

					<OutLookMenu>
						<OutLookLft>
							STC리햅
					</OutLookLft>
						<OutLookRght>
							<div>
								서울 <OutLookSpan>7/14, 9/22</OutLookSpan>
							</div>
							<div>
								부산 <OutLookSpan>7/21, 9/29</OutLookSpan>
							</div>
						</OutLookRght>
					</OutLookMenu>
					<OutLookMenu>
						<OutLookLft>
							STC마스터
					</OutLookLft>
						<OutLookRght>
							<div>
								창원 <OutLookSpan>11/28,11/29,11/30,12/1</OutLookSpan>
							</div>
						</OutLookRght>
					</OutLookMenu>
				</OutLookMenuBox>
				<OutLookInfoTxt>* 교육일정이 추가 될 수 있으니 참고바랍니다.</OutLookInfoTxt>
				*/}
				{this.state.InfoRegistration !== null ? this.InfoRegistrationRender() : '리스트 불러오는중....'}
			</OutLookBox>
		)
	}
}

export default OutLook;