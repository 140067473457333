import React, { Component } from "react";
import { Link } from "react-router-dom";

import styled from "styled-components";

import logo from "./img/karfe_logo.jpg";
import cfsc from "./img/top_cfsc.png";
import dns from "./img/top_dns.png";
import stc from "./img/top_stc.jpg";

const TopHeader = styled.header`
  display: block;
  background: #fff;
`;

const NavMenu = styled.nav`
  position: relative;
  display: flex;
  width: 85%;
  height: 136px;
  margin: 0 auto;
  justify-content: space-between;
  align-items: center;
  background: #fff;

  @media (max-width: 1024px) {
    width: 95%;
    height: 72px;
  }
`;

const NavList = styled.li`
  text-align: center;
  @media (max-width: 960px) {
    display: none;
  }
`;

const NavLogo = styled(NavList)`
  margin-left: 0;
  margin-right: 3%;

  @media (max-width: 1024px) {
    font-size: 16px;
  }

  @media (max-width: 960px) {
    display: block;
    margin: 0 auto;
  }
`;

const LogoImg = styled.img`
  width: 15vh;

  @media (max-width: 1024px) {
    width: 10vh;
  }

  @media (max-width: 960px) {
    width: 10vh;
  }
`;

const LinkImg = styled.img`
  width: 6vh;
`;

const MenuLogoLink = styled.span`
  position: relative;
  display: block;
  padding: 0 5px;
  font-size: 20px;
  color: #575757;

  @media (max-width: 1150px) {
    font-size: 16px;
  }
`;

const MenuLink = styled(MenuLogoLink)`
  &:hover:after {
    content: "";
    display: block;
    position: absolute;
    bottom: -10px;
    left: 0;
    width: 100%;
    height: 2px;
    background: #00aeef;
  }
  .bufs {
    font-size: 17px;
  }
`;

const HambugerBox = styled.div`
  position: absolute;
  left: 0;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 24px;
  height: 18px;
  cursor: pointer;
  z-index: 5;
`;

const HambugerLine = styled.div`
  display: block;

  @media (max-width: 960px) {
    display: block;
    width: 100%;
    height: 2px;
    background: #a9a9a9;
  }
`;

const MobileToggle = styled.div`
  position: relative;
  top: -500px;
  visibility: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 0;
  transition: all 0.4s ease-in 0s;
  opacity: 0;
  z-index: -9999;

  @media (max-width: 960px) {
    &.on {
      position: relative;
      top: 0;
      visibility: visible;
      height: 400px;
      background: #fff;
      z-index: 0;
      opacity: 1;
    }
  }
`;

const MobileLink = styled.li``;

class Header extends Component {
  render() {
    return (
      <>
        <TopHeader>
          <NavMenu>
            <NavLogo>
              <Link to="/">
                <MenuLogoLink title="Home">
                  <h1>
                    <LogoImg src={logo} />
                  </h1>
                </MenuLogoLink>
              </Link>
            </NavLogo>
            <NavList>
              <Link to="/karfe">
                <MenuLink title="karfe">KARFE 소개</MenuLink>
              </Link>
            </NavList>
            <NavList>
              <a href="http://www.cfsckorea.com/" target="_blank">
                <MenuLink title="CFSC">
                  <LinkImg style={{ width: "7.4vh" }} src={cfsc} />
                </MenuLink>
              </a>
            </NavList>
            <NavList>
              <a href="http://www.dnskorea.org/" target="_blank">
                <MenuLink title="DNS">
                  <LinkImg style={{ width: "9vh" }} src={dns} />
                </MenuLink>
              </a>
            </NavList>
            <NavList>
              <a href="http://www.teamstc.org" target="_blank">
                <MenuLink title="Stc">
                  <LinkImg src={stc} />
                </MenuLink>
              </a>
            </NavList>
            <NavList>
              <Link to="/offline_education">
                <MenuLink title="offline_education">현장교육</MenuLink>
              </Link>
            </NavList>
            <NavList>
              <Link to="/online_education">
                <MenuLink title="online_education">인터넷교육</MenuLink>
              </Link>
            </NavList>
            <NavList>
              <Link to="/academy/02home">
                <MenuLink title="Academy">교육소개</MenuLink>
              </Link>
            </NavList>
            <NavList>
              <Link to="/instructor_home">
                <MenuLink title="Instructor">강사소개</MenuLink>
              </Link>
            </NavList>
            <NavList>
              <Link to="/appintoduce">
                <MenuLink title="appintoduce">KARFE 앱 소개</MenuLink>
              </Link>
            </NavList>
            {/* <NavList><Link to="/kbsi"><MenuLink title="KBSI">KBSI</MenuLink></Link></NavList> */}
            {/* <NavList>
              <Link to="/bufs">
                <MenuLink title="bufs">
                  <div className="bufs">
                    부산외국어대학교<br></br>평생교육원양성교육과정안내
                  </div>
                </MenuLink>
              </Link>
            </NavList> */}
            {/* <NavList>
              <Link to="/master_trainer">
                <MenuLink title="Master Trainer">Master Trainer</MenuLink>
              </Link>
            </NavList> */}
            {/* <NavList>
              <Link to="/mentoringschool">
                <MenuLink title="Mentoring School">Mentoring School</MenuLink>
              </Link>
            </NavList> */}
            <Hambuger />
          </NavMenu>
        </TopHeader>

        <MobileToggle
          className="mob_toggle"
          onClick={(e) => {
            const ToggleBox = document.querySelector(".mob_toggle");
            ToggleBox.classList.remove("on");
          }}
        >
          <MobileLink>
            <Link to="/karfe">
              <MenuLink title="karfe">KARFE 소개</MenuLink>
            </Link>
          </MobileLink>
          <MobileLink>
            <a href="http://www.cfsckorea.com/" target="_blank">
              <MenuLink title="cfsc">
                <LinkImg src={cfsc} />
              </MenuLink>
            </a>
          </MobileLink>
          <MobileLink>
            <a href="http://www.dnskorea.org/" target="_blank">
              <LinkImg src={dns} />
            </a>
          </MobileLink>
          <MobileLink>
            <a href="http://www.teamstc.org" target="_blank">
              <LinkImg src={stc} />
            </a>
          </MobileLink>
          <MobileLink>
            <Link to="/offline_education">
              <MenuLink title="offline_education">현장교육</MenuLink>
            </Link>
          </MobileLink>
          <MobileLink>
            <Link to="/online_education">
              <MenuLink title="online_education">인터넷교육</MenuLink>
            </Link>
          </MobileLink>
          <MobileLink>
            <Link to="/academy/02home">
              <MenuLink title="Academy">교육소개</MenuLink>
            </Link>
          </MobileLink>
          <MobileLink>
            <Link to="/instructor_home">
              <MenuLink title="Instructor">강사소개</MenuLink>
            </Link>
          </MobileLink>
          <MobileLink>
            <Link to="/appintoduce">
              <MenuLink title="appintoduce">KARFE 앱 소개</MenuLink>
            </Link>
          </MobileLink>
          {/* <MobileLink><Link to="/kbsi"><MenuLink title="KBSI">KBSI</MenuLink></Link></MobilLink> */}
          {/* <MobileLink>
            <Link to="/bufs">
              <MenuLink title="bufs">
                <div className="bufs">
                  부산외국어대학교<br></br>평생교육원<br></br>양성교육과정안내
                </div>
              </MenuLink>
            </Link>
          </MobileLink>
          <MobileLink>
            <Link to="/instructor_home">
              <MenuLink title="Instructor">Instructor</MenuLink>
            </Link>
          </MobileLink>
          <MobileLink>
            <Link to="/master_trainer">
              <MenuLink title="Master Trainer">Master Trainer</MenuLink>
            </Link>
          </MobileLink>
          <MobileLink>
            <Link to="/mentoringschool">
              <MenuLink title="Mentoring School">Mentoring School</MenuLink>
            </Link>
          </MobileLink> */}
        </MobileToggle>
      </>
    );
  }
}

const Hambuger = () => {
  const Toggle = (e) => {
    const MobToggle = document.querySelector(".mob_toggle");

    MobToggle.classList.toggle("on");
  };
  return (
    <HambugerBox onClick={Toggle}>
      <HambugerLine></HambugerLine>
      <HambugerLine></HambugerLine>
      <HambugerLine></HambugerLine>
    </HambugerBox>
  );
};

export default Header;
