import React, {Component} from 'react';

import styled from 'styled-components';

import ReccordImg from './img/stc_record.jpg';
import MoReccordImg from './img/m_stc_record.jpg';

const RecordBox = styled.div`
	position:relative;
	height:1878px;
	background:url(${ReccordImg}) 50% 0 no-repeat;
	background-size:cover;

	@media(max-width:720px){
		height:717px;
		background:url(${MoReccordImg}) 50% 0 no-repeat;
		background-size:cover;
	}
`

const RecordTxtBox = styled.div`
	position: absolute;
    top: 204px;
    right: 10%;
	color: #fff;
	
	@media(max-width:720px){
		top:30px;
		right:20px;
	}
`

const RecordTopTxt = styled.p`
	font-size:46px;
	font-weight:900;
	letter-spacing:-3px;

	@media(max-width:720px){
		font-size:24px;
	}
`

const RecordMidTxt = styled.p`
	padding:40px 0;
	line-height:150%;
	font-size:22px;
	font-weight:700;

	@media(max-width:720px){
		padding:15px 0;
		font-size:12px;
	}
`

const RecordBtmTxt = styled.ul`
	font-size:22px;
	font-weight:300;
	line-height:150%;

	@media(max-width:720px){
		font-size:12px;
	}
`

const RecordBtmTxtItems = styled.li`
	position:relative;
	padding-left:10px;

	&:after{
		content:"·";
		position:absolute;
		top:1px;
		left:0;
	}
`

class Record extends Component {

	render(){
		return(
			<RecordBox
				id="record"
			>
				<RecordTxtBox>
					<RecordTopTxt>한국 유일한 마스터 강사 박일봉 교수</RecordTopTxt>
					<RecordMidTxt>
						이론은 인터넷 강의로! <br/>
						Basic 코스의 기초이론을 모두 인터넷 강의로 청강할 수 있습니다. <br />
						현장교육은 실기적용 중심으로!<br />
						현장강의는 모두 실기중심으로 몸으로 쉽게 익히고, <br />
						실제 현장에서의 적용방법을 몸으로 익힐 수 있습니다
					</RecordMidTxt>
					<RecordBtmTxt>
						<RecordBtmTxtItems>동아대학교 체육대학 사회체육학과 졸업</RecordBtmTxtItems>
						<RecordBtmTxtItems>동아대학교 대학원 체육학전공 석사학위 이수</RecordBtmTxtItems>
						<RecordBtmTxtItems>동아대학교 대학원 체육학전공 이학박사 학위 이수</RecordBtmTxtItems>
						<RecordBtmTxtItems>부산가톨릭대학교 물리치료학과 졸업</RecordBtmTxtItems>
						<RecordBtmTxtItems>부산대학교 의학전문대학원 의학석사학위 이수</RecordBtmTxtItems>
						<RecordBtmTxtItems>부산대학교 의학전문대학원 의학박사 학위 이수</RecordBtmTxtItems>
						<RecordBtmTxtItems>부산대학교 경영대학원 경영컨설팅학 박사 학위 이수</RecordBtmTxtItems>
						<RecordBtmTxtItems>현 부산 외국어대학교 대학원 스포츠재활학과 주임교수</RecordBtmTxtItems>
						<RecordBtmTxtItems>현 (주)스마트핏 대표이사</RecordBtmTxtItems>
						<RecordBtmTxtItems>현 (주)스마트핏 운동센터 대표</RecordBtmTxtItems>
						<RecordBtmTxtItems>현 (사)대한재활기능운동협회(KARFE) 협회장</RecordBtmTxtItems>
					</RecordBtmTxt>
				</RecordTxtBox>
			</RecordBox>
		)
	}
}

export default Record;