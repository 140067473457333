import React from 'react';

import styled from 'styled-components';

import GreetingsPerson from './img/greetings_person_thums.png';
import GreetingsHeadImg from './img/greetings_head_txt.png';
import GreetingsFtImg from './img/greetings_ft_txt.png';

const GreetingsWarrper = styled.section`
	position: relative;
    top: 0;
    left: 0;
    height: 1284px;
	background:url(${GreetingsPerson}) right bottom no-repeat;

	@media (max-width:1480px){
		background-size:70%;
	}

	@media (max-width:720px){
		height:820px;
	}

	@media (max-width:640px){
		height:1000px;
		background-size:80%;
	}
`

const GreetingsContBox = styled.div`
	position: absolute;
	top: 12.5%;
	left: 9.5%;
	right: 46%;
	bottom: 0;
	z-index:5;


	@media (max-width:1280px) {
		top: 6.5%;
		right:20%;
	}

	@media (max-width:760px) {
		right:9.5%;
	}
`

const GreetingBodyTxt = styled.p`
	font-size: 1vw;
	color:#565656;
	font-weight: 300;
	line-height: 140%;
	padding-bottom: 5%;

	@media (max-width:1600px) {
		font-size:1.4vw;
	}

	@media (max-width:1024px) {
		font-size:14px
	}
`

const GreetingBoldTxt = styled(GreetingBodyTxt)`
	font-weight:700;
`

const GreetingBodyBr = styled.br`
	display:block;
	@media (max-width:640px){
		display:none;
	}
`

const GreetingSignBox = styled.div`
	&:after{
		content:"";
		display:block;
		clear:both;
	}
`

const GreetingSign = styled.div`
	display:flex;
	float:right;
	min-width:200px;

	@media (max-width:1480px){
		float:left;
	}
`

const GreetingInfoBox = styled.div`
	padding-top: 21%;
`

const GreetingInfoTxt = styled.p`
	line-height: 130%;

	@media (max-width:1600px) {
		font-size:1.3vw;
	}

	@media (max-width:1024px) {
		font-size:13px
	}
`

const GreetingInfoTopTxt = styled(GreetingInfoTxt)`
	padding-bottom:1%;
	font-weight:900;
`

const GreetingConBox = styled.div`
	padding-bottom: 11%;
`

const GreetingTopTxt = styled.p`
	padding-left: .5vw;
	font-size: .95vw;

	@media(max-width:1280px){
		font-size:14px;
	}
`

const GreetingMidTxt = styled.p`
	padding-left: .5vw;
	font-size: .65vw;

	@media(max-width:1280px){
		padding-top: 5px;
		font-size:12px;
	}
`

const GreetingBtmTxt = styled.p`
	font-family: serif;
	font-size: 3.6vw;
	padding-top: 1.3vw;
	color: #444;
	
	@media(max-width:1280px){
		font-size: 20px;
	}
`

const GreetingSignTxt = styled.span`
	margin: 0 10px;
    padding-top: 1.3vw;
	color: #444;
	font-family: serif;
	font-size: 30px;

	@media(max-width:960px){
		font-size:20px;
	}
`

const GreetingSignIcon = styled.img`
	flex: 1 1 auto;
	@media(max-width:960px){
		flex:initial;
		width: 10vw;
	}

`

const GreetingsSignGray = styled(GreetingSignTxt)`
	color:#bfbfbf;
`

const KbsiGreetings = () =>{
	return(
		<GreetingsWarrper>
			<GreetingsContBox>
				<GreetingConBox>
					<GreetingTopTxt>한국야구스ㅡ렝스&amp;컨디셔닝 연구소</GreetingTopTxt>
					<GreetingMidTxt>Korea Baseball strength &amp; conditioning Institution</GreetingMidTxt>
					<GreetingBtmTxt>대표 인사말</GreetingBtmTxt>
				</GreetingConBox>
				<GreetingBodyTxt>
					한국에서의 야구는 가장 인기있는 스포츠 중 하나이다. 야구는 타 스포츠와는 특별히 기술적인 <GreetingBodyBr/>
					요소가 중요한 종목이며, 또한 그 기술에 따른 전문적인 체력육성도 중요하다. <GreetingBodyBr />
					수많은 스포츠 중 야구 트레이닝은 그 특수성과 개별성이 더욱 요구된다. <GreetingBodyBr />
					최근 한국에서도 대학에서 야구학과가 생겨날 정도로 야구는 이제 명실공히 스포츠과학을 기반으로 <GreetingBodyBr />
					그 연구활동과 전문성을  가지고 선수관리 및 경기운영 관리가 필요할 것으로 판단된다.
				</GreetingBodyTxt>
				<GreetingBoldTxt>
					본 연구소는 비영리단체로서 야구선수들을 대상으로 체력강화와 컨디셔닝을 물론, 재활관리를<GreetingBodyBr/>
					근거기반중심의 현장적용 방법들을 서로 공유하고 연구하기 위해 설립되었다.
				</GreetingBoldTxt>
				<GreetingBodyTxt>
					아무쪼록 현장에서 선수들을 위해 땀흘리는 트레이너 및 코치, 감독에게 좋은 정보를 나누고, <GreetingBodyBr/>
					또한 함께 고민하고 격려하는 연구소가 되기를 진심으로 바라며..  
				</GreetingBodyTxt>
				<GreetingSignBox>
					<GreetingSign>
						<GreetingsSignGray>연구소장</GreetingsSignGray>
						<GreetingSignTxt>박일봉</GreetingSignTxt>
						<GreetingSignIcon src={GreetingsFtImg} />
					</GreetingSign>
				</GreetingSignBox>
				<GreetingInfoBox>
					<GreetingInfoTopTxt>연구소장 박일봉 교수</GreetingInfoTopTxt>
					<GreetingInfoTxt>
						현, (사)대한기능재활운동협회 KARFE 대표<br/>
						현, (주)스마트핏 대표이사 <br />
						현, 부산외국어대학교 대학원 스포츠재활학과 주임교수 <br />
						이학박사, 의학박사, 경영컨설팅학 박사 과정 이수 <br />
						물리치료사, 건강운동관리사
					</GreetingInfoTxt>
				</GreetingInfoBox>
			</GreetingsContBox>
		</GreetingsWarrper>
	)
}

export default KbsiGreetings;