import React, { Component } from 'react';

import MasterBanner from './master_banner';
import Benefit from './master_benefit';
import BenefitHowTo from './master_benefitHow';
import BenefitRecord from './master_record';

class index extends Component {
	render() {
		return (
			<div>
				<MasterBanner/>
				<Benefit/>
				<BenefitHowTo/>
				<BenefitRecord/>
			</div>
		);
	}
}

export default index;