import React,{ Component } from 'react';

import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import ResetCss from './reset';


import Home from './pages/Home';
import Karfeis from './pages/KARFEis';
import Academy from './pages/Academy';
import Kbsi from './pages/Kbsi';
import Bufs from './pages/Bufs';
import BufsMobility from './pages/Bufs/BufsMobility';
import BufsPrevent from './pages/Bufs/BufsPrevent';
import BufsYoga from './pages/Bufs/BufsYoga';
import Instructor from './pages/Instructor';
import MasterTrainer from './pages/MasterTrainer';
import MentoringSchool from './pages/MentoringSchool';
import OfflineEducation from './pages/OfflineEducation';
import Stc from './pages/Stc';
import Cfsc from './pages/Cfsc';
import Dns from './pages/Dns';

import StcCoach from './pages/Stc/Stc_coach';
import CfscCoach from './pages/Cfsc/Cfsc_coach';
import DnsCoach from './pages/Dns/Dns_coach';

import RouteLayout from "./RouteLayout";
import OfflineEducationDetail from './pages/OfflineEducation/OfflineEducationDetail';
import AppIntoduce from './pages/AppIntoduce';
import OnlineEducation from './pages/OnlineEducation';
import OnlineEducationDetail from './pages/OnlineEducation/OnlineEducationDetail';
import InstructorDetail from './pages/InstructorDetail';

class App extends Component {
	render(){ 
		return (
			<>
				<Router >
					<ResetCss/>
					<div>
						<Switch>
							<RouteLayout exact path="/" component={Home} />
							<RouteLayout exact path="/index.php" component={Home} />
							<RouteLayout exact path="/karfe" component={Karfeis} />
							<RouteLayout exact path="/academy/02home" component={Academy} />
							{/* <RouteLayout exact path="/kbsi" component={Kbsi} /> */}
							<RouteLayout exact path="/bufs" component={Bufs} />
							<RouteLayout exact path="/bufs_mobility" component={BufsMobility} />
							<RouteLayout exact path="/bufs_prevent" component={BufsPrevent} />
							<RouteLayout exact path="/bufs_yoga" component={BufsYoga} />
							<RouteLayout exact path="/instructor_home" component={Instructor} />
							<RouteLayout exact path="/master_trainer" component={MasterTrainer} />
							<RouteLayout exact path="/mentoringschool" component={MentoringSchool} />
							<RouteLayout exact path="/offline_education" component={OfflineEducation} />
							<RouteLayout exact path="/offline_education/:bid/:id" component={OfflineEducationDetail} />
							<RouteLayout exact path="/online_education" component={OnlineEducation} />
							<RouteLayout exact path="/online_education/:id" component={OnlineEducationDetail} />
							<RouteLayout exact path="/appintoduce" component={AppIntoduce} />
							<RouteLayout exact path="/instructor_detail/:id" component={InstructorDetail} />
						</Switch>
						<Switch>
							<Route exact path="/cfsc" component={Cfsc} />
							<Route exact path="/cfsc/coach" component={CfscCoach} />
						</Switch>
						<Switch>
							<Route exact path="/dns" component={Dns} />
							<Route exact path="/dns/coach" component={DnsCoach} />
						</Switch>
						{/* <Switch>
							<Route exact path="/stc" component={Stc} />
							<Route exact path="/stc/coach" component={StcCoach} />
						</Switch> */}
					</div>
				</Router>
			</>
		);
	}
}

export default App;
