import React, {Component} from 'react';

import styled from 'styled-components';

import ReplayModal from './Replay_modal';

import ProcessArrow from './img/process_arrow_ico.png';
import MoProcessArrow from './img/m_process_arrow_ico.png';

import VideoThums from './img/dns_video_thums.png';

const ConceptBox = styled.div`
	padding:60px 0;
	background: #d2e6de;

	@media(max-width:960px){
		padding:0;
	}
`

const InfoConTit = styled.h2`
    text-align: center;
    font-size: 63px;
    color: #333;
	letter-spacing: -5px;
	padding:0 0 60px 0;
	line-height: 130%;

	&>span{
		font-family: 'Impact';
	}

	@media(max-width:960px){
		font-size: 40px;
		padding-top:60px;
		padding-bottom: 60px;
	}

`

const ProcessCircleWrap = styled.div`
	display:flex;
	max-width:1450px;
	width:100%;
	margin:0 auto;

	@media(max-width:1280px){
		flex-direction: column;
    	align-items: center;
	}
`

const ProcessCircleItems = styled.div`
	position:relative;
	width: 23%;
	margin: 0 2%;

	&:after{
		content:"";
		position:absolute;
		top: 4vw;
    	right: -2.1vw;
		background:url(${ProcessArrow}) 0 0 no-repeat;
		width: 4.2vw;
    	height: 3.7vw;
    	background-size: cover;

	}

	&:last-child:after{
		display:none;
	}
	
	&:nth-child(1)>div{
		background:#6284c6;
	}

	&:nth-child(2)>div{
		background:#4d6798;
	}

	&:nth-child(3)>div{
		background:#29467d;
	}

	&:nth-child(4)>div{
		background:#0c234f;
	}

	@media(max-width:1280px){
		display: flex;
		width: 100%;
    	max-width: 640px;
		margin-bottom: 15px;

		&:after{
			content:"";
			position:absolute;
			top: 164px;
    		right: 355px;
			background:url(${MoProcessArrow}) 0 0 no-repeat;
			width: 66px;
    		height: 72px;
			background-size: cover;

		}
	}

	@media(max-width:680px){
	 	flex-direction: column;
		align-items: center;
		padding-bottom: 90px;

		&:nth-child(4)>div{
			margin-top:15px;
		}
		
		&:after{
			content:"";
			position:absolute;
			top: 307px;
			right: 50%;
			transform: translate(50%,0);
			background:url(${MoProcessArrow}) 0 0 no-repeat;
			width: 66px;
    		height: 72px;
			background-size: cover;

		}
	}
`

const ProcessCircleBox = styled.div`
	display:flex;
	flex-direction: column;
	width:12vw;
	height:12vw;
	border-radius:50%;
	justify-content: center;
	align-items: center;
	text-align:center;
	font-size:24px;
	color: #fff;

	@media(max-width:1280px){
		font-size: 2vw;
		width: 180px;
    	height: 180px;
	}

	@media(max-width:720px){
		font-size:20px;
	}
	
`

const CircleMenu = styled.ul`
	margin-top: 38px;
	font-size:13px;

	@media(max-width:1280px){
		align-self: center;
		margin-left: 20px;
	}
`

const CircleList = styled.li`
	position:relative;
	padding:0 0 0 10px;
	line-height:140%;

	&:after{
		content:"-";
		position:absolute;
		top:0;
		left:0;
	}
`

const ProcessAddress = styled.span`
	font-size:18px;
	color:#9fddc4;
`

const VideoImg = styled.img`
	max-width:190px;
	width:100%;
	display:block;
	cursor:pointer;
	padding:16px 0 0 16px;

	@media (max-width: 680px){
		max-width:120px;
		margin:0 auto;
		padding:10px 0 0 0;
	}
`

class Process extends Component {
	state = {
		isReply: false,
	}

	componentDidMount() {
		this.props.getOffset('process', this.process);
	}

	OpenReplayModal = () => {
		this.setState({isReply: true});
	}

	CloseReplayModal = () => {
		this.setState({isReply: false});
	}

	render() {
		return (
			<section 
				id="process"
				ref={ref => {
					this.process = ref;
				}}
			>
				<ConceptBox>
					<InfoConTit><span>DNS</span> 교육과정 절차</InfoConTit>
					<ProcessCircleWrap>
						<ProcessCircleItems>
							<ProcessCircleBox><span>교육과정 등록</span>
								<ProcessAddress>http://www.karfe.org</ProcessAddress>
							</ProcessCircleBox>
							<CircleMenu>
								<CircleList>카르페 홈페이지에 가입 후 교육과정 등록</CircleList>
								<CircleList>단일 코스를 제외한 단계별 코스는 A 또는 Part.1 <br />교육과정 이수 후 다음교육 과정 등록 가능 </CircleList>
								<CircleList>각 코스별 교육대상자(의료인)가 구분되니 등록 전 문의 필수</CircleList>
							</CircleMenu>
						</ProcessCircleItems>
						<ProcessCircleItems>
							<ProcessCircleBox>프라하스쿨<br />교육과정 등록</ProcessCircleBox>
							<CircleMenu>
								<CircleList>프라하 스쿨 본사에 등록 필수(www.rehabps.com)</CircleList>
								<CircleList>
									각 과정마다 80유로(EUR)등록비용 필수<br/>
									<VideoImg src={VideoThums} onClick={this.OpenReplayModal}/>
									{this.state.isReply ? <ReplayModal isReply={this.state.isReply} onCloseModal={this.CloseReplayModal} /> : ''}
								</CircleList>
							</CircleMenu>
						</ProcessCircleItems>
						<ProcessCircleItems>
							<ProcessCircleBox>현장강의 참여</ProcessCircleBox>
							<CircleMenu>
								<CircleList>현장강의 10분 이상 지각 시 자격증 취득 불가함</CircleList>
								<CircleList>1 day 모든 강의 일정에 참여</CircleList>
								<CircleList>현장강의 후 실기 테스트 통과 시 자격증 취득 가능</CircleList>
							</CircleMenu>
						</ProcessCircleItems>
						<ProcessCircleItems>
							<ProcessCircleBox>자격증 취득</ProcessCircleBox>
							<CircleMenu>
								<CircleList>본사 공인 자격증 수여</CircleList>
								<CircleList>CFSC Korea 호메이지 및<br />미국 본사 홈페이지에 등록</CircleList>
							</CircleMenu>
						</ProcessCircleItems>
					</ProcessCircleWrap>
				</ConceptBox>
			</section>
		)
	}
}

export default Process;