import React from 'react';

import BannerBg from './img/cpt_bg1.jpg'

import styled from 'styled-components';

const BannerBox = styled.div`
	display: flex;
    justify-content: center;
	position:relative;
	height: 216px;
	background:url(${BannerBg}) 0 0 no-repeat;
`

const BannerInner = styled.div`
	display: flex;
    justify-content: center;
	align-items: center;
	max-width: 1060px;
	color:#fff;
	box-sizing: border-box;
    padding: 0 20px;
`

const LftTxt = styled.p`
	font-size: 60px;
`

const RghtTxt = styled.p`
	font-size: 20px;
    padding-left: 50px;
	line-height: 27px;
	
	@media(max-width:520px){
		font-size:16px;
		line-height:140%;
	}
`

const CptBanner = () => {
	return(
		<BannerBox>
			<BannerInner>
				<LftTxt>
					CPT
				</LftTxt>
				<RghtTxt>
					카르페의 CPT교육은 퍼스널 트레이닝의 기초부터 실전 응용까지 배울 수 있는 과정으로써 트레이닝 방법론에 따른 학술적인 내용을 근거로 운동을 남녀노소 누구든 잘 지도할 수 있도록 구성된 교육과정입니다.
				</RghtTxt>
			</BannerInner>
		</BannerBox>
	)
}

export default CptBanner;