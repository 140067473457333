import React, { useEffect, useState } from "react";
import StarRating from "../../components/StartRating";
import Axios from "axios";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Avatar from "@material-ui/core/Avatar";

export default function OnlineEducation() {
  const [selectCategory, setSelectCategory] = useState("전체");
  const [datas, setDatas] = useState();
  const router = useHistory();

  useEffect(() => {
    const getDatas = async () => {
      const result = await Axios.post(
        "https://api.teamstc.org:3003/api/user/vod/all"
      );
      if (!result?.data?.success) return;
      setDatas({
        category: [
          "전체",
          ...result.data.result.reduce((x, y) => {
            const name = y.category.name;
            if (!x.includes(name)) {
              x.push(name);
            }
            return x;
          }, []),
        ],
        vods: result.data.result,
      });
    };
    getDatas();
  }, []);

  return (
    <div className="min-h-[60vh] p-5 max-w-[1354px] mx-auto">
      {datas ? (
        <div className="p-3">
          <div className="overflow-auto mb-5">
            <div className="flex gap-3">
              {datas.category.map((category, index) => (
                <button
                  key={index}
                  className={`flex-shrink-0 py-2 px-4 rounded-full text-sm border border-blue-500 ${
                    selectCategory === category
                      ? "bg-blue-500 text-white"
                      : "text-blue-500 opacity-50"
                  }`}
                  onClick={() => setSelectCategory(category)}
                >
                  {category}
                </button>
              ))}
            </div>
          </div>
          <div className="flex flex-col justify-center items-center gap-3">
            {datas.vods
              .filter((x) => {
                if (selectCategory === "전체") return true;
                else return selectCategory === x.category.name;
              })
              .map((vod, index) => (
                <button
                  key={index}
                  className="w-full p-3 border rounded text-left"
                  onClick={() => router.push("/online_education/" + vod.id)}
                >
                  <div className="flex items-center gap-2">
                    <Avatar
                      sx={{ width: 36, height: 36 }}
                      src={`https://api.teamstc.org:3003/api/img/trainerinfo/${vod.teacher.id}.jpg`}
                    />
                    <p className="break-words font-semibold break-keep">
                      {vod.title}
                    </p>
                  </div>
                  <p className="text-sm opacity-70">{vod.teacher.name}</p>
                  {vod.star.count === 0 || (
                    <div className="flex gap-1">
                      <p className="text-sm opacity-50">{vod.star.avg}</p>
                      <StarRating rating={vod.star.avg} size="15" />
                      <p className="text-xs opacity-50">({vod.star.count})</p>
                    </div>
                  )}
                  {vod.price.sale != vod.price.origin && (
                    <div className="flex gap-2 justify-end items-center">
                      <p className="text-sm bg-emerald-500 text-white px-2 rounded">
                        {vod.sale.end} 까지
                      </p>
                      <p className="text-sm opacity-50 line-through">
                        {(+vod.price.origin).toLocaleString()} 원
                      </p>
                    </div>
                  )}
                  <p className="font-bold text-right">
                    {(+vod.price.sale).toLocaleString()} 원
                  </p>
                </button>
              ))}
          </div>
        </div>
      ) : (
        <div className="w-full min-h-[60vh] flex justify-center items-center">
          인터넷교육을 불러오는 중 입니다.
        </div>
      )}
    </div>
  );
}
