import React from 'react';

import QualificationImg from './img/qualification_thums.jpg';
import MoQualificationImg from './img/m_qualification_thums.jpg';

import CheckIcon from './img/check_icon.png';

import styled from 'styled-components';

const TeacherTit = styled.h2`
	padding:120px 0;
	font-size:64px;
	font-weight:100;
	color:#565656;
	text-align:center;

	&>span{
		color:#a49a21;
	}

	@media(max-width: 960px){
		padding:60px 0 0 0;
		font-size: 33px;
	}

	@media(max-width:480px){
		&>span{
			display:block;
		}
	}
`

const QualificationBox = styled.section`
	
`

const QualificationThums = styled.img`
	display:block;
	width:100%;

	@media(max-width:960px){
		display:none;
	}
`

const MoQualificationThums = styled.img`
	display:none;

	@media(max-width:960px){
		display:block;
		width:100%;
	}
`

const MentoMenuBox = styled.div`
	max-width: 1385px;
	width:100%;
    margin: 0 auto;
`

const MentoItems = styled.div`
	padding: 44px 165px;
	border-top:1px solid #dcdcdc;
	color:#595959;
	font-weight:300;
	text-align: center;

	&:first-child{
		border-top:0;
	}

	@media(max-width:1235px){
		padding: 44px 15px;

		&>span{
			font-size:25px;
		}
	}
`

const MentoLft = styled.span`
	font-size:49px;
	font-style: italic;
	letter-spacing: -2px;

	@media(max-width:680px){
		display:block;
		margin-bottom: 30px;
	}
`

const MentoLftBtm = styled(MentoLft)`
	@media(max-width:680px){
		margin-top: 30px;
	}
`

const MentoRght = styled.span`
	position:relative;
    font-size: 49px;
	font-weight: 900;
	font-style: italic;
	letter-spacing: -2px;

	&:after{
		content:"";
		position:absolute;
		background:url(${CheckIcon}) 0 0 no-repeat;
		width: 201px;
		height: 165px;
		top: -36px;
		right: -22px;
		background-size: 100%;
	}

	@media(max-width:1235px){
		&:after{
			width: 106px;
			top: -23px;
		}
	}
`

const MentoRghtExtension = styled(MentoRght)`
	&:after{
		right: 33px;
	}
`

const Qualification = () => {
	return(
		<QualificationBox>
			<div>
				<TeacherTit>카르페멘토링스쿨 <span>지원자격</span></TeacherTit>
			</div>

			<MentoMenuBox>
				<MentoItems>
					<MentoLft>
						대한민국 남녀 누구나
					</MentoLft>
					<MentoRght>트레이너가 되고자 하는 모든분들</MentoRght>
				</MentoItems>
				<MentoItems>
					<MentoLft>
						체육전공자?NO!
					</MentoLft>
					<MentoRght>전공무관</MentoRght>
				</MentoItems>
				<MentoItems>
					<MentoLft>
						6주
					</MentoLft>
					<MentoRghtExtension>현장실습90%이상참가</MentoRghtExtension>
					<MentoLftBtm>
						가능하신분
					</MentoLftBtm>
				</MentoItems>
			</MentoMenuBox>
		</QualificationBox>
	)
}

export default Qualification;