import React from 'react';

import HowToList1 from './img/how_1.png';
import HowToList2 from './img/how_2.png';
import HowToList3 from './img/how_3.png';
import HowToList4 from './img/how_4.png';
import HowToList5 from './img/how_5.png';
import HowToList6 from './img/how_6.png';

import styled from 'styled-components';

const HowtoWarrper = styled.section`
	padding:78px 0;
	background-color:#4795d1;
`

const HowtoInner = styled.div`
	max-width:1350px;
	margin:0 auto;
`

const HowtoTit = styled.h1`
	padding-bottom:15px;
	font-size:33px;
	color:#fff;
	text-align:center;
	letter-spacing:1px;
`

const HowtoTxt = styled.p`
	padding-bottom:60px;
	font-size:23px;
	color:#fff;
	text-align:center;
	font-weight:100;

	@media(max-width:1024px){
		padding-bottom:10%;
	}
`

const HowtoListBox = styled.ul`
	width: 100%;
    text-align: center;
`

const HowtoList = styled.li`
	position:relative;
	display:inline-block;
	width: 13.888%;
	margin:1%;

	&:last-child>div{
		font-size: 1.8vw;
	}

	@media(max-width:1024px){
		width:27.333%;
	}

	@media(max-width:960px){
		&:last-child>div{
			font-size: 36px;
		}
	}

	@media(max-width:720px){
		width:40%;
		margin:1% .5%;
	}
`

const HowtoListImg = styled.img`
	display:block;
	width:100%;
`

const HowToListTxt = styled.div`
	position: absolute;
    top: 50%;
	left: 50%;
	width: 100%;
	font-size: 2.8vw;
	font-weight: 700;
	color: #fff;
	transform: translate(-50%,-50%);

	@media(max-width:960px){
		font-size: 30px;
	}

	
`

const HowToListSubTxt = styled.p`
	font-size: .7vw;

	@media(max-width:960px){
		font-size:14px;
	}
`

const HowToListSubExtension = styled.p`
	
`

const BenefitHowTo = () => {
	const data = [
		{HowToImg: HowToList1, txt:'CPT', hasSub:false},
		{HowToImg: HowToList2, txt: 'PES', hasSub: false},
		{HowToImg: HowToList3, txt: 'CES', hasSub: false},
		{HowToImg: HowToList4, txt: 'CFSC', subtxt: 'Level 1,2', hasSub: true},
		{HowToImg: HowToList5, txt: 'STC', subtxt: 'Rehap-advance', hasSub: true},
		{HowToImg: HowToList6, txt: '카르페', subtxt: '컨퍼런스', hasSub: true},
	];

	return (
		<HowtoWarrper>
			<HowtoInner>
				<HowtoTit>How to become a Master Trainer</HowtoTit>
				<HowtoTxt>모든 코스는 인터넷 수업 구분없이 모두 이수 가능합니다.</HowtoTxt>
				<HowtoListBox>
					{
						data.map((TranerList,idx)=>{
							return(
								<HowtoList key={idx}>
									<HowtoListImg src={TranerList.HowToImg}/>
									<HowToListTxt>
										<p>{TranerList.txt}</p>
										{
										TranerList.hasSub 
										? 
										idx !== 5 ? <HowToListSubTxt>{TranerList.subtxt}</HowToListSubTxt> : <HowToListSubExtension>{TranerList.subtxt}</HowToListSubExtension>
										: 
										""}
									</HowToListTxt>
								</HowtoList>
							)
						})
					}
				</HowtoListBox>
			</HowtoInner>
		</HowtoWarrper>
	)
}

export default BenefitHowTo;