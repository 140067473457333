import React from 'react';

import {Link} from 'react-router-dom';

import logo from './img/logo.jpg';
import MenuBg from './img/bg_topmenu.jpg';

import styled from 'styled-components';

const LogoBox = styled.div`
	padding:90px 0;
	text-align: center;

	@media(max-width:960px){
		text-align: center;
		width: 86px;
		margin: 0 auto;
		padding: 10px 0;
		background: #fff;

		&>img{
			display:block;
			width:100%;
		}
	}
`

const StcMenuBox = styled.div`
	background:url(${MenuBg}) 0 0 repeat-x;
`

const StcMenu = styled.ul`
	padding: 47px 0;
	text-align:center;

	@media(max-width:960px){
		display:none;
	}
`

const StcList = styled.li`
	display: inline-block;
	margin: 0px 2%;
    box-sizing: border-box;
`


const StcLink = styled.span`
	color:#fff;
	cursor:pointer;
	font-size: 20px;
`

const MoStcLink = styled.span`
	color:#555;
`

const HambugerBox = styled.div`
	position: absolute;
	top:26px;
	left: 30px;
	display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 24px;
	height: 18px;
	cursor:pointer;
	z-index:5;
`

const HambugerLine = styled.div`
	display:block;

	@media (max-width: 960px) {
		display:block;
		width:100%;
		height:2px;
		background:#a9a9a9;
	}
`

const MobileToggle = styled.div`
	position:relative;
	top:-500px;
	visibility: hidden;
	display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
	height:0;
	transition: all .4s ease-in 0s;
	opacity:0;
	z-index:-9999;

	@media (max-width: 960px) {
		&.on{
			position:relative;
			top:0;
			visibility: visible;
			height:400px;
			background: #fff;
			z-index:0;
			opacity:1;
		}
	}


`

const MobileLink = styled.li`

`

const StcLogo = styled.img`
	display:block;
	width:100%;
	max-width:285px;
	margin:0 auto;
`

const StcHeader = (props) => {
	return(
		<>
			<LogoBox>
				<Link to="/stc">
					<StcLogo src={logo} alt="STC로고"/>
				</Link>
			</LogoBox>
			<StcMenuBox>
				<StcMenu>
					<StcList><Link to="/stc?intro"><StcLink data-type="intro">STC소개</StcLink></Link></StcList>
					<StcList><Link to="/stc?course"><StcLink data-type="course">코스소개</StcLink></Link></StcList>
					<StcList><Link to="/stc?concept"><StcLink data-type="concept">특징</StcLink></Link></StcList>
					<StcList><Link to="/stc?process"><StcLink data-type="process">커리큘럼</StcLink></Link></StcList>
					<StcList><Link to="/stc?certificates"><StcLink data-type="certificates">인증</StcLink></Link></StcList>
					<StcList><Link to="/stc?outlook"><StcLink data-type="outlook">교육일정</StcLink></Link></StcList>
					<StcList><Link to="/stc/coach"><StcLink>코치소개</StcLink></Link></StcList>
				</StcMenu>
			</StcMenuBox>

			<Hambuger />
			<MobileToggle className="mob_toggle" onClick={(e) => {
				const ToggleBox = document.querySelector('.mob_toggle');

				ToggleBox.classList.remove('on');
			}}>
				<MobileLink><Link to="/stc?intro"><MoStcLink>STC소개</MoStcLink></Link></MobileLink>
				<MobileLink><Link to="/stc?course"><MoStcLink>코스소개</MoStcLink></Link></MobileLink>
				<MobileLink><Link to="/stc?concept"><MoStcLink>특징</MoStcLink></Link></MobileLink>
				<MobileLink><Link to="/stc?process"><MoStcLink>커리큘럼</MoStcLink></Link></MobileLink>
				<MobileLink><Link to="/stc?certificates"><MoStcLink>인증</MoStcLink></Link></MobileLink>
				<MobileLink><Link to="/stc?outlook"><MoStcLink>교육일정</MoStcLink></Link></MobileLink>
				<MobileLink><Link to="/stc/coach"><MoStcLink>코치소개</MoStcLink></Link></MobileLink>
			</MobileToggle>
		</>
	)
}

const Hambuger = () => {
	const Toggle = (e) => {
		const MobToggle = document.querySelector('.mob_toggle');

		MobToggle.classList.toggle('on');
	}
	return (
		<HambugerBox onClick={Toggle}>
			<HambugerLine></HambugerLine>
			<HambugerLine></HambugerLine>
			<HambugerLine></HambugerLine>
		</HambugerBox>
	)
}

export default StcHeader;