import React from 'react';

import HealthImg from './img/stc_health_bg.jpg';
import MoHealthImg from './img/m_stc_health_bg.jpg';

import styled from 'styled-components';

const HealthWarrper = styled.div`
	position:relative;
	height:847px;
	background:url(${HealthImg}) 50% 50% no-repeat;
	background-size:cover;

	@media(max-width:720px){
		height:560px;
		background:url(${MoHealthImg}) 50% 50% no-repeat;
		background-size:cover;
	}
`

const HealthImpact = styled.p`
	font-size: 110px;
	font-family: 'impact';

	@media(max-width:720px){
		font-size: 66px;
	}

	@media(max-width:680px){
		font-size: 50px;
	}
`

const GroupTxtBox = styled.div`
	position:absolute;
	top: 6%;
	left: 50%;
	transform:translate(-50%,0);
	display: flex;
	flex-direction: column;
	width: 80%;
	color:#fff;
	font-size: 24px;
	font-weight: 700;
	text-align: center;
	padding-top:10px;

	@media(max-width:720px){
		width:100%;
	}
`

const GroupConBox = styled.div`
	font-size: 4.5vw;
    font-style: normal;
`

const GroupTxtYellow = styled.span`
	color:#f4d10d;
	font-weight:400;
`

const GroupTxtMid = styled.p`
	padding-bottom: 10px;
	font-size:69px;

	&>span{
		font-weight:900;
	}

	@media(max-width:720px){
		font-size: 60px;
	}

	@media(max-width:680px){
		font-size: 50px;
	}
`

const GroupSubTxt = styled.p`
	@media(max-width:720px){
		font-size: 16px;
	}

	@media(max-width:680px){
		font-size: 14px;
	}
`

const StcBanner = () => {
	return(
		<HealthWarrper>
			<GroupTxtBox>
				<GroupConBox>
					<HealthImpact>CFSC</HealthImpact>
					<GroupTxtMid>드디어 <span>한국에 오다!</span></GroupTxtMid>
				</GroupConBox>
				<GroupSubTxt><GroupTxtYellow>북미 최고의 기능성 트레이닝 전문가 과정</GroupTxtYellow>인 CFSC과정이 한국에 옵니다!</GroupSubTxt>
			</GroupTxtBox>
		</HealthWarrper>
	)
}

export default StcBanner;