import React, { Component,useState, useEffect} from 'react';
import styled from 'styled-components';
import HomeTopBg from './img/home_top_bg.jpg';
import HomeTopBgMobile from './img/m_home_top_bg.jpg';
import { useCookies } from 'react-cookie';

// 쿠키등록
const HomeContainer = styled.div`
	position: relative;
`

const HomTopImg = styled.img`
	display:block;
	width:100%;

	@media (max-width:780px){
		display:none;
	}
`

const HomeTopImgMobile = styled.img`
	display:none;
	width:100%;

	@media (max-width:780px){
		display:block;
	}
`

const TopTitleBox = styled.div`
	position: absolute;
    top: 51%;
    right: 16%;
	transform: translate(0, -28%);
	
	@media(max-width:1480px){
		right:10%;
	}

	@media(max-width:780px){
		top: 80%;
		right:5%;
		transform: translate(0, 0);
	}
`

const TopTitContxt = styled.p`
	font-size: 28px;
	color:#a4a2a3;
	letter-spacing: 1.3px;

	@media(max-width:1480px){
		font-size:20px;
		letter-spacing: -3px;
	}

	@media(max-width:640px){
		font-size:15px;
	}

	&>span{
		color:#505050;
		font-weight:900;

		@media(max-width:1480px){
			font-size:20px;
		}

		@media(max-width:640px){
			font-size:15px;
		}
	}
`

const BottomTitContxt = styled.p`
	font-size: 75px;
	color:#595959;
	letter-spacing: -12px;

	@media(max-width:1480px){
		font-size:43px;
		letter-spacing: -3px;
	}

	@media(max-width:640px){
		font-size:30px;
	}

	&>span{
		position: relative;
    	top: 6px;
		padding-right: 5px;
		font-family: 'Impact';
		font-size: 75px;
		font-weight: 900;
		color:#000;

		@media(max-width:1480px){
			font-size:43px;
			top: 3px;
		}

		@media(max-width:640px){
			font-size:30px;
		}
	}
`


const PopContainer = styled.div`
	position: absolute; z-index:999999; background:#ffffff;top:1rem; left:1rem; font-size:20px; width: 90%; max-width: 550px; border:2px solid #cccccc;	
`;

const PopButton = styled.div`
	text-align:center;
	padding-top:10px;
	padding-bottom:10px;
`;

function HomeTop () {

	const [display,setDisplay] = useState('none');
	const [cookie, setCookie] = useCookies(['name']);

	useEffect(()=>{
		// if(cookie.P_20201221!='1') {
		// 	setDisplay('block');
		// }
	},[])

	function pop_close_20201221(){
		setDisplay('none');
	}
	
	function pop_close_24_20201221() {
		setCookie('P_20201221', '1', { path: '/' , maxAge:60*60*24});		
		setDisplay('none');
	}

	return (
		<>
			<PopContainer id="P20201221" style={{display:display}}>
				<a href="http://www.smartfitkorea.com/slim/internet?focusDVD=EVENT"><img src="http://smartfitkorea.com/img/event/20201216_POP.jpg" width="100%" /></a>
				<PopButton>
				<button type="button" name="button" onClick={()=>pop_close_24_20201221()}>오늘 하루 창 열지 않기</button>
				<button type="button" name="button" onClick={()=>pop_close_20201221()}>창 닫기</button>
				</PopButton>
			</PopContainer>	

			<HomeContainer>					

				<TopTitleBox>
					<TopTitContxt>
						<span>사단법인</span> 대한기능재활운동협회
					</TopTitContxt>
					<BottomTitContxt>
						<span>KARFE</span> 카르페
					</BottomTitContxt>
				</TopTitleBox>
				<HomTopImg src={HomeTopBg}/>
				<HomeTopImgMobile src={HomeTopBgMobile}/>
			</HomeContainer>
		</>
	);
	
}

export default HomeTop;