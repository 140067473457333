import React, {Component} from 'react';

import styled from 'styled-components';

import CharacteristicBg from './img/cfsc_characteristic_bg.jpg';
import MoCharacteristicBg from './img/m_cfsc_characteristic_bg.jpg';

import Chbg from './img/cfsc_ch_bg.jpg';

import PicturLft from './img/pictur_lft_arrow.png';
import PicturRght from './img/pictur_rght_arrow.png';

import CfscThums1 from './img/thumb1.jpg';
import CfscThums2 from './img/thumb2.jpg';
import CfscThums3 from './img/thumb3.jpg';
import CfscThums4 from './img/thumb4.jpg';

import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';

const ConceptBox = styled.div`
	position:relative;
	background:url(${CharacteristicBg}) 50% 50% no-repeat;
	height: 567px;
	background-size: cover;

	@media(max-width:720px){
		background:url(${MoCharacteristicBg}) 50% 50% no-repeat;
		height: 432px;
		background-size: cover;
	}
`

const InfoConTit = styled.h2`
    text-align: center;
    font-size: 63px;
    color: #fff;
	letter-spacing: -5px;
	padding:140px 0 15px 0;
	line-height: 130%;

	&>span{
		font-family: 'Impact';
	}

	@media(max-width:960px){
		font-size: 40px;
		padding-top:60px;
		padding-bottom: 60px;
	}
`

const CharacteristicTxt = styled.p`
	color: #fff;
	text-align: center;
	line-height:140%;
	width: 90%;
    margin: 0 auto;
`

const CharConBox = styled.div`
	background:url(${Chbg}) 0 0 repeat-x;
	height:563px;

	@media(max-width:1280px){
		background:#161415;
		height:initial;
	}
`

const CharInnter = styled.div`
	display:flex;
	max-width: 1350px;
    width: 100%;
	margin: 0 auto;
	padding: 0 1%;
	box-sizing:border-box;
	color:#fff;

	@media(max-width:1280px){
		flex-direction:column;
	}
`

const CharItems = styled.div`
	width:50%;

	@media(max-width:1280px){
		width:100%;
	}
`

const CharOnlineTxt = styled.p`
	font-size: 2.1vw;
    height: 170px;
    line-height: 170px;
	
	&>span{
		color:#db191b;
	}

	@media(max-width:1280px){
		font-size:30px;
		text-align: center;
	}

	@media(max-width:680px){
		height: initial;
		line-height: initial;
		padding:60px 0;
		font-size:26px;
	}
`

const CharOutLook = styled.div`
	display:flex;
	padding-top:50px;

	@media(max-width:1280px){
		justify-content: center;
	}

	@media(max-width:680px){
		flex-direction: column;
		align-items: center;
		padding-top:0;
	}
`

const CharOutLookItems = styled.div`
	width:50%;

	@media(max-width:1280px){
		width:36%;	
	}

	@media(max-width:680px){
		width:80%;
	}
`

const CharOutLookTit = styled.h2`
	padding-bottom:30px;
	font-size:23px;

	@media(max-width:1280px){
		font-size:24px;
	}

	@media(max-width:680px){
		padding-bottom:15px;
	}
`

const CharOutLookMenu = styled.ul`
	&>li{
		font-size:17px;
		color:#898989;
		line-height:160%;
	}

	@media(max-width:1280px){
		padding-bottom:10px;

		&>li{
			font-size:13px;
		}
	}
`

const VideoBox = styled.div`
	position:relative;
	height:374px;

	@media(max-width:1280px){
		height:0;
		margin-top: 40px;
		padding-bottom:57%
	}
`

const OutLookVideo = styled.iframe`
	position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`

const ThumsItems = styled.img`
	height:initial !important;
	min-height:initial !important;
`

const SliderInner = styled.div`
	margin: 0 auto;
    padding: 41px 10%;
    background: #1f1f1f;
    box-sizing: border-box;
`

function SamplePrevArrow(props) {
	const {className, style, onClick} = props;
	return (
		<div
			className={className}
			style={{...style, position: "absolute", top: "50%", left: "-32px", width: "21px", height: "21px", display: "block", marginTop: "-10.5px", background: `url(${PicturLft}) 0 0 no-repeat`, cursor: "pointer", zIndex: "5"}}
			onClick={onClick}
		/>
	);
}

function SampleNextArrow(props) {
	const {className, style, onClick} = props;
	return (
		<div
			className={className}
			style={{...style, position: "absolute", top: "50%", right: "-32px", width: "21px", height: "21px", display: "block", marginTop: "-10.5px", background: `url(${PicturRght}) 0 0 no-repeat`, cursor: "pointer", zIndex: "5"}}
			onClick={onClick}
		/>
	);
}

class Record extends Component {
	state = {
		SlcVideo:'https://player.vimeo.com/video/217144476',
	}

	componentDidMount() {
		this.props.getOffset('record', this.record);
	}

	SlcVideo = (e) => {
		const {target} = e;
		this.setState({SlcVideo: target.dataset.url});
	}

	render(){
		const settings = {
			dots: false,
			infinite: true,
			autoplay: true,
			autoplaySpeed: 3000,
			pauseOnHover: true,
			speed: 600,
			slidesToShow: 3,
			slidesToScroll: 1,
			nextArrow: <SampleNextArrow />,
			prevArrow: <SamplePrevArrow />,
		};
		return(
			<div
				id="record"
				ref={ref => {
					this.record = ref;
				}}
			>
				<ConceptBox>
					<InfoConTit><span>CFSC</span>의 특징</InfoConTit>
					<CharacteristicTxt>
						CFSC 모든 교육 코스는 10~15(교육생) : 1(강사)의 소그룹 강의방식으로 교육생들에게 디태일한 티칭과 피드백을 통해 교육의 질을 높였습니다.<br/>
						다양한 툴(레더, 콘, 밴드, 메디신 볼, TRX, 케틀벨 등)을 이용한 검증된 기능 강화 훈련방법을 전수 받을 수 있습니다.
					</CharacteristicTxt>
				</ConceptBox>
				<CharConBox>
					<CharInnter>
						<CharItems>
							<CharOnlineTxt>온라인교육과 현장교육의 <span>콜라보레이션</span></CharOnlineTxt>
							<CharOutLook>
								<CharOutLookItems>
									<CharOutLookTit>온라인교육</CharOutLookTit>
									<CharOutLookMenu>
										<li>1.펑셔널 트레이닝 기초 이론</li>
										<li>2.CFSC교육 철학</li>
										<li>3.기능적 움직임 컨셉</li>
										<li>4.펑셔널 트레이닝 방법론</li>
										<li>5.Regression/Progression 방법</li>
										<li>6.트레이닝 주기화 및 코칭론</li>
										<li>7.트레이닝 프로그래밍 방법론</li>
										<li>8.기초 기능해부학</li>
									</CharOutLookMenu>
								</CharOutLookItems>
								<CharOutLookItems>
									<CharOutLookTit>현장교육</CharOutLookTit>
									<CharOutLookMenu>
										<li>1.기초 기능적 호흡법</li>
										<li>2.Lagger를 이용한 민첩성/신속성 트레이닝</li>
										<li>3.월볼 및 메디신볼을 이용한 코어 트레이닝</li>
										<li>4.동적관절가동운동</li>
										<li>5.케틀벨을 이용한 펑셔널 트레이닝</li>
										<li>6.펑셔널 트레이닝 티칭 방법</li>
										<li>7.스쿼트&amp;런지의 다양한 적용방법</li>
										<li>8.기능향상을 위한 스포츠 트레이닝 프로그래밍</li>
									</CharOutLookMenu>
								</CharOutLookItems>
							</CharOutLook>
						</CharItems>
						<CharItems>
							<VideoBox>	
								<OutLookVideo src={this.state.SlcVideo} ref={(ref) => this.OutVideo = ref}/>
							</VideoBox>
							<SliderInner>
								<Slider {...settings}>
									<div onClick={this.SlcVideo}>
										<ThumsItems src={CfscThums1} data-url="https://player.vimeo.com/video/217144476?autoplay=1"/>
									</div>
									<div onClick={this.SlcVideo}>
										<ThumsItems src={CfscThums2} data-url="https://player.vimeo.com/video/259639976?autoplay=1"/>
									</div>
									<div onClick={this.SlcVideo}>
										<ThumsItems src={CfscThums3} data-url="https://player.vimeo.com/video/217144628?autoplay=1"/>
									</div>
									<div onClick={this.SlcVideo}>
										<ThumsItems src={CfscThums4} data-url="https://player.vimeo.com/video/194321505?autoplay=1"/>
									</div>
								</Slider>
							</SliderInner>
						</CharItems>
					</CharInnter>
				</CharConBox>
			</div>
		)
	}
}

export default Record;