import React, { Component } from "react";

import HomeTop from "./Home_top";
import HomeQuestion from "./Home_question";
import HomePermit from "./Home_permit";
import HomeAwards from "./Home_awards";
import HomeVideo from "./Home_video";
import HomeOutlook from "./Home_outlook";
import HomePictur from "./Home_pictur";
import KarfeVideo from "./video/karfe.mp4";

import Sns from "../../components/Sns";

class index extends Component {
  render() {
    return (
      <div>
        {/* <HomeTop/> */}
        <div className="relative">
          <video
            src={KarfeVideo}
            className="w-full lg:h-[calc(100vh-136px)] object-contain bg-black"
            muted
            autoPlay
            loop
          />
        </div>
        <HomeQuestion />
        <HomePermit />
        {/* <HomeAwards/> */}
        {/* <HomeVideo/> */}
        {/* <HomeOutlook/> */}
        <HomePictur />
        <div className="bg-yellow-50 py-[3rem]">
          <div className="max-w-[1300px] mx-auto">
            <p className="text-2xl font-bold mb-5 text-center">바로가기</p>
            <div className="flex px-5 gap-4 justify-center">
              {[
                {
                  img: "you",
                  src: "https://www.youtube.com/@user-ug3wt1mz1b",
                  text: "YOUTUBE",
                },
                {
                  img: "ins",
                  src: "https://www.instagram.com/karfekorea/",
                  text: "INSTAGRAM",
                },
                {
                  img: "fac",
                  src: "https://www.facebook.com/karfekorea",
                  text: "FACEBOOK",
                },
              ].map((sns, index) => (
                <button
                  className="group max-w-[200px] w-1/3 border lg:p-3"
                  onClick={() => window.open(sns.src)}
                >
                  <div className="overflow-hidden relative">
                    <img
                      key={index}
                      src={require(`./img/${sns.img}.png`)}
                      className="object-contain scale-110 group-hover:scale-125 transition-transform duration-1000 w-full"
                    />
                    <div className="bg-gradient-to-b to-[rgba(0,0,0,0.8)] from-transparent absolute top-0 left-0 w-full h-full flex items-end text-white p-1 lg:p-3 whitespace-pre-line text-left font-semibold">
                      KARFE{`\n`}
                      {sns.text} {">"}
                    </div>
                  </div>
                </button>
              ))}
            </div>
          </div>
        </div>
        {/* <Sns /> */}
      </div>
    );
  }
}

export default index;
