import React, { useEffect, useState } from "react";
import axios from "axios";
import Instructor from "./Instructor";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

export default function KarfeInstructor({ InstructorTit }) {
  const [trainers, setTrainers] = useState();
  const router = useHistory();
  const level = {
    강사: "Instructor",
    보조강사: "Assist Instructor",
    주니어: "Junior Instructor",
  };

  useEffect(() => {
    const getData = async () => {
      const result = await axios.post(
        "https://api.teamstc.org:3003/api/user/trainer/all"
      );
      if (!result?.data?.success) return;
      const init = Object.values(level).reduce((x, y) => {
        x[y] = x[y] || [];
        return x;
      }, {});
      const obj = result.data.result.instructors.reduce((x, y) => {
        if (level[y.level]) x[level[y.level]].push(y);
        return x;
      }, init);
      obj.Instructor.unshift(result.data.result.president);
      setTrainers(obj);
    };
    getData();
  }, []);

  const goDetail = (id) => {
    router.push("/instructor_detail/" + id);
  };

  return (
    <div className="mt-10">
      {trainers &&
        Object.keys(trainers).map((data, index) => (
          <div key={index} className="mb-5">
            <InstructorTit>{data}</InstructorTit>
            <div className="flex flex-wrap">
              {trainers[data].map(
                (trainer, tindex) =>
                  data === level.강사 && (
                    <Instructor
                      trainer={trainer}
                      key={tindex}
                      onClick={() => goDetail(trainer.id)}
                    />
                  )
              )}
            </div>
            <div className="grid grid-cols-3 md:grid-cols-5 gap-4">
              {trainers[data].map(
                (trainer, tindex) =>
                  data !== level.강사 && (
                    <button
                      key={tindex}
                      className="w-full h-full flex flex-col justify-center items-center gap-3"
                      onClick={() => goDetail(trainer.id)}
                    >
                      <div className="w-full h-full bg-gray-200">
                        <img
                          src={`https://api.teamstc.org:3003${trainer.img}`}
                          alt=""
                          className="object-contain w-full h-full"
                          onError={(e) => e.target.classList.add("hidden")}
                        />
                      </div>
                      <p className="text-xl">{trainer.name}</p>
                    </button>
                  )
              )}
            </div>
          </div>
        ))}
    </div>
  );
}
