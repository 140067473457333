import React, {Component} from 'react';

import InfoBg from './img/info_bg_thums.jpg';
import ProcessTxt from './img/process_thums.png';

import styled from 'styled-components';

const InfoContainer = styled.div`
	position:relative;
	height:1238px;
	background:url(${InfoBg}) 0 0 no-repeat;

	@media(max-width:1098px){
		height:0;
		padding-bottom:120%;
		background:url(${InfoBg}) 0 0 no-repeat;
		background-size:cover;
	}
`

const InfoConTit = styled.h2`
    text-align: center;
    font-size: 63px;
    color: #fff;
	letter-spacing: -5px;
	padding:60px 0;
	line-height: 130%;

	&>span{
		font-family: 'Impact';
	}

	@media(max-width:960px){
		font-size: 40px;
		padding-top:60px;
		padding-bottom: 30px;
	}

`

const InfoThums = styled.img`
	display:block;
	width:100%;
	max-width:1098px;
	margin:0 auto;

	@media(max-width:960px){
		width:95%;
	}
`

class Info extends Component {
	componentDidMount() {
		this.props.getOffset('course',this.course);
	}

	render() {
		return (
			<InfoContainer 
				id="course"
				ref={ref => {
					this.course = ref;
				}}
			>
				<InfoConTit><span>STC</span> 코스안내</InfoConTit>
				<InfoThums src={ProcessTxt}/>
				{/*<InfoConTit><span>STC</span> 코스안내</InfoConTit>*/}
			</InfoContainer>
		)
	}
}

export default Info;