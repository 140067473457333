import React, {Component} from 'react';

import OutLookImg from './img/stc_peoples.jpg';
import customerImg from './img/customer_ico.png';

import SnsFaceBookThums from './img/facebook_ico.jpg';
import SnsKakaoTalkThums from './img/kakao_ico.jpg';

import styled from 'styled-components';

const OutLookBox = styled.section`
	padding:114px 0 0 0;

	@media(max-width:960px){
		padding:60px 0 0 0;
	}
`

const OutLookTit = styled.h2`
	padding-bottom:46px;
	font-size:70px;
	color:#3d3d3d;
	text-align:center;

	@media(max-width:960px){
		font-size:42px;
	}
`

const OutLookTitSpan = styled.span`
	position: relative;
    top: 5px;
	font-family:'Impact';
`

const SnsWarpper = styled.div`
	padding:74px 0;
	background:#f65253;
`

const SnsInner = styled.div`
	display: flex;
	max-width: 1354px;
	flex-wrap: wrap;
	margin: 0 auto;
	
	@media (max-width:1480px){
		width:80%;
		text-align: center;
		flex-direction: column;
	}

	@media (max-width:1024px){
		width:70%;
	}

	@media (max-width:640px){
		width:90%;
	}
`

const SnsItems = styled.div`
	flex:1 1 auto;

	&:after{
		content:"";
		clear:both;
	}
`

const SnsExtends = styled.p`
	padding-bottom: 20px;
	color:#fff;
	letter-spacing: -1.4px;

`

const SnsEngTxt = styled(SnsExtends)`
	font-size: 60px;
	font-weight:100;

	&>span{
		font-size:90px;
	}

	@media (max-width:720px){
		font-size:48px;

		&>span{
			font-size:78px;
		}
	}

	@media(max-width:640px){
		font-size:36px;

		&>span{
			font-size:66px;
		}
	}
`

const SnsNumberTxt = styled(SnsExtends)`
	font-size:65px;
	font-weight:900;
	padding-bottom: 13px;
	text-shadow:
    -1px -1px 0 #fff,
    1px -1px 0 #fff,
    -1px 1px 0 #fff,
	1px 1px 0 #fff;  
	
	@media(max-width:600px){
		font-size:50px;
	}
`

const SnsTimeStampTxt = styled(SnsExtends)`
	font-size:16px;
	letter-spacing: 0;
	font-weight: 700;
	
	padding-left:138px;
	@media (max-width:1480px){
		padding-left:0;
	}
`

const SnsSocailBox = styled.div`
	width:49%;
	max-width: 150px;
	float:left;

	@media (max-width:1480px){
		float:none;
		display: inline-block;
		max-width: 160px;
	}
`

const SnsFaceBook = styled(SnsSocailBox)`

`

const SnsFaceBookImg = styled.img`
	display:block;
	width:100%;
`

const SnsKakaoTalk = styled(SnsSocailBox)`
	margin-left: 2%;
`

const SnsKakaoTalkImg = styled.img`
	display:block;
	width:100%;
`

const CustomerThums = styled.img`
	width: 100%;
    max-width: 60px;
    display: inline-block;
	padding-right: 15px;
	
	@media(max-width:640px){
		max-width:50px;
	}
`

class OutLook extends Component {
	render() {
		return (
			<OutLookBox >
				<OutLookTit>
					<OutLookTitSpan>CFSC</OutLookTitSpan>
					고객센터
				</OutLookTit>
				<div>
					<SnsWarpper>
						<SnsInner>
							<SnsItems>
								<SnsEngTxt><CustomerThums src={customerImg} /><span>c</span>ustomer <span>c</span>enter</SnsEngTxt>
								<SnsNumberTxt>051-583-9645</SnsNumberTxt>
								<SnsTimeStampTxt>MON-FRI 09:00 ~ 18:00(주말, 공휴일 휴무)</SnsTimeStampTxt>
							</SnsItems>
							<SnsItems>
								<SnsFaceBook>
									<a href="https://www.facebook.com/karfekorea" target="_blank" rel="noopener noreferrer"><SnsFaceBookImg src={SnsFaceBookThums} /></a>
								</SnsFaceBook>
								<SnsKakaoTalk>
									<a href="http://pf.kakao.com/_hrKXxl" target="_blank" rel="noopener noreferrer"><SnsKakaoTalkImg src={SnsKakaoTalkThums} /></a>
								</SnsKakaoTalk>
							</SnsItems>
						</SnsInner>
					</SnsWarpper>
				</div>
			</OutLookBox>
		)
	}
}

export default OutLook;