import React, {Component} from 'react';

import styled from 'styled-components';

import CertificateBg from './img/certificates_bg.jpg';
import MoCertificateBg from './img/m_certificates_bg.jpg';

import StcBasic from './img/stc_basic.jpg';
import StcAdvance from './img/stc_advance.jpg';
import StcRehab from './img/stc_rehab.jpg';
import StcMaster from './img/stc_master.jpg';


const CertificatesBox = styled.div`
	position:relative;
	padding:60px 0;
	height:1069px;
	background:url(${CertificateBg}) 0 0 no-repeat;
	background-size: cover;

	@media(max-width:960px){
		padding:0;
		height:663px;
		background:url(${MoCertificateBg}) 0 0 no-repeat;
		background-size: cover;
	}
`

const ConceptBox = styled.div`
	
`

const InfoConTit = styled.h2`
    text-align: center;
    font-size: 63px;
    color: #fff;
	letter-spacing: -5px;
	line-height: 130%;

	&>span{
		font-family: 'Impact';
	}

	@media(max-width:960px){
		font-size: 40px;
		padding-top:60px;
	}
`

const CertificateMenuBox = styled.div`
	padding:100px 0;
	max-width:862px;
	width:100%;
	margin:0 auto;

	@media(max-width:960px){
		padding:60px 0;
	}
`

const CertificateMenu = styled.div`
	display:flex;
	flex-wrap: wrap;
`

const CertificateItems = styled.div`
	width:48%;
	margin:0 1%;
`

const CertificateThums = styled.img`
	display:block;
	width:100%;
`

const CertificateTxt = styled.p`
	padding:22px 0;
	font-size:22px;
	font-weight:700;
	color:#fff;
	text-align:center;
`

class Certificates extends Component {
	componentDidMount() {
		this.props.getOffset('certificates', this.certificates);
	}

	render() {
		return (
			<CertificatesBox
				id="certificates"
				ref={ref => {
					this.certificates = ref;
				}}
			>
				<ConceptBox>
					<InfoConTit><span>STC</span> 인증 교육 수료증</InfoConTit>
				</ConceptBox>
				<CertificateMenuBox>
					<CertificateMenu>
						<CertificateItems>
							<div>
								<CertificateThums src={StcBasic}/>
							</div>
							<CertificateTxt>
								STC Basic 수료증
							</CertificateTxt>
						</CertificateItems>
						<CertificateItems>
							<div>
								<CertificateThums src={StcAdvance} />
							</div>
							<CertificateTxt>
								STC Advance 수료증
							</CertificateTxt>
						</CertificateItems>
						<CertificateItems>
							<div>
								<CertificateThums src={StcRehab} />
							</div>
							<CertificateTxt>
								STC Rehab 수료증
							</CertificateTxt>
						</CertificateItems>
						<CertificateItems>
							<div>
								<CertificateThums src={StcMaster} />
							</div>
							<CertificateTxt>
								STC Master 수료증
							</CertificateTxt>
						</CertificateItems>
					</CertificateMenu>
				</CertificateMenuBox>

			</CertificatesBox>
		)
	}
}

export default Certificates;