import React, { Component } from 'react';

import urlApi from '../../config';

import Pagination from "react-js-pagination";


import Header from './Dns_header';
import Footer from './Dns_footer';

import CoachImg from './img/coach_img1.jpg';

import styled from 'styled-components';

import axios from 'axios';

const CoachThums = styled.img`
	display:block;
	width:100%;
`

const CoachBtn = styled.span`
	cursor:pointer;

	&:hover{
		color:#bfbfbf;
	}

	&.on{
		color:#f44336;
	}
`

const CoachContainer = styled.div`
	display:flex;
    color: #fff;
	text-align: center;
	
	@media(max-width:720px){
		flex-direction: column;
		border-bottom: .5px solid #fff;
	}

`

const CoachLft = styled.div`
	width: 100%;
	padding: 40px 0px;
	color:#fff;
	cursor: pointer;
	text-align:center;
	border-top:.5px solid #fff;
	border-right:.5px solid #fff;
	box-sizing: border-box;

	&:last-child{
		border-bottom:.5px solid #fff;
	}

	&.on{
		color: #ff9b00;
		background: #003aa0;
		font-weight:700;
	}


	@media(max-width:720px){
		width:100%;

		&:first-child{
			border-bottom:.5px solid #fff;
		}
	}
`

const CoachLftBlack = styled(CoachLft)`
	width:20%;
	background:#000;
`

const CoachLftGray = styled(CoachLft)`
	background:#595959;
`

const CoachLftBlue = styled(CoachLft)`
	background:#1f67e8;
`

const CoachRght = styled.div`
	display: flex;
    justify-content: space-around;
	flex: 1 0 auto;
	padding: 40px 0px;
	color:#fff;
	box-sizing: border-box;
	
	
`

const CoachRghtBlack = styled(CoachRght)`
	background:#000;
`

const CoachRghtGray = styled(CoachRght)`
	background:#595959;
`

const CoachRghtBlue = styled(CoachRght)`
	background:#1f67e8;
`

const CoachContentsBox = styled.div`
	padding: 80px 40px;
	background: #595959;
	min-height: 131px;
	border-top:1px solid #fff;
	
	@media(max-width:720px){
		margin-left:0;
	}
`

const CoachContentList = styled.ul`
	display: flex;
	flex-wrap: wrap;
    justify-content: normal;
	color: #fff;
`

const CoachContentItem = styled.li`
	width:20%;
	margin-bottom:20px;
`

const CoachContentItemPictur = styled(CoachContentItem)`
    width: 25%;
    text-align: center;
    padding: 0 50px;
	box-sizing: border-box;

	&>img{
		width:100%;
		max-width:200px;
	}

	@media(max-width:1280px){
		padding:0;
	}

	@media(max-width:1170px){
		width:33.333%;
		padding:0;
	}
	
	@media(max-width:920px){
		width:50%;
		padding:0;
	}

	@media(max-width:520px){
		width:100%;
		padding:0;
	}
`

const CoachProcessBox = styled.div`
	display:flex;
	flex-direction: row;

	@media(max-width: 720px){
		flex-direction: column;
	}
`

const CoachProcessTxt = styled.div`
	width:20%;
	background: #595959;
	border-right: .5px solid #fff;

	@media(max-width: 720px){
		width:100%;
	}

	&.off>div.on{
		color: #fff;
		background: #595959;
	}
`

const CoachProcessListContainer = styled.div`
	width:80%;

	@media(max-width: 720px){
		width:100%;
	}
`


class StcCoach extends Component {
	state = {
		data: [],
		total: null, //총 갯수
		todosPerPage: 16, //보여질갯수
		currentPage: 1, //현재 페이지
		postId: 2,
		dataQuery: 1,
		isBoolean: 2,
		pageNumbers: [],
		currentTodos: [],
		activePage: 1,
		pagerTotalNum: null,
		allBtn : '',
		targetCurrent: null,
		
	}

	componentWillMount() {
		this.HttpRequestWithPage(2,1)
			.then(() => {
				this.renderPage();
			});
	}

	AllHttpRequestWithPage = () => {
		/*
		* 전체페이지 요청 ajax
		*/
		const url = `${urlApi.Api}/rest/karfe/stc/0`;
		
		return axios.get(url)
			.then((res) => {
				this.setState({data: res.data});
				this.setState({total: res.data.length});
			})
			.catch((err) => {
				console.log(err);
			})
	}

	HttpRequestWithPage = (postId,dataQuery) => {
		/*
		* 베이직코치 요청 ajax
		*/

		console.log(postId,dataQuery)
		const url = `${urlApi.Api}/rest/karfe/stc/${postId}/${dataQuery}`;
		
		return axios.get(url)
			.then((res) => {
				this.setState({data: res.data});
				this.setState({total: res.data.length});
			})
			.catch((err) => {
				console.log(err);
			})
	}

	AllHandleClick = async (e) => {
		const target = e.target;

		this.selectCoachs.classList.add('off');

		this.select1.childNodes.forEach(list => {
			list.classList.remove('on');
		});

		await this.setState({
			currentPage: 1,
			postId: 1,
			dataQuery: Number(e.target.dataset.query),
			isBoolean: this.state.isBoolean,
			allBtn: target,
		});


		await this.AllHttpRequestWithPage()
			.then(() => {
				this.renderPage();
			});
	}

	AllClickCheck = (clickList,targetList) => {
		const chkList = clickList.childNodes;

		this.selectCoachs.classList.remove('off');

		chkList.forEach(list => {
			list.classList.remove('on');
		})

		if (targetList.tagName === 'DIV') {
			chkList[0].classList.add('on');

			targetList.parentElement.childNodes.forEach(list => {
				list.classList.remove('on');
			})
		}

		targetList.classList.add('on');

	}

	basicHandleClick = async (e) => {
		this.AllClickCheck(this.select1,e.target);

		await this.setState({
			currentPage: 1,
			postId: Number(e.target.dataset.id),
			dataQuery: Number(e.target.dataset.query),
			isBoolean: Number(e.target.dataset.id),
		});
		await this.HttpRequestWithPage(this.state.postId, this.state.dataQuery)
			.then(()=>{
				this.renderPage();
			});
	}

	pageHandleClick = async (e) => {
		await this.setState({
			currentPage: Number(e.target.id === '0' ? 1 : e.target.id),
			postId: Number(e.target.dataset.id),
			dataQuery: Number(e.target.dataset.query),
		});
		await this.renderPage();
	}

	renderPage = () => {
		try {
			const {data, currentPage, todosPerPage} = this.state;

			const indexOfLastTodo = currentPage * todosPerPage;
			const indexOfFirstTodo = indexOfLastTodo - todosPerPage;

			const currentTodos = data.slice(indexOfFirstTodo, indexOfLastTodo);

			const pageNumbers = [];
			for (let i = 1; i <= Math.ceil(data.length / todosPerPage); i++) {
				pageNumbers.push(i);
			}

			this.setState({
				currentTodos,
				pageNumbers,
				pagerTotalNum: Math.ceil(data.length / todosPerPage)
			})
		}catch (e){
			return;
		}
		
	}

	handlePageChange = async (pageNumber) => {
		await this.setState({
			currentPage: pageNumber,
		});
		await this.renderPage();

		await this.setState({activePage: pageNumber});
	}

	CoachBtnRender = (coachNum, total) => {
		const listArr = [];
		for (let i = 0; i < total; i++) {
			listArr.push(i);
		}
		return listArr.map((list,idx) => {
			return(
				idx === 0 
				?
					<CoachBtn className="on" key={idx} data-id={coachNum} data-query={idx+1} onClick={this.basicHandleClick}>{idx+1}기</CoachBtn> 
				: 
					<CoachBtn data-id={coachNum} key={idx} data-query={idx + 1} onClick={this.basicHandleClick}>{idx + 1}기</CoachBtn>
				
			)
		})
	}

	CoachRenderList = (data, index) => {
		
		if (this.state.postId === 0 || this.state.postId === 1){
			return (
				<CoachContentItem key={index}>
					<p>{data.name}</p>
				</CoachContentItem>
			)
		}else if(this.state.postId === 2){
			return (
				<CoachContentItemPictur key={index}>
					<img src={data.img} alt={data.name}/ >
					{/*<p style={{paddingTop: "5px"}}>{data.level}level</p>*/}
					<p style={{padding: "5px 0", fontWeight: "700"}}>{data.name}</p>
					<p style={{lineHeight:"120%",fontSize:"14px"}}>{data.memo.split('<br />').map((line,idx) => {
						return (<span key={idx}>{line}<br /></span>)
					})}</p>
				</CoachContentItemPictur>
			)
		}
	}



	render() {
		return (
			<div>
				<Header/>
				<div>
					<CoachThums src={CoachImg}/>
				</div>
				<CoachContainer>
					<CoachLftBlack style={{border:"0"}}>
						코치소개
					</CoachLftBlack>
					<CoachRghtBlack>
						{
							<CoachBtn 
							className={this.state.dataQuery === 0 ? "on" : "off"} 
							data-query="0"
							onClick={this.AllHandleClick}
							>전체보기</CoachBtn> 
						}
					</CoachRghtBlack>
				</CoachContainer>
				<CoachProcessBox>
					<CoachProcessTxt ref={(ref) => {this.selectCoachs = ref}}>
						<CoachLftGray 
							data-slect="div" 
							data-id="1" 
							data-query="1" 
							onClick={this.basicHandleClick}
						>

							베이직 코치
						</CoachLftGray>
						<CoachLftGray
							className="on" 
							data-slect="div"
							data-id="2"
							data-query="1"
							onClick={this.basicHandleClick}
						>
							어드밴스 코치
						</CoachLftGray>
						<CoachLftGray 
							data-slect="div"
							data-id="3" 
							data-query="1" 
							onClick={this.basicHandleClick}
						>
							리햅 코치
						</CoachLftGray>
						<CoachLftGray 
							data-slect="div"
							data-id="4" 
							data-query="1" 
							onClick={this.basicHandleClick} 
						>
							마스터 코치
						</CoachLftGray>
					</CoachProcessTxt>
					<CoachProcessListContainer>
						{this.state.isBoolean === 1 && <CoachRghtBlue ref={(ref) => {this.select1 = ref}}>
							{this.CoachBtnRender(1, 10)}
						</CoachRghtBlue>}

						{this.state.isBoolean === 2 && <CoachRghtBlue ref={(ref) => {this.select1 = ref}}>
							{this.CoachBtnRender(2, 10)}
						</CoachRghtBlue>}

						{this.state.isBoolean === 3 && <CoachRghtBlue ref={(ref) => {this.select1 = ref}}>
							{this.CoachBtnRender(3, 10)}
						</CoachRghtBlue>}

						{this.state.isBoolean === 4 && <CoachRghtBlue ref={(ref) => {this.select1 = ref}}>
							{this.CoachBtnRender(4, 10)}
						</CoachRghtBlue>}

						<CoachContentsBox>
							<CoachContentList>
								{
									this.state.currentTodos.length !== 0
										?
										this.state.currentTodos.map((data, index) => {
											return this.CoachRenderList(data, index)
										})
										:
										'자료가 없습니다.'
								}
							</CoachContentList>

							{
								this.state.data.length !== 0
									?
									<Pagination
										activePage={this.state.activePage}
										itemsCountPerPage={this.state.todosPerPage}
										totalItemsCount={this.state.total}
										pageRangeDisplayed={5}
										onChange={this.handlePageChange}
									/>
									: ''

							}
						</CoachContentsBox>
					</CoachProcessListContainer>
				</CoachProcessBox>
				<Footer/>
			</div>
		);
	}
}


export default StcCoach;