import React, { Component } from 'react';

import CertificateModal from './Certificate_modal';

import Awards from './img/home_awards.jpg';
import Certificate from './img/home_certificate.jpg';
import Partnership from './img/home_partnership.jpg';

import styled from 'styled-components';

const AwardsWarpper = styled.div`
	max-width: 1565px;
	margin: 0 auto;
	padding: 77px 0 55px 0;
	&:after{
		content:"";
		display:block;
		clear:both;
	}

	@media (max-width: 720px) {
		padding: 74px 0;
	}
`

const AwardsColum = styled.div`
	float:left;
	width:33.333%;
	box-sizing:border-box;

	&:nth-child(1) {
		padding-right: 30px;
		border-right:1px solid #d2d2d2;
	}

	&:nth-child(3) {
		padding-left: 60px;
		border-left:1px solid #d2d2d2;
	}

	@media (max-width: 1330px) {
		float:none;
		width:80%;
		margin:0 auto;
		border:0 !important;
		padding:0 !important
	}
`

const AwardsImages = styled.img`
	display:block;
	width:100%;
	margin: 0 auto;

	@media (max-width: 1330px) {
		width:100%;
		margin: 0 auto;
	}
`

const ImagesOne = styled(AwardsImages)`
	margin: 0 0 0 auto;
	max-width: 400px;
	@media (max-width: 1330px) {
		width:100%;
		margin: 0 auto;
	}
`

const ImagesTwo = styled(AwardsImages)`
	max-width: 520px;
`

const ImagesThree = styled(AwardsImages)`
	margin: 0 auto 0 0;
	max-width: 360px;
`

const CertificateBtn = styled.span`
	cursor:pointer;
`

const CertificateItems = styled.div`
	text-align:center;
	color:#999;

	@media(max-width:1330px){
		margin:0 auto;
	}
`

const CertificateLftItems = styled(CertificateItems)`
	text-align: center;
    margin: 0 0 0 auto;
    max-width: 400px;
`

const CertificateRghtItems = styled(CertificateItems)`
	text-align: center;
    margin: 0 auto 0 0;
    max-width: 360px;
`

const CertificateTopTxt = styled.p`
	font-size:21px;
`

const CertificateBtmTxt = styled.p`
	font-size: 44px;
    padding-top: 8px;
	color: #2576b2;
	
	@media(max-width:960px){
		font-size: 50px;
		letter-spacing: -5px;
	}

	@media(max-width:640px){
		font-size: 40px;
	}
`

class HomeAwards extends Component {
	state = {
		open: false,
	};

	onOpenModal = () => {
		this.setState({open: true});
	};

	onCloseModal = () => {
		this.setState({open: false});
	};

	render() {
		return (
			<AwardsWarpper>
				<AwardsColum>
					<a href="http://news.heraldcorp.com/view.php?ud=20180404000342" target="_blank" rel="noopener noreferrer">
						<CertificateLftItems>
							<CertificateTopTxt>2017·2018 <span>2년 연속 수상</span>(헬스케어부분)</CertificateTopTxt>
							<CertificateBtmTxt>교육산업대상 수상</CertificateBtmTxt>
						</CertificateLftItems>
						<ImagesOne src={Awards}/>
					</a>
				</AwardsColum>
				<AwardsColum>
					<CertificateBtn onClick={this.onOpenModal}>
						<CertificateItems>
							<CertificateTopTxt>다 같은 자격증이 아닙니다.</CertificateTopTxt>
							<CertificateBtmTxt>카르페 민간자격증 등록</CertificateBtmTxt>
						</CertificateItems>
						<ImagesTwo src={Certificate}/>
					</CertificateBtn>
					{this.state.open ? <CertificateModal open={this.state.open} onCloseModal={this.onCloseModal}/> : ''}
				</AwardsColum>
				<AwardsColum>
					<CertificateRghtItems>
						<CertificateTopTxt>공식파트너쉽 공인 단체</CertificateTopTxt>
						<CertificateBtmTxt>공식파트너쉽</CertificateBtmTxt>
					</CertificateRghtItems>
					<ImagesThree src={Partnership}/>
				</AwardsColum>
			</AwardsWarpper>
		);
	}
}

export default HomeAwards;