import React from 'react';

import styled from 'styled-components';

import BaseBallPeople from './img/baseball_people_bg.png';

const QuestionWarrper = styled.section`
	position:relative;
	padding:142px 0 140px 0;
	text-align:center;
	background-color:#f4f4f4;
	z-index:-2;

	@media(max-width:960px){
		padding:60px 15px;
	}
`

const QuestionTit = styled.h1`
	font-size:33px;
	font-weight: 300;

	@media (max-width:960px) {
		font-size:18px;
	}
`

const QuestionTopTxt = styled.div`
	padding: 23px 0 30px 0;
	font-size:64px;
	font-weight:500;
	color:#777;
	letter-spacing: -6.4px;

	@media (max-width:960px) {
		padding: 10px 0 25px 0;
		font-size:35px;
		letter-spacing: -3.4px;
	}

	@media(max-widtH:480px){
		font-size:25px;
	}
`

const QuestionTopSpan = styled.span`
	position: relative;
    top: 5px;
	font-family: 'Arial';
	font-size: 75px;
    font-weight: 900;
	letter-spacing: -4.8px;

	@media (max-width:960px) {
		font-size: 45px;
	}

	@media(max-widtH:480px){
		font-size:35px;
	}
`

const QuestionBtmTxt = styled.p`
	font-size:26px;
	font-weight:300;
	color:#565656;
	line-height: 36px;
	letter-spacing: -1.4px;

	@media (max-width:960px) {
		font-size:16px;
		line-height: 140%;
	}
`

const QuestionBtmSpan = styled.span`
	font-weight:700;
	letter-spacing: -1.4px;
`

const QuestionBasePerson = styled.img`
	position: absolute;
    top: 190px;
    right: 0;
    z-index: -1;
`

const KbsiQuestion = () => {
	return (
		<QuestionWarrper>
			<QuestionTit>한국야구 스트렝스&컨디셔닝 연구소</QuestionTit>
			<QuestionTopTxt>
				<QuestionTopSpan>KBSI</QuestionTopSpan> 는 어떤 단체인가요?
			</QuestionTopTxt>
			<QuestionBtmTxt>
				한국 야구 스트렝스&컨디셔닝 연구소는<br/>
				아마추어 및 프로야구 선수들을 위한 부상방지 및 회복은  물론, <br />
				<QuestionBtmSpan>최적의 운동수행능력향상을 위한 연구활동을 위해  설립된 비영리 연구소</QuestionBtmSpan>입니다. 
			</QuestionBtmTxt>
			<QuestionBasePerson src={BaseBallPeople}/>
		</QuestionWarrper>
	)
}

export default KbsiQuestion;