import React from 'react';

import KbsiMemberList from './Member_list';

import styled from 'styled-components';

import MemberBg from './img/member_bg.png';
import MemberMobileBg from './img/m_member_bg.png'

const MemberWarrper = styled.section`
	padding:184px 0;
	background:url(${MemberBg}) 0 0 no-repeat;

	@media (max-width:960px){
		padding: 11% 0 0 0;
		background:url(${MemberMobileBg}) 0 0 no-repeat;
		background-size: 100%;
	}

	@media (max-width:680px){
		background:url(${MemberBg}) 0 0 no-repeat;
	}
`

const MemberTit = styled.h1`
	padding-bottom:5.5%;
	font-size:65px;
	color:#fff;
	text-align:center;

	@media (max-width:1024px){
		font-size: 5vw;
	}

	@media (max-width:640px){
		font-size:40px;
	}
`

const KbsiMember = () =>{
	return(
		<MemberWarrper>
			<MemberTit>연구소 멤버 소개</MemberTit>
			<KbsiMemberList />
		</MemberWarrper>
	)
}

export default KbsiMember;