import React, {Component} from 'react';

import styled from 'styled-components';

import Tch1 from './img/cfsc_tch_01.jpg';
import Tch2 from './img/cfsc_tch_02.jpg';
import Tch3 from './img/cfsc_tch_03.jpg';
import Tch4 from './img/cfsc_tch_04.jpg';

const CurriculumBox = styled.div`
	padding:70px 0;
`

const ConceptBox = styled.div`
	max-width: 1350px;
	width:100%;
    margin: 0 auto;
`

const InfoConTit = styled.h2`
    text-align: center;
    font-size: 63px;
    color: #333;
	letter-spacing: -5px;
	padding:0 0 50px 0;
	line-height: 130%;

	&>span{
		font-family: 'Impact';
	}


	@media(max-width:960px){
		font-size: 40px;
		padding-top:0;
		padding-bottom: 60px;
	}
`

const CurItems = styled.div`
	display: flex;
	flex-direction: row;

	@media(max-width:1280px){
		flex-direction:column;
	}
	
`

const TopCurItems = styled(CurItems)`
	margin:.5% 1%;

	@media(max-width:1280px){
		flex-direction:column;
	}

`

const TopCurThums = styled.img`
	display:block;
	width:100%;
	max-width: 1004px;

	@media(max-width:1280px){
		max-width:initial;
	}
`

const CurThums = styled.img`
	display:block;
	width:100%;
	max-width: 332px;

	@media(max-width:1280px){
		width: 50%;
		max-width:initial;
	}

	@media(max-width:580px){
		width: 100%;
	}
`

const RowCurItems = styled(CurItems)`
	width:48%;
	margin:.5% 1%;

	@media(max-width:1280px){
		width: 98%;
		flex-direction:row;
	}

	@media(max-width:580px){
		flex-direction:column;
		padding-bottom:45px;
	}
`

const RowCurItemsRght = styled.div`
	flex:1 1 auto;
	padding-left:15px;

	@media(max-width:1280px){
		flex:1 1 auto;
		padding-left: 15px;
	}

	@media(max-width:580px){
		padding-left: 15px;
	}
`

const RowCurItemsRghtTop = styled(RowCurItemsRght)`
	@media(max-width:1280px){
		display:flex;
		padding:10px 0;
		align-items:center;
	}

	@media(max-width:580px){
		flex-direction:column;
		padding-left: 15px;
	}
`

const CurItemsTit = styled.span`
	display: block;
	padding:45px 0 30px 0;
	font-size:23px

	&>span{
		color:#dd1b1e;
	}
`

const CurItemsTitTop = styled(CurItemsTit)`
	width:100%;
`

const CurItemsUlTop = styled.ul`
	width:100%;
	box-sizing: border-box;

	@media (max-width: 1280px){
		padding-left:20px;
	}

	@media (max-width: 580px){
		padding-left:0;
	}
`

const CurItemsList = styled.li`
	font-size:13px;
	letter-spacing:-2px;
	line-height: 130%;
`

class Curriculum extends Component {
	componentDidMount() {
		this.props.getOffset('teacher', this.concept);
	}

	render(){
		return(
			<CurriculumBox 
				id="teacher"
				ref={ref => {
					this.concept = ref;
				}}
			>
				
				<ConceptBox>
					<InfoConTit><span>CFSC</span> 한국 강사진 소개</InfoConTit>
					<TopCurItems>
						<TopCurThums src={Tch1}/>
						<RowCurItemsRghtTop>
							<CurItemsTitTop><span>스포츠 트레이닝</span> 박일봉박사</CurItemsTitTop>
							<CurItemsUlTop>
								<CurItemsList>동아대학교 체육대학 사회체육학과 졸업</CurItemsList>
								<CurItemsList>부산가톨릭대학교 물리치료학과 졸업</CurItemsList>
								<CurItemsList>동아대학교 대학원 체육학석사</CurItemsList>
								<CurItemsList>부산대학교 의학전문대학원 의학석사</CurItemsList>
								<CurItemsList>동아대학교 대학원 체육학박사</CurItemsList>
								<CurItemsList>부산대학교 의학전문대학원 의학박사</CurItemsList>
								<CurItemsList>부산대학교 경영대학원 경영컨설팅학 박사</CurItemsList>
								<CurItemsList>(사) 한국재활기능운동협회(KARFE) 협회장</CurItemsList>
								<CurItemsList>(주) 스마트핏 (SmartFit) CEO</CurItemsList>
								<CurItemsList>현, 부산외국어대학교 사회체육학부 겸임교수</CurItemsList>
								<CurItemsList>현, 동원과학기술대학교 재활스포츠학부 겸임교수</CurItemsList>
							</CurItemsUlTop>
						</RowCurItemsRghtTop>
					</TopCurItems>
					<CurItems>
						<RowCurItems>
							<CurThums src={Tch2} />
							<RowCurItemsRght>
								<CurItemsTit><span>펑셔널트레이닝</span> 이채관교수</CurItemsTit>
								<ul>
									<CurItemsList>부산대학교 체육교육대학원 석사</CurItemsList>
									<CurItemsList>부산카톨릭대 물리치료학 박사과정</CurItemsList>
									<CurItemsList>현, 동원과학기술대학 스포츠재활운동학부 겸임교수</CurItemsList>
									<CurItemsList>현, KARFE 교육이사</CurItemsList>
									<CurItemsList>물리치료사 - 보건복지부</CurItemsList>
									<CurItemsList>SABA배 Mr. Ms. 보디빌딩대회 -60kg 준 그랑프리 등 다수 수상</CurItemsList>
									<CurItemsList>재활기능운동학, 퍼스널 트레이닝(한미의학)공역</CurItemsList>
									<CurItemsList>NASM-CPT, CES, PES Instructor</CurItemsList>
									<CurItemsList>ViPR Master Instructor course- Fitpro(England)</CurItemsList>
									<CurItemsList>Grid (SMRT-CORE) Master Instructor course -TpTherapy(USA)</CurItemsList>
									<CurItemsList>EXOS rehabilitation metorship  - Athletes Performance(USA)</CurItemsList>
									<CurItemsList>EXOS Sports performance metorship Phase1, 2</CurItemsList>
									<CurItemsList>Athletes Performance(USA)</CurItemsList>
									<CurItemsList>CFSC(Certified Functional Strength coach) Lv.1 / Lv.2</CurItemsList>
									<CurItemsList>CFSC(Certified Functional Strength coach) Master Instructor</CurItemsList>
									<CurItemsList>BareFoot training Specialist - Evidence Based Fitness Academy</CurItemsList>
									<CurItemsList>freeFORM FITNESS Instructor Course - freeFORM.Co(Austraila)</CurItemsList>
									<CurItemsList>TRX STC/ SMSTC / Rip Trainer Instructor Course</CurItemsList>
									<CurItemsList>FITNESS ANYWHERE(USA)</CurItemsList>
									<CurItemsList>Powerplate Instructor Course - Powerplate.co(USA)</CurItemsList>
									<CurItemsList>Trigger Point Instructor Course - TpTherapy(USA)</CurItemsList>
								</ul>
							</RowCurItemsRght>
						</RowCurItems>
						<RowCurItems>
							<CurThums src={Tch3} />
							<RowCurItemsRght>
								<CurItemsTit><span>펑셔널트레이닝</span> 정주연교수</CurItemsTit>
								<ul>
									<CurItemsList>H 퍼스널 트레이닝 대표</CurItemsList>
									<CurItemsList>동국대 석사과정</CurItemsList>
									<CurItemsList>NASM CPT, CES INSTRUCTOR</CurItemsList>
									<CurItemsList>미국 EXOS PERFORMANCE MENTOSHIP LEVEL 1</CurItemsList>
									<CurItemsList>TRX STC, SMSTC, GSTC, RIP TRAINER INSTRUCTOR </CurItemsList>
									<CurItemsList>PS2AD part B course / part A, B review course</CurItemsList>
									<CurItemsList>Kinetic Integration Corrective Exercise Specialist</CurItemsList>
									<CurItemsList>Functional Approach Level 1 </CurItemsList>
									<CurItemsList>FMS LEVEL 1</CurItemsList>
									<CurItemsList>VIPR INSTRUCTOR</CurItemsList>
									<CurItemsList>SMART-CORE INSTRUCTOR </CurItemsList>
									<CurItemsList>POWER PLATE INSTRUCTOR</CurItemsList>
									<CurItemsList>TRIGGER POINT INSTRUCTOR</CurItemsList>
									<CurItemsList>FLOWIN INSTRUCTOR</CurItemsList>
									<CurItemsList>FREEFORM INSTRUCTOR</CurItemsList>
									<CurItemsList>IKFF KETTLEBELL MASTER INSTRUCTOR </CurItemsList>
									<CurItemsList>V-CORE BARE FOOT TRAINING INSTRUCTOR</CurItemsList>
								</ul>
							</RowCurItemsRght>
						</RowCurItems>
					</CurItems>
					<CurItems>
						<RowCurItems>
							<CurThums src={Tch4} />
							<RowCurItemsRght>
								<CurItemsTit><span>스포츠 재활관리</span> 임병권교수</CurItemsTit>
								<ul>
									<CurItemsList>신라대학교 의생명과학대학 체육학과 졸업</CurItemsList>
									<CurItemsList>신라대학교 의생명과학대학 물리치료학과 졸업</CurItemsList>
									<CurItemsList>미국 센트럴 오클라호마 주립대학교 대학원 </CurItemsList>
									<CurItemsList>Exercise Science 석사 졸업</CurItemsList>
									<CurItemsList>미국 오클라호마주 에드몬드 메모리얼 고등학교 (OEMH)</CurItemsList>
									<CurItemsList>Strength and Conditioning (대학원조교) Assistant 코치</CurItemsList>
									<CurItemsList>Strength and Conditioning 수업 및 올림픽 웨이트리프팅 코치 </CurItemsList>
									<CurItemsList>선수 부상예방을 위한 올바른 트레이닝 프로그램 코치</CurItemsList>
									<CurItemsList>올림픽 웨이트리프팅 (Olympic Weightlifting) 코치 </CurItemsList>
									<CurItemsList>미국 텍사스주 탈레톤 주립대학교 (TSU) 운동선수 퍼포먼스 센터 Assistant 코치</CurItemsList>
									<CurItemsList>TSU 운동선수 폼 롤링 스트레치 및 근막 스트레칭 기술 코치</CurItemsList>
									<CurItemsList>미국 센트럴 오클라호마 주립대학교 (UCO) 대학원 조교 </CurItemsList>
									<CurItemsList>UCO 피트니스센터 관리, 그룹운동 강의 및 퍼스널 트레이너 </CurItemsList>
									<CurItemsList>UCO 운동선수 Strength and Conditioning 트레이닝센터 Assistant 코치</CurItemsList>
									<CurItemsList>UCO 미국 장애인 올림픽 트레이닝센터 Assistant 코치</CurItemsList>
									<CurItemsList>현, 동원과학기술대학교 스포츠재활학부 겸임교수</CurItemsList>
									<CurItemsList>중등정교사 (체육교육) 2급</CurItemsList>
									<CurItemsList>미국역도연맹 스포츠 퍼포먼스 코치 (USAW, Level-1)</CurItemsList>
									<CurItemsList>노인 운동 실행 및 트레이닝 자격증</CurItemsList>
									<CurItemsList>(UCO, 오클라호마 노인학 교육 센터)</CurItemsList>
									<CurItemsList>트리거포인트 (TRIGGERPOINT) 자격증</CurItemsList>
								</ul>
							</RowCurItemsRght>
						</RowCurItems>
					</CurItems>
				</ConceptBox>
			</CurriculumBox>
		)
	}
}


export default Curriculum;