import React, {Component} from 'react';

import styled from 'styled-components';

import ProcessArrow from './img/process_arrow_ico.png';
import MoProcessArrow from './img/m_process_arrow_ico.png';

const ConceptBox = styled.div`
	padding:60px 0;

	@media(max-width:960px){
		padding:0;
	}
`

const InfoConTit = styled.h2`
    text-align: center;
    font-size: 63px;
    color: #333;
	letter-spacing: -5px;
	padding:0 0 60px 0;
	line-height: 130%;

	&>span{
		font-family: 'Impact';
	}

	@media(max-width:960px){
		font-size: 40px;
		padding-top:60px;
		padding-bottom: 60px;
	}

`

const ProcessCircleWrap = styled.div`
	display:flex;
	max-width:1350px;
	width:100%;
	margin:0 auto;

	@media(max-width:1280px){
		flex-direction: column;
    	align-items: center;
	}
`

const ProcessCircleItems = styled.div`
	position:relative;
	width: 23%;
	margin: 0 2%;

	&:after{
		content:"";
		position:absolute;
		top: 4vw;
    	right: -2.1vw;
		background:url(${ProcessArrow}) 0 0 no-repeat;
		width: 4.2vw;
    	height: 3.7vw;
    	background-size: cover;

	}

	&:last-child:after{
		display:none;
	}
	
	&:nth-child(1)>div{
		background:#ff6365;
	}

	&:nth-child(2)>div{
		background:#f65153;
	}

	&:nth-child(3)>div{
		background:#e53436;
	}

	&:nth-child(4)>div{
		background:#db191b;
	}

	@media(max-width:1280px){
		display: flex;
		width: 100%;
    	max-width: 640px;
		margin-bottom: 15px;

		&:after{
			content:"";
			position:absolute;
			top: 164px;
    		right: 355px;
			background:url(${MoProcessArrow}) 0 0 no-repeat;
			width: 66px;
    		height: 72px;
			background-size: cover;

		}
	}

	@media(max-width:680px){
	 	flex-direction: column;
		align-items: center;
		padding-bottom: 90px;

		&:nth-child(4)>div{
			margin-top:15px;
		}
		
		&:after{
			content:"";
			position:absolute;
			top: 307px;
			right: 50%;
			transform: translate(50%,0);
			background:url(${MoProcessArrow}) 0 0 no-repeat;
			width: 66px;
    		height: 72px;
			background-size: cover;

		}
	}
`

const ProcessCircleBox = styled.div`
	display:flex;
	width:12vw;
	height:12vw;
	border-radius:50%;
	justify-content: center;
	align-items: center;
	text-align:center;
	font-size:24px;
	color: #fff;

	@media(max-width:1280px){
		font-size: 2vw;
		width: 180px;
    	height: 180px;
	}

	@media(max-width:720px){
		font-size:20px;
	}
	
`

const CircleMenu = styled.ul`
	margin-top: 38px;
	font-size:13px;

	@media(max-width:1280px){
		align-self: center;
		margin-left: 20px;
	}
`

const CircleList = styled.li`
	position:relative;
	padding:0 0 0 10px;
	line-height:140%;

	&:after{
		content:"-";
		position:absolute;
		top:0;
		left:0;
	}
`

class Process extends Component {
	componentDidMount() {
		this.props.getOffset('process', this.process);
	}

	render() {
		return (
			<section
				id="process"
				ref={ref => {
					this.process = ref;
				}}
			>
				<ConceptBox>
					<InfoConTit><span>CFSC</span> 교육과정 절차</InfoConTit>
					<ProcessCircleWrap>
						<ProcessCircleItems>
							<ProcessCircleBox>교육과정 등록</ProcessCircleBox>
							<CircleMenu>
								<CircleList>교육과정 등록 후 바로 온라인 강의 신청 가능</CircleList>
								<CircleList>온라인 강의는 이론강의와 실기강의<br />(실기동작)모두 시청 가능</CircleList>
								<CircleList>이론강의는 현장강의 전날 오후 5시까지 합격해야함</CircleList>
							</CircleMenu>
						</ProcessCircleItems>
						<ProcessCircleItems>
							<ProcessCircleBox>온라인 교육 후<br />이론 시험</ProcessCircleBox>
							<CircleMenu>
								<CircleList>100점 만접에 70점 이상 통과</CircleList>
								<CircleList>불합격시 합격할 때까지 계속 시험 가능</CircleList>
								<CircleList>불합격시 현장강의 참여는 가능하나,<br />현장강의 후 자격증 취득은 불가능</CircleList>
							</CircleMenu>
						</ProcessCircleItems>
						<ProcessCircleItems>
							<ProcessCircleBox>현장강의 참여</ProcessCircleBox>
							<CircleMenu>
								<CircleList>현장강의 10분 이상 지각 시 자격증 취득 불가함</CircleList>
								<CircleList>1 day 모든 강의 일정에 참여</CircleList>
								<CircleList>현장강의 후 실기 테스트 통과 시 자격증 취득 가능</CircleList>
							</CircleMenu>
						</ProcessCircleItems>
						<ProcessCircleItems>
							<ProcessCircleBox>자격증 취득</ProcessCircleBox>
							<CircleMenu>
								<CircleList>본사 공인 자격증 수여</CircleList>
								<CircleList>CFSC Korea 호메이지 및<br />미국 본사 홈페이지에 등록</CircleList>
							</CircleMenu>
						</ProcessCircleItems>
					</ProcessCircleWrap>
				</ConceptBox>
			</section>
		)
	}
}

export default Process;