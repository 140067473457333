import Modal from "react-responsive-modal";
import React, { cloneElement, useState } from "react";

export default function CertModal({ children, img }) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Modal open={open} onClose={() => setOpen(false)} center>
        <div className="h-[90vh]">
          <img
            src={require(`./cert/${img}.png`)}
            alt=""
            className="object-contain w-full h-full"
          />
        </div>
      </Modal>
      {cloneElement(children, { onClick: () => setOpen(true) })}
    </>
  );
}
