import React from 'react';

import styled from 'styled-components';

import EduMoreBtn from './img/edu_more_btn.jpg';

const OutLookListBox = styled.div`
	position:relative;
	width: 23%;
   	margin: 0 1% 0 1%;
	box-sizing:border-box;

	@media (max-width: 1280px) {
		display:block;
		width:31.333%;
		margin: 1%;
	}

	@media (max-width: 960px) {
		display:block;
		width:48%;
		margin: 1%;
	}

	@media (max-width: 640px) {
		display:flex;
		flex-direction: row;
		width:100%;
		margin: 0 0 20px 0;
	}

	&:after{
		content:"";
		display:block;
		clear:both;
	}
`

const OutLookImgBox = styled.div`
	position:relative;
	height: 258px;
	overflow: hidden;

	@media (max-width: 640px) {
		display:flex;
		width:40%;
		height:initial;
	}
`

const OutLookTxtBox = styled.div`
	position:relative;
	min-height: 170px;

	&:after{
		content: "";
		display: block;
		position: absolute;
		top: 0;
		left: -999px;
		width: 3000px;
		height: 100%;
		z-index: -1;
		background: #f4f4f4;
	}

	@media (max-width: 640px) {
		display:flex;
		flex-direction: column;
		align-items:flex-start;
		width:60%;

		&:after{
			display:none;
		}
	}
`

const OutLookInnerBox = styled.div`
	@media (max-width: 640px) {
		padding-left: 10px;
		width: 90%;
		height: 100%;
		border-bottom: 1px solid #d7d7d7;
		align-self: flex-end;
	}
`


const OutLookImg = styled.img`
	display:block;
	width:100%;
`

const OutLookTit = styled.p`
	width: 100%;
	padding:14px 0;
	font-size:20px;
	font-weight:900;
	text-align:center;
	overflow:hidden;
    text-overflow:ellipsis;
	white-space:nowrap;
	color:#3d3d3d;

	@media (max-width: 640px) {
		text-align:left;
	}

`

const OutLookEndThums = styled.div`
	position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: url(http://www.smartfitkorea.com/img/home/lec_dead.png?0607) 0 0 no-repeat;
    z-index: 5;
	background-size: 100%;
	text-indent:-9999px;
`

const OutLookExit = styled.div`
	position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: url(http://www.smartfitkorea.com/img/home/lec_end.png?0607) center center no-repeat;
    z-index: 5;
	background-size: 100%;
	text-indent:-9999px;

	@media (max-width:640px){
		background: url(http://www.smartfitkorea.com/img/home/lec_end.png?0607) 0 0 no-repeat;
		background-size: 100%;
	}
`

const OutLookBottom = styled.div`
	position: relative;
	display:flex;
	flex-direction: row;
	align-items: baseline;
	padding: 8px;

	@media (max-width: 640px) {
		flex-direction: column-reverse;
	}
`

const OutLookLoc = styled.p`
	font-size:17px;
	font-weight:900;
	color:#3d3d3d;

	@media (max-width: 640px) {
		padding-top:8px;
	}
`

const OutLookCenter = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1 0 auto;
	text-align: center;
	padding-right: 38px;

	@media (max-width: 640px) {
		align-items: flex-start;
		padding-right:0;
	}
`

const OutLookDate = styled.span`
	color:#ff0000;
	font-weight: 900;

	@media (max-width: 640px) {
		display: block;
    	padding-bottom: 20px;
	}
`

const OutLookLoctxt = styled.span`
	padding-top: 5px;
	color:#979797;
	font-size:12px;
	font-weight: 900;
`

const OutLookMoreBtn = styled.span`
	position: absolute;
    top: 8px;
    right: 8px;
    display: inline-block;
    padding: 2px 26px;
    color: #fff;
    font-size: 10px;
    text-indent: -9999px;
	background:#ff0000 url(${EduMoreBtn}) 0 0 no-repeat;

	@media (max-width: 640px) {
		display:none;
	}
`

function OutlookList(props){
	console.log(props)
	const getDay = (OutLookStartDate) => {
		const year = OutLookStartDate.substring(0, 4);
		const month = OutLookStartDate.substring(4, 6);
		const day = OutLookStartDate.substring(6, 8);

		const week = ['일', '월', '화', '수', '목', '금', '토'];
		const dayOfWeek = week[new Date(`${year}-${month}-${day}`).getDay()];
		const total = `${year}-${month}-${day} (${dayOfWeek})`;

		return total;
	}

	/*
	const getCourse = (OutLookCourse) => {
		const pattern = /^\[[가-하]{1,}\]|^\([가-하]{1,}\)/g;

		const regex = OutLookCourse.match(pattern);


		console.log(OutLookCourse)
		//const result = regex[0].substring(1, 3) + '지점'
		
		//return result;

	}
	*/

	const getLoc = (OutLookLoc) =>{
		const result = OutLookLoc.match("<BR>") ? OutLookLoc.replace("<BR>", "(") + ")" : OutLookLoc;
		return result;
	}

	return (
		<OutLookListBox >
			
			<OutLookImgBox>
				<a href={props.OutLookLink} target="_blank" rel="noopener noreferrer">
					<OutLookImg 
						src={props.OutLookImg} 
					/>
				{props.OutLookState === '마감임박' ? <OutLookEndThums>마감임박</OutLookEndThums> : ''}
				{props.OutLookState === '마감' ? <OutLookExit>마감</OutLookExit> : ''}
				</a>
			</OutLookImgBox>
			<OutLookTxtBox>
				<OutLookInnerBox>
					<OutLookTit title={props.OutLookTit}>{props.OutLookTit}</OutLookTit>
					<OutLookBottom>
						<OutLookLoc>{props.OutLookCourse}</OutLookLoc>
						<OutLookCenter>
							<OutLookDate>{getDay(props.OutLookStartDate)}</OutLookDate>
							<OutLookLoctxt>{getLoc(props.OutLookLoc)}</OutLookLoctxt>
						</OutLookCenter>
						<a href={props.OutLookLink} target="_blank" rel="noopener noreferrer">
							<OutLookMoreBtn>more+</OutLookMoreBtn>
						</a>
					</OutLookBottom>
				</OutLookInnerBox>
			</OutLookTxtBox>
		</OutLookListBox>
	)
}

export default OutlookList;