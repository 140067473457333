import React, { Component } from 'react';
import Modal from 'react-responsive-modal';

import styled from 'styled-components';

class PermitModal extends Component {
	render() {

		const PermitImages = styled.img`
			display: block;
			width:100%;
			max-width: 600px;
			margin: 0 auto;
		`

		return (
			<div>
				<Modal open={this.props.open} onClose={this.props.onCloseModal} center>
					<PermitImages src={this.props.PermitImage} />
				</Modal>
			</div>
		);
	}
}

export default PermitModal;