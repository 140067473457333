import React from "react";
import styled from "styled-components";
import KarfeInstructor from "./KarfeInstructor";

const InstructorWarrper = styled.section`
  overflow: hidden;
  padding: 84px;
  border-top: 1px solid #e2e2e2;

  @media (max-width: 1024px) {
    padding: 10%;
  }

  @media (max-width: 720px) {
    padding: 3%;
  }
`;

const InstructorInner = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1354px;
  margin: 0 auto;
`;

const InstructorTit = styled.h1`
  padding-bottom: 55px;
  font-size: 24px;
  font-weight: 700;

  @media (max-width: 1024px) {
    padding-top: 5%;
  }
`;

export default function Index() {
  return (
    <InstructorWarrper>
      <InstructorInner>
        <KarfeInstructor InstructorTit={InstructorTit} />
      </InstructorInner>
    </InstructorWarrper>
  );
}
